(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireDialogController', FormulaireDialogController);

    FormulaireDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Formulaire', 'DataElement', 'Data', 'CCI', 'Campagne'];

    function FormulaireDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Formulaire, DataElement, Data, CCI, Campagne) {
        var vm = this;

        vm.formulaire = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.dataelements = DataElement.query();
        vm.data = Data.query();
        vm.ccis = CCI.query();
        vm.campagnes = Campagne.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.formulaire.id !== null) {
                Formulaire.update(vm.formulaire, onSaveSuccess, onSaveError);
            } else {
                Formulaire.save(vm.formulaire, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bhsApp:formulaireUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateSaisie = false;
        vm.datePickerOpenStatus.dateVerification = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
