(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('MonBilanFormulaireService', MonBilanFormulaireService);

    MonBilanFormulaireService.$inject = ['$resource', 'DateUtils'];

    function MonBilanFormulaireService ($resource, DateUtils) {
        var resourceUrl = 'api/monBilanFormulaire';

        return $resource(resourceUrl, {}, {
            'getMonBilanFormulaire': {
                method:'GET',
                isArray: true
            }
        });
    }
})();
