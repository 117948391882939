(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('Campagne', Campagne);

    Campagne.$inject = ['$resource', 'DateUtils'];

    function Campagne ($resource, DateUtils) {
        //var resourceUrl =  'api/campagnes/:id';   mdi: conf par défaut
        var resourceUrl = 'api/campagnes';
        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'getAllCampagnes': {
                url: 'api/campagnes/all',
                method: 'GET',
                isArray: true
            },
            'get': {
                url: 'api/campagnes/:id',
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateOuverture = DateUtils.convertDateTimeFromServer(data.dateOuverture);
                        data.dateFermeture = DateUtils.convertDateTimeFromServer(data.dateFermeture);
                    }
                    return data;
                }
            },
            'campagneEncours':{
                url: 'api/campagneEncours',
                method:'GET',
                isArray:true
            },
            'getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires':{
                url: 'api/campagneSelectedAndTheTwoPrevious/:idCampagneSelected',
                method:'GET',
                isArray:true
            },
            'update': { method:'PUT' }
        });
    }
})();
