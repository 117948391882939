(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('FormulaireCciService', FormulaireCciService);

    FormulaireCciService.$inject = ['$resource', 'DateUtils'];

    function FormulaireCciService ($resource, DateUtils) {
        var resourceUrl =  'api/campagnesWithFormualiresCci';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getOne': {
                url: 'api/campagnes//formulaires/:id',
                method: 'GET',
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data;
                }
            },
            'getSimple': {
                url: 'api/campagnesWithoutFormualiresCci',
                isArray: true,
                method: 'GET'
            },
             'search': {
                 url: 'api/campagnesWithFormualiresCci/:campagne/:region/:cci/:complet/:valide',
                 method: 'GET',
                 isArray: true
             },
             'filters': {
                url: 'api/filters',
                method: 'GET',
                isArray: false
             },
             'sort': {
                 url: 'api/formulaire/sorting',
                 method:'PUT'
             },
             'getSort': {
                 url: 'api/formulaire/sorting',
                 method:'GET'
             }
        });
    }
})();
