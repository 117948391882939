(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireNbrHrTrCtrl', FormulaireNbrHrTrCtrl);

    FormulaireNbrHrTrCtrl.$inject = ['$scope', '$state', 'Formulaire', 'AlertService', 'AutreActivite', 'ParamFormulaire', 'Campagne', 'entityCCI', 'Principal', 'entityCampagne', '$stateParams'];

    function FormulaireNbrHrTrCtrl($scope, $state, Formulaire, AlertService, AutreActivite, ParamFormulaire, Campagne, entityCCI, Principal, entityCampagne, $stateParams) {
        var vm = this;

        // Init functions
        vm.printFormulaire = printFormulaire;
        vm.sauvegarder = sauvegarder;
        vm.showHelp = showHelp;
        vm.confirmerRetour = confirmerRetour;
        vm.calcTaux = calcTaux;
        vm.calcTauxOldCampagne = calcTauxOldCampagne;
        vm.calculerTotalFrequence = calculerTotalFrequence;
        vm.calculerTotalGravite = calculerTotalGravite;
        vm.calculerTotalFrequenceOldCampagne1 = calculerTotalFrequenceOldCampagne1;
        vm.calculerTotalGraviteOldCampagne1 = calculerTotalGraviteOldCampagne1;
        vm.calculerTotalFrequenceOldCampagne2 = calculerTotalFrequenceOldCampagne2;
        vm.calculerTotalGraviteOldCampagne2 = calculerTotalGraviteOldCampagne2;
        vm.loadFormulaireAccidentOldCampagne1=loadFormulaireAccidentOldCampagne1;
        vm.loadFormulaireAccidentOldCampagne2=loadFormulaireAccidentOldCampagne2;
        vm.loadOldCampagnesFormulairesByCCI=loadOldCampagnesFormulairesByCCI;
        vm.loadFormulaire = loadFormulaire;

        // Init variables
        vm.campagneArchivee = {};
        vm.campagnes = entityCampagne;  //Les campagnes archivées + celle sélectionnée ( ou en cours)
        vm.campagneSelected = null;  // La campagne sélectionnée
        vm.idCampagneSelected = $stateParams.idCampagneSelected;
        vm.autreActivites = [];
        vm.paramFormulaires = [];
        vm.paramTableaux = [];
        vm.formulaire = null;
        vm.formulaireAccident = null;
        vm.formulaireAccidentOldCampagne1 = null;
        vm.formulaireAccidentOldCampagne2 = null;
        vm.cci = entityCCI;
        vm.colspanValue = 6;  //les 4 colonnes fixes + celles du nom de la campagne et du total
        vm.dataByColonne = [{1: {}}, {2: {}}, {3: {}}, {4: {}}, {5: {}}, {6: {}}, {7: {}}, {8: {}}, {9: {}}];
        vm.dataCF1ByColonne = [{1: {}}, {2: {}}, {3: {}}, {4: {}}, {5: {}}, {6: {}}, {7: {}}, {8: {}}, {9: {}}];
        vm.dataCF2ByColonne = [{1: {}}, {2: {}}, {3: {}}, {4: {}}, {5: {}}, {6: {}}, {7: {}}, {8: {}}, {9: {}}];
        vm.helpMessage = false;
        vm.saveMessage = false;
        vm.campagnes = [];
        vm.oldCampagnesFormulaires = [];
        vm.campagneFermee1 = false;
        vm.campagneFermee2 = false;
        vm.isAdmin = false;
        vm.account = null;

        vm.totalNbHeuresTravailleesCampagneFermee2 = 0;
        vm.totalTauxFrequenceCampagneFermee2 = 0;
        vm.totalTauxGraviteCampagneFermee2 = 0;
        vm.totalNbHeuresTravailleesCampagneFermee1 = 0;
        vm.totalTauxFrequenceCampagneFermee1 = 0;
        vm.totalTauxGraviteCampagneFermee1 = 0;

        // Call functions when page load
        loadFormulaire();
        loadAllParamFormulaires();
        getAccount();

        function loadFormulaire(){
             Formulaire.getFormulairesWithDatas({id: $stateParams.id}, onSuccess, onError);
             function onSuccess(data){
                 vm.formulaire = data;
                 getCampagnes();
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

        function getCampagnes() {
            Campagne.getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires({idCampagneSelected: vm.idCampagneSelected}, onSuccess, onError);
            function onSuccess(data) {
                vm.campagneSelected = data[0];
                vm.campagnes = data;
                loadAllAutreActivites();
                loadFormulaireAccident();
                vm.totalNbHeuresTravailleesCampagneFermee2 = 0;
                vm.totalTauxFrequenceCampagneFermee2 = 0;
                vm.totalTauxGraviteCampagneFermee2 = 0;
                vm.totalNbHeuresTravailleesCampagneFermee1 = 0;
                vm.totalTauxFrequenceCampagneFermee1 = 0;
                vm.totalTauxGraviteCampagneFermee1 = 0;
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllParamFormulaires() {
            ParamFormulaire.loadAll({}, onSuccess, onError);
            function onSuccess(data) {
                vm.paramFormulaires = data;
                if (vm.paramFormulaires[3].descriptif) {
                    vm.paramFormulaires[3].descriptif = vm.paramFormulaires[3].descriptif.replace('![CDATA[', '').replace(']]', '');
                }
                vm.paramTableaux = vm.paramFormulaires[3].paramTableaus.sort(function (a, b) {
                    return a.ordre - b.ordre;
                });
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace(']]', '');
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllAutreActivites() {
            AutreActivite.getAutreActivitesByCciAndCampagne({
                idCCI: vm.cci.id,
                idCampagne: vm.campagneSelected.id
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.autreActivites = [];
                angular.forEach(data, function (value, key) {
                    if (vm.campagnes[0] !== null) {
                        if (value.campagne.id === vm.campagnes[0].id && value.cci.id === vm.cci.id) {
                            vm.autreActivites.push(value);
                        }
                    }
                });
                vm.colspanValue = vm.colspanValue + vm.autreActivites.length;

                // Récupération des données des inputs des tableaux
                var listeData = vm.formulaire.data.sort(function (a, b) {
                    return a.colonne - b.colonne;
                });

                // Tri par colonne
                angular.forEach(listeData, function (valueData, keyData) {
                    if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                        vm.dataByColonne[valueData.colonne - 1] = valueData;
                    } else if (valueData.autreActivite !== null) {
                        var found = false;

                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData.autreActivite.id && !found && vm.dataByColonne[i + 4] !== {}) {
                                vm.dataByColonne[i + 4] = valueData;
                                found = true;
                            }
                        }
                    }
                });
                loadOldCampagnesFormulairesByCCI();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadOldCampagnesFormulairesByCCI() {
            var idCampagne1 = -1;
            var idCampagne2 = -1;
            if (vm.campagnes.length > 2) {
                idCampagne2 = vm.campagnes[2].id;
            }
            if (vm.campagnes.length > 1) {
                idCampagne1 = vm.campagnes[1].id;
            }
            Formulaire.getOldCampagnesFormulairesByCCI({
                idCCI: vm.cci.id,
                idCampagne1: idCampagne1,
                idCampagne2: idCampagne2,
                type: 'nbrHrTr'
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.oldCampagnesFormulaires = data;
                if (data !== null) {
                    if (data.length > 1) {
                        var listeData = vm.oldCampagnesFormulaires[1].data.sort(function (a, b) {
                            return a.colonne - b.colonne;
                        });
                        angular.forEach(listeData, function (valueData, keyData) {
                            if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                                vm.dataCF2ByColonne[valueData.colonne - 1] = valueData;
                            } else if(valueData.autreActivite !== null){
                              //traitement des autres activités
                              var found =false;
                              for(var i=0; i<vm.autreActivites.length; i++){
                                  if(vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataCF2ByColonne[i+4]!=={}){
                                      vm.dataCF2ByColonne[i+4] = valueData;
                                      found = true;
                                  }
                              }
                          }
                            vm.totalNbHeuresTravailleesCampagneFermee2 += valueData.nbHeuresTravaillees;
                        });
                    }

                    if (data.length > 0) {
                        var listeData = vm.oldCampagnesFormulaires[0].data.sort(function (a, b) {
                            return a.colonne - b.colonne;
                        });
                        angular.forEach(listeData, function (valueData, keyData) {
                            if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                                vm.dataCF1ByColonne[valueData.colonne - 1] = valueData;
                            } else if(valueData.autreActivite !== null){
                              //traitement des autres activités
                              var found =false;
                              for(var i=0; i<vm.autreActivites.length; i++){
                                  if(vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataCF1ByColonne[i+4]!=={}){
                                      vm.dataCF1ByColonne[i+4] = valueData;
                                      found = true;
                                  }
                              }
                          }
                            vm.totalNbHeuresTravailleesCampagneFermee1 += valueData.nbHeuresTravaillees;
                        });
                    }
                }
                loadFormulaireAccidentOldCampagne1();
                loadFormulaireAccidentOldCampagne2();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        // Fonction qui va chercher les infos sur le formulaire Accidents
        function loadFormulaireAccident() {
            Formulaire.getFormulaireWithDataByCCIAndCampagneAndType({
                idCCI: vm.cci.id,
                idCampagne: vm.idCampagneSelected,
                typeFormulaire: 'accidents'
            }, onSuccess, onError);
            function onSuccess(formulaireAccident) {
                vm.formulaireAccident = formulaireAccident;
                calcTaux();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        // Fonction qui va chercher les infos sur le formulaire Accidents de la campagne passée 1
        function loadFormulaireAccidentOldCampagne1() {
            var idCampagne1 = (vm.campagnes.length > 1) ? vm.campagnes[1].id : null;
            Formulaire.getFormulaireWithDataByCCIAndCampagneAndType({
                idCCI: vm.cci.id,
                idCampagne: idCampagne1,
                typeFormulaire: 'accidents'
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.formulaireAccidentOldCampagne1 = data;
                calcTauxOldCampagne();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        // Fonction qui va chercher les infos sur le formulaire Accidents de la campagne passée 2
        function loadFormulaireAccidentOldCampagne2() {
            var idCampagne2 = (vm.campagnes.length > 2) ? vm.campagnes[2].id : null;
            Formulaire.getFormulaireWithDataByCCIAndCampagneAndType({
                idCCI: vm.cci.id,
                idCampagne: idCampagne2,
                typeFormulaire: 'accidents'
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.formulaireAccidentOldCampagne2 = data;
                calcTauxOldCampagne();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function calcTaux() {
            calcTauxFrequence();
            calcTauxGravite();
        }

        function calcTauxOldCampagne() {
            calcTauxFrequenceOldCampagne1();
            calcTauxGraviteOldCampagne1();
            calcTauxFrequenceOldCampagne2();
            calcTauxGraviteOldCampagne2();
        }

        function calcTauxFrequence() {
            if (null !== vm.formulaireAccident) {
                var dataNbAccidentsAvecArret = [];
                if (vm.formulaireAccident.complet) {
                //Recuperer les valeurs des datas correspondant aux nbAccidentsAvecArret du formulaire accidents
                    for(var j =0; j<vm.formulaireAccident.data.length; j++){
                        if(vm.formulaireAccident.data[j].nbAccidentsAvecArret !== null && typeof vm.formulaireAccident.data[j].nbAccidentsAvecArret !== 'undefined'
                            && vm.formulaireAccident.data[j].tableau === 1){
                            dataNbAccidentsAvecArret.push(vm.formulaireAccident.data[j]);
                        }
                    }
                    dataNbAccidentsAvecArret.sort(function (a, b) { return a.colonne - b.colonne;});
                    var dataSize = vm.dataByColonne.length;
                    for (var i = 0; i < dataSize; i++) {
                        if (vm.dataByColonne[i].nbHeuresTravaillees !== null && typeof vm.dataByColonne[i].nbHeuresTravaillees !== 'undefined') {
                            if (0 !== parseInt(vm.dataByColonne[i].nbHeuresTravaillees)) {
                                var nbAccidentsAvecArret = 0;
                                //parcourir la liste des nolbre d'accident afin  de récupérer la bonne donnée
                                for(var k = 0 ; k<dataNbAccidentsAvecArret.length ; k++){
                                   if((null !== vm.dataByColonne[i].colonne && null !== dataNbAccidentsAvecArret[k].colonne && vm.dataByColonne[i].colonne===dataNbAccidentsAvecArret[k].colonne)
                                   || (null !== vm.dataByColonne[i].autreActivite && null !== dataNbAccidentsAvecArret[k].autreActivite && vm.dataByColonne[i].autreActivite.id===dataNbAccidentsAvecArret[k].autreActivite.id)){
                                       nbAccidentsAvecArret = dataNbAccidentsAvecArret[k].nbAccidentsAvecArret;

                                   }
                                }
                                vm.dataByColonne[i].tauxFrequence = (parseInt(nbAccidentsAvecArret) / parseInt(vm.dataByColonne[i].nbHeuresTravaillees)) * 1000000;





                            } else {
                                vm.dataByColonne[i].tauxFrequence = 0;
                            }
                        }
                    }
                    calculerTotalFrequence();
                }
            }
        }

        function calcTauxGravite() {
            if (null !== vm.formulaireAccident) {
                var dataNbJourneesPerdues = [];
                if (vm.formulaireAccident.complet) {
                //Recuperer les valeurs des datas correspondant aux nbJourneesPerdues du formulaire accidents
                    for(var j =0; j<vm.formulaireAccident.data.length; j++){
                        if(vm.formulaireAccident.data[j].nbJourneesPerdues !== null && typeof vm.formulaireAccident.data[j].nbJourneesPerdues !== 'undefined'
                            && vm.formulaireAccident.data[j].tableau === 2){
                            dataNbJourneesPerdues.push(vm.formulaireAccident.data[j]);
                        }
                    }
                    dataNbJourneesPerdues.sort(function (a, b) { return a.colonne - b.colonne;});
                    var dataSize = vm.dataByColonne.length;
                    for (var i = 0; i < dataSize; i++) {
                        if (vm.dataByColonne[i].nbHeuresTravaillees !== null && typeof vm.dataByColonne[i].nbHeuresTravaillees !== 'undefined') {
                            if (0 !== parseInt(vm.dataByColonne[i].nbHeuresTravaillees)) {
                            var nbJourneesPerdues = 0;
                            // parcourir la liste des nombres de journées perdues afin  de récupérer la bonne donnée
                            for(var k = 0 ; k<dataNbJourneesPerdues.length ; k++){
                               if((null !== vm.dataByColonne[i].colonne && null !== dataNbJourneesPerdues[k].colonne && vm.dataByColonne[i].colonne===dataNbJourneesPerdues[k].colonne)
                               || (null !== vm.dataByColonne[i].autreActivite && null !== dataNbJourneesPerdues[k].autreActivite && vm.dataByColonne[i].autreActivite.id===dataNbJourneesPerdues[k].autreActivite.id)){
                                   nbJourneesPerdues = dataNbJourneesPerdues[k].nbJourneesPerdues;

                               }
                            }
                            vm.dataByColonne[i].tauxGravite = (parseInt(nbJourneesPerdues) / parseInt(vm.dataByColonne[i].nbHeuresTravaillees)) * 1000;

                            } else {
                                vm.dataByColonne[i].tauxGravite = 0;
                            }
                        }
                    }
                }
                calculerTotalGravite();
            }
        }

        //Pour le calcul des taux de fréquence de la campagne passée 1
        function calcTauxFrequenceOldCampagne1() {
            if (null !== vm.formulaireAccidentOldCampagne1) {
                var dataNbAccidentsAvecArret = [];
                if (vm.formulaireAccidentOldCampagne1.complet) {
                    for(var j =0; j<vm.formulaireAccidentOldCampagne1.data.length; j++){
                        if(vm.formulaireAccidentOldCampagne1.data[j].nbAccidentsAvecArret !== null && typeof vm.formulaireAccidentOldCampagne1.data[j].nbAccidentsAvecArret !== 'undefined'
                            && vm.formulaireAccidentOldCampagne1.data[j].tableau === 1){
                            dataNbAccidentsAvecArret.push(vm.formulaireAccidentOldCampagne1.data[j]);
                        }
                    }
                    dataNbAccidentsAvecArret.sort(function (a, b) { return a.colonne - b.colonne;});
                    var dataSize = vm.dataCF1ByColonne.length;
                    for (var i = 0; i < dataSize; i++) {
                        if (vm.dataCF1ByColonne[i].nbHeuresTravaillees !== null && typeof vm.dataCF1ByColonne[i].nbHeuresTravaillees !== 'undefined') {
                            if (0 !== parseInt(vm.dataCF1ByColonne[i].nbHeuresTravaillees)) {
                                var nbAccidentsAvecArret = 0;
                                //parcourir la liste des nolbre d'accident afin  de récupérer la bonne donnée
                                for(var k = 0 ; k<dataNbAccidentsAvecArret.length ; k++){
                                   if((null !== vm.dataCF1ByColonne[i].colonne && null !== dataNbAccidentsAvecArret[k].colonne && vm.dataCF1ByColonne[i].colonne===dataNbAccidentsAvecArret[k].colonne)
                                   || (null !== vm.dataCF1ByColonne[i].autreActivite && null !== dataNbAccidentsAvecArret[k].autreActivite && vm.dataCF1ByColonne[i].autreActivite.id===dataNbAccidentsAvecArret[k].autreActivite.id)){
                                       nbAccidentsAvecArret = dataNbAccidentsAvecArret[k].nbAccidentsAvecArret;

                                   }
                                }
                                vm.dataCF1ByColonne[i].tauxFrequence = (parseInt(nbAccidentsAvecArret) / parseInt(vm.dataCF1ByColonne[i].nbHeuresTravaillees)) * 1000000;
                            } else {
                                vm.dataCF1ByColonne[i].tauxFrequence = 0;
                            }
                        }
                    }
                    calculerTotalFrequenceOldCampagne1();
                }
            }
        }

        //Pour le calcul des taux de gravite de la campagne passée 1
        function calcTauxGraviteOldCampagne1() {
            if (null !== vm.formulaireAccidentOldCampagne1) {
                var dataNbJourneesPerdues = [];
                if (vm.formulaireAccidentOldCampagne1.complet) {
                    for(var j =0; j<vm.formulaireAccidentOldCampagne1.data.length; j++){
                        if(vm.formulaireAccidentOldCampagne1.data[j].nbJourneesPerdues !== null && typeof vm.formulaireAccidentOldCampagne1.data[j].nbJourneesPerdues !== 'undefined'
                            && vm.formulaireAccidentOldCampagne1.data[j].tableau === 2){
                            dataNbJourneesPerdues.push(vm.formulaireAccidentOldCampagne1.data[j]);
                        }
                    }
                    dataNbJourneesPerdues.sort(function (a, b) { return a.colonne - b.colonne;});
                    var dataSize = vm.dataCF1ByColonne.length;
                    for (var i = 0; i < dataSize; i++) {
                        if (vm.dataCF1ByColonne[i].nbHeuresTravaillees !== null && typeof vm.dataCF1ByColonne[i].nbHeuresTravaillees !== 'undefined') {
                            if (0 !== parseInt(vm.dataCF1ByColonne[i].nbHeuresTravaillees)) {
                                var nbJourneesPerdues = 0;
                                // parcourir la liste des nombres de journées perdues afin  de récupérer la bonne donnée
                                for(var k = 0 ; k<dataNbJourneesPerdues.length ; k++){
                                   if((null !== vm.dataCF1ByColonne[i].colonne && null !== dataNbJourneesPerdues[k].colonne && vm.dataCF1ByColonne[i].colonne===dataNbJourneesPerdues[k].colonne)
                                   || (null !== vm.dataCF1ByColonne[i].autreActivite && null !== dataNbJourneesPerdues[k].autreActivite && vm.dataCF1ByColonne[i].autreActivite.id===dataNbJourneesPerdues[k].autreActivite.id)){
                                       nbJourneesPerdues = dataNbJourneesPerdues[k].nbJourneesPerdues;

                                   }
                                }
                                vm.dataCF1ByColonne[i].tauxGravite = (parseInt(nbJourneesPerdues) / parseInt(vm.dataCF1ByColonne[i].nbHeuresTravaillees)) * 1000;
                            } else {
                                vm.dataCF1ByColonne[i].tauxGravite = 0;
                            }
                        }
                    }
                    calculerTotalGraviteOldCampagne1();
                }
            }
        }

        //Pour le calcul des taux de fréquence de la campagne passée 2
        function calcTauxFrequenceOldCampagne2() {
            if (null !== vm.formulaireAccidentOldCampagne2) {
                var dataNbAccidentsAvecArret = [];
                if (vm.formulaireAccidentOldCampagne2.complet) {
                    for(var j =0; j<vm.formulaireAccidentOldCampagne2.data.length; j++){
                        if(vm.formulaireAccidentOldCampagne2.data[j].nbAccidentsAvecArret !== null && typeof vm.formulaireAccidentOldCampagne2.data[j].nbAccidentsAvecArret !== 'undefined'
                            && vm.formulaireAccidentOldCampagne2.data[j].tableau === 1){
                            dataNbAccidentsAvecArret.push(vm.formulaireAccidentOldCampagne2.data[j]);
                        }
                    }
                    dataNbAccidentsAvecArret.sort(function (a, b) { return a.colonne - b.colonne;});
                    var dataSize = vm.dataCF2ByColonne.length;
                    for (var i = 0; i < dataSize; i++) {
                        if (vm.dataCF2ByColonne[i].nbHeuresTravaillees !== null && typeof vm.dataCF2ByColonne[i].nbHeuresTravaillees !== 'undefined') {
                            if (0 !== parseInt(vm.dataCF2ByColonne[i].nbHeuresTravaillees)) {
                                var nbAccidentsAvecArret = 0;
                                //parcourir la liste des nolbre d'accident afin  de récupérer la bonne donnée
                                for(var k = 0 ; k<dataNbAccidentsAvecArret.length ; k++){
                                   if((null !== vm.dataCF2ByColonne[i].colonne && null !== dataNbAccidentsAvecArret[k].colonne && vm.dataCF2ByColonne[i].colonne===dataNbAccidentsAvecArret[k].colonne)
                                   || (null !== vm.dataCF2ByColonne[i].autreActivite && null !== dataNbAccidentsAvecArret[k].autreActivite && vm.dataCF2ByColonne[i].autreActivite.id===dataNbAccidentsAvecArret[k].autreActivite.id)){
                                       nbAccidentsAvecArret = dataNbAccidentsAvecArret[k].nbAccidentsAvecArret;

                                   }
                                }
                                vm.dataCF2ByColonne[i].tauxFrequence = (parseInt(nbAccidentsAvecArret) / parseInt(vm.dataCF2ByColonne[i].nbHeuresTravaillees)) * 1000000;
                            } else {
                                vm.dataCF2ByColonne[i].tauxFrequence = 0;
                            }
                        }
                    }
                    calculerTotalFrequenceOldCampagne2();
                }
            }
        }

        //Pour le calcul des taux de gravite de la campagne passée 2
        function calcTauxGraviteOldCampagne2() {
            if (null !== vm.formulaireAccidentOldCampagne2) {
                var dataNbJourneesPerdues = [];
                if (vm.formulaireAccidentOldCampagne2.complet) {
                    for(var j =0; j<vm.formulaireAccidentOldCampagne2.data.length; j++){
                        if(vm.formulaireAccidentOldCampagne2.data[j].nbJourneesPerdues !== null && typeof vm.formulaireAccidentOldCampagne2.data[j].nbJourneesPerdues !== 'undefined'
                            && vm.formulaireAccidentOldCampagne2.data[j].tableau === 2){
                            dataNbJourneesPerdues.push(vm.formulaireAccidentOldCampagne2.data[j]);
                        }
                    }
                    dataNbJourneesPerdues.sort(function (a, b) { return a.colonne - b.colonne;});
                    var dataSize = vm.dataCF2ByColonne.length;
                    for (var i = 0; i < dataSize; i++) {
                        if (vm.dataCF2ByColonne[i].nbHeuresTravaillees !== null && typeof vm.dataCF2ByColonne[i].nbHeuresTravaillees !== 'undefined') {
                            if (0 !== parseInt(vm.dataCF2ByColonne[i].nbHeuresTravaillees)) {
                                var nbJourneesPerdues = 0;
                                // parcourir la liste des nombres de journées perdues afin  de récupérer la bonne donnée
                                for(var k = 0 ; k<dataNbJourneesPerdues.length ; k++){
                                   if((null !== vm.dataCF2ByColonne[i].colonne && null !== dataNbJourneesPerdues[k].colonne && vm.dataCF2ByColonne[i].colonne===dataNbJourneesPerdues[k].colonne)
                                   || (null !== vm.dataCF2ByColonne[i].autreActivite && null !== dataNbJourneesPerdues[k].autreActivite && vm.dataCF2ByColonne[i].autreActivite.id===dataNbJourneesPerdues[k].autreActivite.id)){
                                       nbJourneesPerdues = dataNbJourneesPerdues[k].nbJourneesPerdues;

                                   }
                                }
                                vm.dataCF2ByColonne[i].tauxGravite = (parseInt(nbJourneesPerdues) / parseInt(vm.dataCF2ByColonne[i].nbHeuresTravaillees)) * 1000;
                            } else {
                                vm.dataCF2ByColonne[i].tauxGravite = 0;
                            }
                        }
                    }
                    calculerTotalGraviteOldCampagne2();
                }
            }
        }

        function printFormulaire() {
            window.print();
        }

        function sauvegarder() {
            var warning = 'Attention, tous les champs laissés à vide sont considérés comme étant à 0';
            if(!vm.formulaire.complet){
                if (window.confirm(warning)) {
                    Formulaire.update(vm.formulaire, onSuccess, onError);
                }
            } else {
                Formulaire.update(vm.formulaire, onSuccess, onError);
            }
            function onSuccess(data) {
                vm.saveMessage = true;
                loadFormulaire();
                window.scrollTo(0, 0);
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                vm.saveMessage = false;
            }
        }

        function showHelp() {
            if (!vm.helpMessage) {
                vm.helpMessage = true;
            } else {
                vm.helpMessage = false;
            }
        }

        function confirmerRetour() {
            getAccount();
            var message = 'Les informations non sauvegardées seront perdues.';
            if (window.confirm(message)) {
                if (vm.isAdmin) {
                    $state.go('list-formulaires-cci', {id: vm.cci.id, idCampagne: vm.campagneSelected.id});
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id !== vm.account.cci.id) {  //si la cci du formulaire en question est differente de celle du user connecté
                    $state.go('list-formulaires-cci', {id: vm.cci.id, idCampagne: vm.campagneSelected.id});
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id === vm.account.cci.id) {
                    $state.go('mon-bilan-formulaire');
                } else {
                    $state.go('mon-bilan-formulaire');
                }
            }
        }

        function calculerTotalFrequence(){
            var totalNbAccidentsAvecArret = 0;
            var totalNbHeuresTravaillees = 0;
            if (vm.formulaireAccident !== null && typeof vm.formulaireAccident !== 'undefined' ) {
                if (vm.formulaireAccident.complet) {
                //calculer le nombre d'heures travaillees
                 angular.forEach(vm.dataByColonne, function(value,key){
                      if (value.nbHeuresTravaillees !== null && typeof value.nbHeuresTravaillees !== 'undefined') {
                           totalNbHeuresTravaillees += parseInt(value.nbHeuresTravaillees);
                      }
                 });
                 //calculer le nombre d'accident avec arret
                 angular.forEach(vm.formulaireAccident.data, function(v,k){
                     if (v.nbAccidentsAvecArret !== null) {
                          totalNbAccidentsAvecArret += parseInt(v.nbAccidentsAvecArret);
                     }
                });
                }
            }
            if(totalNbHeuresTravaillees > 0){
                return (totalNbAccidentsAvecArret / totalNbHeuresTravaillees) * 1000000;
            }else {
                return null;
            }
        }

        function calculerTotalFrequenceOldCampagne1(){
            var totalNbAccidentsAvecArret = 0;
            var totalNbHeuresTravaillees = 0;
            if (vm.formulaireAccidentOldCampagne1 !== null && typeof vm.formulaireAccidentOldCampagne1 !== 'undefined' ) {
                if (vm.formulaireAccidentOldCampagne1.complet) {
                    //calculer le nombre d'heures travaillees
                    if (vm.oldCampagnesFormulaires.length > 0) {
                        angular.forEach(vm.oldCampagnesFormulaires[0].data, function (value, keyData) {
                            if (value.nbHeuresTravaillees !== null && typeof value.nbHeuresTravaillees !== 'undefined') {
                               totalNbHeuresTravaillees += parseInt(value.nbHeuresTravaillees);
                          }
                        });
                    }

                     //calculer le nombre d'accident avec arret
                     angular.forEach(vm.formulaireAccidentOldCampagne1.data, function(v,k){
                         if (v.nbAccidentsAvecArret !== null) {
                              totalNbAccidentsAvecArret += parseInt(v.nbAccidentsAvecArret);
                         }
                    });
                }
            }
            if(totalNbHeuresTravaillees > 0){
                return (totalNbAccidentsAvecArret / totalNbHeuresTravaillees) * 1000000;
            }else {
                return null;
            }
        }

        function calculerTotalFrequenceOldCampagne2(){
            var totalNbAccidentsAvecArret = 0;
            var totalNbHeuresTravaillees = 0;
            if (vm.formulaireAccidentOldCampagne2 !== null && typeof vm.formulaireAccidentOldCampagne2 !== 'undefined' ) {
                if (vm.formulaireAccidentOldCampagne2.complet) {
                     //calculer le nombre d'heures travaillees
                     if (vm.oldCampagnesFormulaires.length > 1) {
                         angular.forEach(vm.oldCampagnesFormulaires[1].data, function (value, keyData) {
                             if (value.nbHeuresTravaillees !== null && typeof value.nbHeuresTravaillees !== 'undefined') {
                                totalNbHeuresTravaillees += parseInt(value.nbHeuresTravaillees);
                           }
                         });
                     }
                     // calculer le nombre d'accidents avec arret
                     angular.forEach(vm.formulaireAccidentOldCampagne2.data, function(v,k){
                         if (v.nbAccidentsAvecArret !== null) {
                              totalNbAccidentsAvecArret += parseInt(v.nbAccidentsAvecArret);
                         }
                    });
                }
            }

            if(totalNbHeuresTravaillees > 0){
                return (totalNbAccidentsAvecArret / totalNbHeuresTravaillees) * 1000000;
            }else {
                return null;
            }
        }

        function calculerTotalGravite(){
            var totalNbJourneesPerdues = 0;
            var totalNbHeuresTravaillees = 0;
            if (vm.formulaireAccident  !== null && typeof vm.formulaireAccident !== 'undefined' ) {
                if (vm.formulaireAccident.complet) {
                     //calculer le nombre d'heures travaillées
                     angular.forEach(vm.dataByColonne, function(value,key){
                          if (value.nbHeuresTravaillees !== null && typeof value.nbHeuresTravaillees !== 'undefined') {
                               totalNbHeuresTravaillees += parseInt(value.nbHeuresTravaillees);
                          }
                     });

                     //calculer le nombre de journées perdues
                     angular.forEach(vm.formulaireAccident.data, function(v,k){
                         if (v.nbJourneesPerdues !== null) {
                              totalNbJourneesPerdues += parseInt(v.nbJourneesPerdues);
                         }
                    });
                }
            }
            if(totalNbHeuresTravaillees > 0){
                return (totalNbJourneesPerdues / totalNbHeuresTravaillees) * 1000;
            }else {
                return null;
            }
        }

        function calculerTotalGraviteOldCampagne1(){
            var totalNbJourneesPerdues = 0;
            var totalNbHeuresTravaillees = 0;
            if (vm.formulaireAccidentOldCampagne1 !== null && typeof vm.formulaireAccidentOldCampagne1 !== 'undefined' ) {
                if (vm.formulaireAccidentOldCampagne1.complet) {
                     //calculer le nombre d'heures travaillées
                     if (vm.oldCampagnesFormulaires.length > 0) {
                         angular.forEach(vm.oldCampagnesFormulaires[0].data, function (value, keyData) {
                             if (value.nbHeuresTravaillees !== null && typeof value.nbHeuresTravaillees !== 'undefined') {
                                totalNbHeuresTravaillees += parseInt(value.nbHeuresTravaillees);
                           }
                         });
                     }
                     // calculer le nombre de jounrées perdues
                     angular.forEach(vm.formulaireAccidentOldCampagne1.data, function(v,k){
                         if (v.nbJourneesPerdues !== null) {
                              totalNbJourneesPerdues += parseInt(v.nbJourneesPerdues);
                         }
                    });
                }
            }
            if(totalNbHeuresTravaillees > 0){
                return (totalNbJourneesPerdues / totalNbHeuresTravaillees) * 1000;
            }else {
                return null;
            }
        }

        function calculerTotalGraviteOldCampagne2(){
            var totalNbJourneesPerdues = 0;
            var totalNbHeuresTravaillees = 0;
            if (vm.formulaireAccidentOldCampagne2 !== null && typeof vm.formulaireAccidentOldCampagne2 !== 'undefined' ) {
                if (vm.formulaireAccidentOldCampagne2.complet) {
                    // nombre d'heures travaillées
                    if (vm.oldCampagnesFormulaires.length > 1) {
                         angular.forEach(vm.oldCampagnesFormulaires[1].data, function (value, keyData) {
                             if (value.nbHeuresTravaillees !== null && typeof value.nbHeuresTravaillees !== 'undefined') {
                                totalNbHeuresTravaillees += parseInt(value.nbHeuresTravaillees);
                           }
                         });
                     }
                     //calculer le nombre de journées perdues
                     angular.forEach(vm.formulaireAccidentOldCampagne2.data, function(v,k){
                         if (v.nbJourneesPerdues !== null) {
                              totalNbJourneesPerdues += parseInt(v.nbJourneesPerdues);
                         }
                    });
                }
            }
            if(totalNbHeuresTravaillees > 0){
                return (totalNbJourneesPerdues / totalNbHeuresTravaillees) * 1000;
            }else {
                return null;
            }
        }
    }
})
();
