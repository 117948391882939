(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('CCIDeleteController',CCIDeleteController);

    CCIDeleteController.$inject = ['$uibModalInstance', 'entity', 'CCI'];

    function CCIDeleteController($uibModalInstance, entity, CCI) {
        var vm = this;

        vm.cCI = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CCI.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
