(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('TableauMontantDepensesEngageesCtrl', TableauMontantDepensesEngageesCtrl);

    TableauMontantDepensesEngageesCtrl.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function TableauMontantDepensesEngageesCtrl ($scope, $state, ParseLinks, AlertService) {
        var vm = this;
    }
})();
