(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireDetailController', FormulaireDetailController);

    FormulaireDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Formulaire', 'DataElement', 'Data', 'CCI', 'Campagne'];

    function FormulaireDetailController($scope, $rootScope, $stateParams, previousState, entity, Formulaire, DataElement, Data, CCI, Campagne) {
        var vm = this;

        vm.formulaire = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bhsApp:formulaireUpdate', function(event, result) {
            vm.formulaire = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
