(function() {
    'use strict';

    angular
        .module('bhsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('c-ci', {
            parent: 'admin',
            url: '/c-ci?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'bhsApp.cCI.home.title'
            },
            views: {
                'content@bhs': {
                    templateUrl: 'app/entities/c-ci/c-cis.html',
                    controller: 'CCIController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('cCI');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('c-ci.new', {
            parent: 'c-ci',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/c-ci/c-ci-dialog.html',
                    controller: 'CCIDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nom: null,
                                type: "CCI",
                                actif: true,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('c-ci', null, { reload: 'c-ci' });
                }, function() {
                    $state.go('c-ci');
                });
            }]
        })
        .state('c-ci.update-statut', {
            parent: 'c-ci',
            url: '/{id}/update-statut',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                   templateUrl: 'app/entities/c-ci/c-ci-update-statut.html',
                    controller: 'CCIDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CCI', function(CCI) {
                            return CCI.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('c-ci', null, { reload: 'c-ci' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('c-ci.delete', {
            parent: 'c-ci',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/c-ci/c-ci-delete-dialog.html',
                    controller: 'CCIDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CCI', function(CCI) {
                            return CCI.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('c-ci', null, { reload: 'c-ci' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
