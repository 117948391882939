(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireDepenseCtrl', FormulaireDepenseCtrl);

    FormulaireDepenseCtrl.$inject = ['$scope', '$state', 'Formulaire', 'AlertService', 'AutreActivite', 'ParamFormulaire', 'Campagne', 'entityCCI',  'Principal', 'entityCampagne', '$stateParams'];

    function FormulaireDepenseCtrl ($scope, $state, Formulaire, AlertService, AutreActivite, ParamFormulaire, Campagne, entityCCI, Principal, entityCampagne, $stateParams) {
        var vm = this;

        //Init functions
        vm.printFormulaire = printFormulaire;
        vm.sauvegarder = sauvegarder;
        vm.showHelp1 = showHelp1;
        vm.showHelp2 = showHelp2;
        vm.confirmerRetour = confirmerRetour;
        vm.loadFormulaire = loadFormulaire;

        // Init variables
        vm.campagneArchivee = {};
        vm.campagnes = entityCampagne;  //Les campagnes archivées + celle sélectionnée ( ou en cours)
        vm.campagneSelected = null;  // La campagne sélectionnée
        vm.idCampagneSelected = $stateParams.idCampagneSelected ;
        vm.autreActivites = [];
        vm.paramFormulaires = [];
        vm.paramTableaux = [];
        vm.formulaire = null;
        vm.cci = entityCCI;
        vm.colspanValue = 6;  //les 4 colonnes fixes + celles du nom de la campagne et du total
        vm.dataByColonne = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
        vm.dataCF1ByColonne = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
        vm.dataCF2ByColonne = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
        vm.dataTab1ByColonne = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
        vm.dataTab1CF1ByColonne = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
        vm.dataTab1CF2ByColonne = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
        vm.helpMessage1 = false;
        vm.helpMessage2 = false;
        vm.saveMessage = false;
        vm.campagnes = [];
        vm.oldCampagnesFormulaires =  [];
        vm.campagneFermee1Depenses = false;
        vm.campagneFermee1AT = false;
        vm.campagneFermee2Depenses = false;
        vm.campagneFermee2AT = false;
        vm.isAdmin = false;
        vm.regex = '^\\d{0,1}(\\.\\d{1,2})?$';

        vm.account = null;

        vm.totalDepensesEngageesCampagneFermee1 = 0;
        vm.totalDepensesEngageesCampagneFermee2 = 0;
        vm.totalCotisationsVerseesCampagneFermee1 = 0;
        vm.totalCotisationsVerseesCampagneFermee2 = 0;
        vm.totalTauxATCampagneFermee1 = 0;
        vm.totalTauxATCampagneFermee2 = 0;

        //Call functions when page load
        loadFormulaire();
        loadAllParamFormulaires();
        getAccount();

        function loadFormulaire(){
             Formulaire.getFormulairesWithDatas({id: $stateParams.id}, onSuccess, onError);
             function onSuccess(data){
                 vm.formulaire = data;
                 getAllCampagnes();
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

        function getAccount() {
            Principal.identity().then(function(account) {
            vm.account = account;
            });
        }

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

        function getAllCampagnes() {
            Campagne.getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires({idCampagneSelected: vm.idCampagneSelected}, onSuccess, onError);
            function onSuccess(data){
                vm.campagneSelected = data[0];
                vm.campagnes = data;
                loadAllAutreActivites();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllParamFormulaires() {
            ParamFormulaire.loadAll({}, onSuccess, onError);
            function onSuccess(data) {
                vm.paramFormulaires = data;
                if (vm.paramFormulaires[9].descriptif) {
                    vm.paramFormulaires[9].descriptif = vm.paramFormulaires[9].descriptif.replace('![CDATA[', '').replace(']]', '');
                }
                vm.paramTableaux = vm.paramFormulaires[9].paramTableaus.sort(function(a,b) {return a.ordre - b.ordre;});
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace('![CDATA[','');  //Iterer sur le reste des tableaux pour les formulaires qui en ont plusieurs
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace(']]','');
                if(vm.paramTableaux.length>1){
                    vm.paramTableaux[1].descriptif = vm.paramTableaux[1].descriptif.replace('![CDATA[','');  //Iterer sur le reste des tableaux pour les formulaires qui en ont plusieurs
                    vm.paramTableaux[1].descriptif = vm.paramTableaux[1].descriptif.replace(']]','');
                }
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllAutreActivites() {
            AutreActivite.getAutreActivitesByCciAndCampagne({idCCI: vm.cci.id, idCampagne: vm.campagneSelected.id}, onSuccess, onError);
            function onSuccess(data) {
                vm.autreActivites = [];
                angular.forEach(data, function(value, key){
                    if(vm.campagnes[0] !== null){
                        if(value.campagne.id === vm.campagnes[0].id && value.cci.id === vm.cci.id){
                            vm.autreActivites.push(value);
                        }
                    }
                });
                vm.colspanValue = vm.colspanValue + vm.autreActivites.length;
                //Récupération des données des inputs des tableaux
                var listeData = vm.formulaire.data.sort(function(a,b) {return a.colonne - b.colonne ;}); //Tri par colonne
                angular.forEach(listeData, function(valueData,keyData){
                    if(valueData.colonne !== null && valueData.colonne<5 && valueData.colonne >0){
                        if(valueData.tableau ===1){
                            vm.dataByColonne[valueData.colonne - 1] = valueData;
                        } else if (valueData.tableau ===2){
                             vm.dataTab1ByColonne[valueData.colonne - 1] = valueData;
                        }
                    }else if(valueData.autreActivite !== null){
                        var found =false;
                        for(var i=0; i<vm.autreActivites.length; i++){
                            if(valueData.tableau ===1){
                                if(vm.autreActivites[i].id === valueData.autreActivite.id && !found && vm.dataByColonne[i+4]!=={}){
                                    vm.dataByColonne[i+4] = valueData;
                                    found = true;
                                }
                            }
                            else if(valueData.tableau ===2){
                                 if(vm.autreActivites[i].id === valueData.autreActivite.id && !found && vm.dataTab1ByColonne[i+4]!=={}){
                                     vm.dataTab1ByColonne[i+4] = valueData;
                                     found = true;
                                 }
                             }
                        }
                    }
                });
                loadOldCampagnesFormulairesByCCI();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function printFormulaire() {
            window.print();
        }

        function onSuccessSauvegarder(data) {
            vm.saveMessage=true;
            loadFormulaire();
            window.scrollTo(0, 0);
        }
        function onErrorSauvegarder(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            vm.saveMessage=false;
        }

        function sauvegarder() {
            var message = 'Assurez-vous d’avoir saisi vos montants en Kilo euros et non en euros. Confirmez-vous l’enregistrement ?'
            if (window.confirm(message)) {
                var warning = 'Attention, tous les champs laissés à vide sont considérés comme étant à 0';
                if(!vm.formulaire.complet){
                    if (window.confirm(warning)) {
                        Formulaire.update(vm.formulaire, onSuccessSauvegarder, onErrorSauvegarder);
                    }
                } else {
                    Formulaire.update(vm.formulaire, onSuccessSauvegarder, onErrorSauvegarder);
                }
            }
        }

        function loadOldCampagnesFormulairesByCCI() {
            var idCampagne1 = -1;
            var idCampagne2 = -1;
            if(vm.campagnes.length>2){
                idCampagne2 = vm.campagnes[2].id;
            }
            if(vm.campagnes.length>1){
                idCampagne1 = vm.campagnes[1].id;
            }
            Formulaire.getOldCampagnesFormulairesByCCI({idCCI: vm.cci.id, idCampagne1: idCampagne1, idCampagne2:idCampagne2, type: 'depense'}, onSuccess,onError);
            function onSuccess(data) {
                vm.oldCampagnesFormulaires = data;
                vm.totalDepensesEngageesCampagneFermee1 = 0;
                vm.totalDepensesEngageesCampagneFermee2 = 0;
                vm.totalCotisationsVerseesCampagneFermee1 = 0;
                vm.totalCotisationsVerseesCampagneFermee2 = 0;
                vm.totalTauxATCampagneFermee1 = 0;
                vm.totalTauxATCampagneFermee2 = 0;
                if(data!== null){
                    if(data.length>1){
                        var listeData = vm.oldCampagnesFormulaires[1].data.sort(function(a,b) {return a.colonne - b.colonne ;});
                        angular.forEach(listeData, function(valueData,keyData){
                            if(valueData.colonne !== null && valueData.colonne<5 && valueData.colonne >0){
                                if(valueData.tableau ===1){
                                    vm.dataCF2ByColonne[valueData.colonne - 1] = valueData;
                                    }
                                else if(valueData.tableau ===2){
                                     vm.dataTab1CF2ByColonne[valueData.colonne - 1] = valueData;
                                }
                            }
                            if(valueData.depensesEngagees !== null){
                                vm.totalDepensesEngageesCampagneFermee2 += valueData.depensesEngagees;
                            }
                            if(valueData.cotisationsVersees !== null){
                                vm.totalCotisationsVerseesCampagneFermee2 += valueData.cotisationsVersees;
                            }
                            if(valueData.tauxAT !== null){
                                vm.totalTauxATCampagneFermee2 += valueData.tauxAT;
                            }
                            if(valueData.autreActivite !== null){
                                //traitement des autres activités
                                var found =false;
                                for(var i=0; i<vm.autreActivites.length; i++){
                                    if(valueData.tableau ===1){
                                        if(vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataCF2ByColonne[i+4]!=={}){
                                            vm.dataCF2ByColonne[i+4] = valueData;
                                            found = true;
                                        }
                                    } else if(valueData.tableau ===2){
                                        if(vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataTab1CF2ByColonne[i+4]!=={}){
                                            vm.dataTab1CF2ByColonne[i+4] = valueData;
                                            found = true;
                                        }

                                    }
                                }
                            }
                        });
                    }
                    if(data.length>0){
                        var listeData = vm.oldCampagnesFormulaires[0].data.sort(function(a,b) {return a.colonne - b.colonne ;});
                        angular.forEach(listeData, function(valueData,keyData){
                            if(valueData.colonne !== null && valueData.colonne<5 && valueData.colonne >0){
                                if(valueData.tableau ===1){
                                    vm.dataCF1ByColonne[valueData.colonne - 1] = valueData;
                                } else if(valueData.tableau ===2){
                                    vm.dataTab1CF1ByColonne[valueData.colonne - 1] = valueData;
                                }
                            }
                            if(valueData.depensesEngagees !== null){
                                vm.totalDepensesEngageesCampagneFermee1 += valueData.depensesEngagees;
                            }
                            if(valueData.cotisationsVersees !== null){
                                vm.totalCotisationsVerseesCampagneFermee1 += valueData.cotisationsVersees;
                            }
                            if(valueData.tauxAT !== null){
                                vm.totalTauxATCampagneFermee1 += valueData.tauxAT;
                            }
                            if(valueData.autreActivite !== null){
                                //traitement des autres activités
                                var found =false;
                                for(var i=0; i<vm.autreActivites.length; i++){
                                    if(valueData.tableau ===1){
                                        if(vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataCF1ByColonne[i+4]!=={}){
                                            vm.dataCF1ByColonne[i+4] = valueData;
                                            found = true;
                                        }
                                    } else if(valueData.tableau ===2){
                                        if(vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataTab1CF1ByColonne[i+4]!=={}){
                                            vm.dataTab1CF1ByColonne[i+4] = valueData;
                                            found = true;
                                        }

                                    }
                                }
                            }
                        });
                    }
                }
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function showHelp1() {
            vm.helpMessage1 = !vm.helpMessage1;
        }

        function showHelp2() {
            vm.helpMessage2 = !vm.helpMessage2;
        }

        function confirmerRetour(){
            var message = 'Les informations non sauvegardées seront perdues.';
               if (window.confirm(message)) {
                   if(vm.isAdmin) {
                       $state.go('list-formulaires-cci', {id:vm.cci.id,idCampagne:vm.campagneSelected.id});
                   }else if(Principal.hasAuthority("ROLE_CCIR") && vm.cci.id !== vm.account.cci.id) {  //si la cci du formulaire en question est differente de celle du user connecté
                       $state.go('list-formulaires-cci', {id:vm.cci.id,idCampagne:vm.campagneSelected.id});
                   }else if(Principal.hasAuthority("ROLE_CCIR") && vm.cci.id === vm.account.cci.id) {
                       $state.go('mon-bilan-formulaire');
                   }else {
                       $state.go('mon-bilan-formulaire');
                   }
               }
        }
     }
})();
