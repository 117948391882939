(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('UserExport', UserExport);

    UserExport.$inject = ['$resource'];

    function UserExport ($resource) {
        var resourceUrl =  'api/exportUsers';

        return $resource(resourceUrl,{}, {
            'export': {

                            method:'POST',
                            cache: false,
                            responseType: 'arraybuffer',
                            transformResponse: function(data, headers) {
                                var zip = null;
                                if (data) {
                                    zip = new Blob([data], {
                                        type: 'application/vnd.ms-excel'
                                    });
                                }
                                //server should sent content-disposition header
                                var fileName = getFileNameFromHeader(headers('content-disposition'));
                                var result = {
                                    blob: zip,
                                    fileName: fileName
                                };
                                return {
                                    response: result
                                };
                            }
                        }
        });

        function getFileNameFromHeader(header) {
                    if (!header) return null;
                    var result = header.split(";")[1].trim().split("=")[1];
                    return result.replace(/"/g, '');
                }
    }
})();
