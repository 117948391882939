(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('TableauNombreTotalChsAuSeinCci', TableauNombreTotalChsAuSeinCci);

    TableauNombreTotalChsAuSeinCci.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService', 'Principal'];

    function TableauNombreTotalChsAuSeinCci ($scope, $state, ParseLinks, AlertService, Principal) {
        var vm = this;
        Principal.hasAuthority("ROLE_ADMIN")
                    .then(function (result) {
                        if (result) {
                            vm.isAdmin = true;
                        } else {
                            vm.isAdmin = false;
                        }
                    });
        Principal.hasAuthority("ROLE_CCIR")
                            .then(function (result) {
                                if (result) {
                                    vm.isCCIR= true;
                                } else {
                                    vm.isCCIR = false;
                                }
                            });



    }
})();
