(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('EditionTextService', EditionTextService);

    EditionTextService.$inject = ['$resource'];

    function EditionTextService ($resource) {
        var resourceUrl = 'api/edition-text';

        return $resource(resourceUrl, {}, {
            'getAllEditionText': {
                url: resourceUrl + "/all",
                method: 'GET',
                isArray: true,
            },
            'getOneEditionText': {
                method: 'GET',
                isArray: false
            },
            'update': {
                method:'PUT',
                isArray: false
            }
        });
    }
})();
