(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('ParamFormulaireController', ParamFormulaireController);

    ParamFormulaireController.$inject = ['$scope', '$state', 'ParamFormulaire', 'AlertService'];

    function ParamFormulaireController($scope, $state, ParamFormulaire, AlertService) {
        var vm = this;
        vm.paramFormulaireListe = [];
        vm.paramFormulaireSelection = null;
        vm.paramFormulaireSelectionUpdates = 0;
        vm.save = save;


        loadAll();

        function loadAll() {
            ParamFormulaire.loadAll(onSuccess, onError);

            function onSuccess(data) {
                vm.paramFormulaireListe = data;
                if (vm.paramFormulaireListe !== null && vm.paramFormulaireListe.length > 0) {
                    // Gestion des CDATA à supprimer en retour
                    for (var i = 0; i < vm.paramFormulaireListe.length; i++) {
                        var parameter = vm.paramFormulaireListe[i];
                        if (parameter.descriptif) {
                            parameter.descriptif = parameter.descriptif.replace('![CDATA[', '').replace(']]', '');
                        }
                        for (var j = 0; j < parameter.paramTableaus.length; j++) {
                            var parameterTableau = parameter.paramTableaus[j];
                            if (parameterTableau.descriptif) {
                                parameterTableau.descriptif = parameterTableau.descriptif.replace('![CDATA[', '').replace(']]', '');
                            }
                        }
                    }
                    vm.paramFormulaireSelection = vm.paramFormulaireListe[0];
                }
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function save() {
            vm.isSaving = true;
            ParamFormulaire.update(vm.paramFormulaireSelection, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            var createdAlerte = AlertService.get()[0];
            var options = {
                type: createdAlerte.type,
                msg: createdAlerte.msg,
                timeout: 5000,
                toast: createdAlerte.toast,
                position: createdAlerte.position,
                scoped: createdAlerte.scoped
            }
            AlertService.closeAlert(0);
            AlertService.add(options);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        // Editor options.
        $scope.options = {
            language: 'fr',
            allowedContent: true,
            entities: true,
            height: 80,
            removePlugins: 'scayt, wsc'
        };

        // Called when the editor is completely ready.
        $scope.onReady = function () {
            // nothing for the moment
        };

        // destroy and remove editors.
        $scope.$on('$destroy', function onDestroyCkEditors() {

            for (name in CKEDITOR.instances) {
                CKEDITOR.instances[name].destroy(true);
            }
        });

        $scope.$watch('vm.paramFormulaireSelection', function () {
            if (null !== vm.paramFormulaireSelection && angular.isDefined(vm.paramFormulaireSelection)) {
                if (vm.paramFormulaireSelectionUpdates > 0) {
                    for (name in CKEDITOR.instances) {
                        if (name !== 'ckeditor_formulaire') {
                            CKEDITOR.instances[name].destroy(true);
                        }
                    }
                }
                vm.paramFormulaireSelectionUpdates += 1;
            }
        });


    }
})();
