(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('AutreActivite', AutreActivite);

    AutreActivite.$inject = ['$resource', 'DateUtils'];

    function AutreActivite ($resource, DateUtils) {
        var resourceUrl =  'api/autre-activites/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': { method:'POST' },
            'delete':{ method:'DELETE'},
            'update': { method:'PUT' },
            'getAutreActivitesByCciAndCampagne': {
                url: 'api/autre-activites-by-cci-and-campagne/:idCCI/:idCampagne',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
