(function() {
    'use strict';

    angular
        .module('bhsApp')
        .directive('limitTo', function () {
            return {
                restrict: "A",
                link: function(scope, elem, attrs) {
                    var limit = parseInt(attrs.limitTo);
                    angular.element(elem).on("keydown", function(e) {
                        if (this.value.length >= limit && e.originalEvent.key < 10) {
                            e.preventDefault();
                        }
                    });
                }
            }
        });
})();
