(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('CampagneController', CampagneController);

    CampagneController.$inject = ['$scope', '$state', 'Campagne', 'ParseLinks', 'paginationConstants', 'pagingParams', 'AlertService'];

    function CampagneController($scope, $state, Campagne, ParseLinks, paginationConstants, pagingParams, AlertService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 50;

        loadAll();

        $scope.onNouvellCampagneClick = function () {

            Campagne.getAllCampagnes(onSuccess, onError);

            function onSuccess(data) {

                var allCampagneHasCloseDate = true;

                for (var i = 0; i < data.length; i++) {
                    var campagne = data[i];

                    if (campagne.dateFermeture === null) {
                        allCampagneHasCloseDate = false
                    }
                }

                // Si on a au moins une campagne avec une date de fin indéfinie, on affiche une erreur
                if (!allCampagneHasCloseDate) {
                    AlertService.error("Action impossible. La campagne en cours n'a pas été fermée.");
                } else {
                    // Sinon on affiche la popup
                    $state.go('campagne.new');
                }
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        };

        function loadAll() {
            Campagne.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.campagnes = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
