(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('FormulairesCciCampagneService', FormulairesCciCampagneService);

    FormulairesCciCampagneService.$inject = ['$resource', 'DateUtils'];

    function FormulairesCciCampagneService ($resource, DateUtils) {
        var resourceUrl = 'api/formulaires';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateSaisie = DateUtils.convertDateTimeFromServer(data.dateSaisie);
                        data.dateVerification = DateUtils.convertDateTimeFromServer(data.dateVerification);
                    }
                    return data;
                }
            },
            'save': {
                url: 'api/formulaires/all',
                method:'PUT',
                isArray: true
            },
            'getAllByCampagneIdAndCciId': {
                url: 'api/formulairesByCampagneAndCci/:idCCI/:idCampagne',
                method:'GET',
                isArray: true
            }
        });
    }
})();
