(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireFormationsCtrl', FormulaireFormationsCtrl);

    FormulaireFormationsCtrl.$inject = ['$scope', '$state','$stateParams', 'Formulaire', 'AlertService', 'AutreActivite', 'ParamFormulaire', 'Campagne', 'entityCCI', 'Principal', 'entityCampagne'];

    function FormulaireFormationsCtrl ($scope, $state, $stateParams, Formulaire, AlertService, AutreActivite, ParamFormulaire, Campagne, entityCCI, Principal, entityCampagne) {
        var vm = this;

        //Init functions
        vm.printFormulaire = printFormulaire;
        vm.sauvegarder = sauvegarder;
        vm.showHelp1 = showHelp1;
        vm.showHelp2 = showHelp2;
        vm.confirmerRetour = confirmerRetour;
        vm.loadFormulaire = loadFormulaire;

         // Init variables
         vm.campagnes = entityCampagne; //Les campagnes archivées + celle sélectionnée ( ou en cours)
         vm.campagneSelected = null;  // La campagne sélectionnée
         vm.idCampagneSelected = $stateParams.idCampagneSelected ;
         vm.autreActivites = [];
         vm.paramFormulaires = [];
         vm.paramTableaux = [];
         vm.formulaire = null;
         vm.cci = entityCCI;
         vm.colspanValue = 6;  //les 4 colonnes fixes + celles du nom de la campagne et du total
         vm.dataByColonneTableau1 = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
         vm.dataByColonneTableau2 = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
         vm.dataCF1ByColonneTableau1 = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
         vm.dataCF2ByColonneTableau1 = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
         vm.dataCF1ByColonneTableau2 = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
         vm.dataCF2ByColonneTableau2 = [{1:{}},{2:{}},{3:{}},{4:{}},{5:{}},{6:{}},{7:{}},{8:{}},{9:{}}];
         vm.helpMessage1=false;
         vm.helpMessage2=false;
         vm.saveMessage=false;
         vm.campagnes = [];
         vm.oldCampagnesFormulaires =  [];
         vm.campagneFermee1 = false;
         vm.campagneFermee2 = false;
         vm.campagneFermee1Bis = false;
         vm.campagneFermee2Bis = false;
         vm.isAdmin =false;
         vm.account = null;
         vm.allOk = true;

        //tableau 7.2
        vm.totalHommeCampagneFermee2 =0;
        vm.totalCadreCampagneFermee2 =0;
        vm.totalFemmeCampagneFermee2 =0;
        vm.totalNonCadreCampagneFermee2 =0;
        vm.totalCampagneFermee2 =0;
        vm.totalCampagneFermee2Bis = 0;
        vm.totalFemmeCampagneFermee1 =0;
        vm.totalNonCadreCampagneFermee1 =0;
        vm.totalHommeCampagneFermee1 =0;
        vm.totalCadreCampagneFermee1 =0;
        vm.totalCampagneFermee1 =0;
        vm.totalCampagneFermee1Bis = 0;

        //tableau 7.1
        vm.totalHomme_71_CampagneFermee2 =0;
        vm.total_71_CampagneFermee2 =0;
        vm.totalFemme_71_CampagneFermee2 =0;
        vm.totalFemme_71_CampagneFermee1 =0;
        vm.total_71_CampagneFermee1 =0;
        vm.totalHomme_71_CampagneFermee1 =0;


         //Call functions when page load
         loadFormulaire();
         loadAllParamFormulaires();
         getAccount();

         function loadFormulaire(){
             Formulaire.getFormulairesWithDatas({id: $stateParams.id}, onSuccess, onError);
             function onSuccess(data){
                 vm.formulaire = data;
                 getCampagnes();
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

         function getAccount() {
             Principal.identity().then(function(account) {
             vm.account = account;
             });
         }

         Principal.hasAuthority("ROLE_ADMIN")
             .then(function (result) {
                 if (result) {
                     vm.isAdmin=true;
                 } else {
                     vm.isAdmin = false;
                 }
             });

         function getCampagnes(){
             Campagne.getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires({idCampagneSelected: vm.idCampagneSelected}, onSuccess, onError);
             function onSuccess(data){
                 vm.campagneSelected = data[0];
                 vm.campagnes = data;
                 loadAllAutreActivites();
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

         function loadAllParamFormulaires(){
             ParamFormulaire.loadAll({}, onSuccess, onError);
             function onSuccess(data) {
                vm.paramFormulaires = data;
                if (vm.paramFormulaires[6].descriptif) {
                    vm.paramFormulaires[6].descriptif = vm.paramFormulaires[6].descriptif.replace('![CDATA[', '').replace(']]', '');
                }
                vm.paramTableaux = vm.paramFormulaires[6].paramTableaus.sort(function(a,b) {return a.ordre - b.ordre;});
                 vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace('![CDATA[','');
                 vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace(']]','');
                 vm.paramTableaux[1].descriptif = vm.paramTableaux[1].descriptif.replace('![CDATA[','');
                 vm.paramTableaux[1].descriptif = vm.paramTableaux[1].descriptif.replace(']]','');
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

         function loadAllAutreActivites() {
              AutreActivite.getAutreActivitesByCciAndCampagne({idCCI: vm.cci.id, idCampagne: vm.campagneSelected.id}, onSuccess, onError);
              function onSuccess(data) {
                  vm.autreActivites = [];
                  angular.forEach(data, function(value, key){
                      if(vm.campagnes[0] !== null){
                          if(value.campagne.id === vm.campagnes[0].id && value.cci.id === vm.cci.id){
                              vm.autreActivites.push(value);
                          }
                      }
                  });
                  vm.colspanValue = vm.colspanValue + vm.autreActivites.length;
                  //Récupération des données des inputs des tableaux
                  var listeData = vm.formulaire.data.sort(function(a,b) {return a.colonne - b.colonne ;});
                  var listeDataTableau1 = [] ;
                  var listeDataTableau2 = [];
                  angular.forEach(listeData, function(v,k){
                       if(v.tableau === 1){
                           listeDataTableau1.push(v);
                       }else if(v.tableau === 2){
                           listeDataTableau2.push(v);
                       }
                  });
                  angular.forEach(listeDataTableau1, function(valueData1,keyData1){
                     if(valueData1.colonne !== null && valueData1.colonne<5 && valueData1.colonne >0){
                         vm.dataByColonneTableau1[valueData1.colonne - 1] = valueData1;
                     }else if(valueData1.autreActivite !== null){
                         var found =false;
                         for(var i=0; i<vm.autreActivites.length; i++){
                             if(vm.autreActivites[i].id === valueData1.autreActivite.id && !found && vm.dataByColonneTableau1[i+4]!=={}){
                                 vm.dataByColonneTableau1[i+4] = valueData1;
                                 found = true;
                             }
                         }
                     }
                  });
                  angular.forEach(listeDataTableau2, function(valueData2,keyData2){
                      if(valueData2.colonne !== null && valueData2.colonne<5 && valueData2.colonne >0){
                          vm.dataByColonneTableau2[valueData2.colonne - 1] = valueData2;
                      }else if(valueData2.autreActivite !== null){
                          var found =false;
                          for(var i=0; i<vm.autreActivites.length; i++){
                              if(vm.autreActivites[i].id === valueData2.autreActivite.id && !found && vm.dataByColonneTableau2[i+4]!=={}){
                                  vm.dataByColonneTableau2[i+4] = valueData2;
                                  found = true;
                              }
                          }
                      }
                  });
                 loadOldCampagnesFormulairesByCCI();
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

         function printFormulaire(){
             window.print();
         }

         function onSuccessSauvegarder(data) {
             vm.saveMessage=true;
             loadFormulaire();
             window.scrollTo(0, 0);
         }
         function onErrorSauvegarder(error) {
             AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             vm.saveMessage=false;
         }

         function sauvegarder(){
             vm.allOk = true;
             var totalHommeFemmeByColonne = 0;
             var totalCadreNonCadreByColonne = 0;
             for(var i=0; i<vm.autreActivites.length+4; i++){
                 totalHommeFemmeByColonne = (parseInt(vm.dataByColonneTableau2[i].effectifHomme)|| 0) + (parseInt(vm.dataByColonneTableau2[i].effectifFemme)|| 0);
                 totalCadreNonCadreByColonne = (parseInt(vm.dataByColonneTableau2[i].effectifCadre)|| 0) + (parseInt(vm.dataByColonneTableau2[i].effectifNonCadre)|| 0);
                 if(vm.allOk){
                     vm.allOk = (totalHommeFemmeByColonne === totalCadreNonCadreByColonne) ? true : false;
                 }
             }
             if(vm.allOk){
                 var warning = 'Attention, tous les champs laissés à vide sont considérés comme étant à 0';
                if(!vm.formulaire.complet){
                    if (window.confirm(warning)) {
                        Formulaire.update(vm.formulaire, onSuccessSauvegarder, onErrorSauvegarder);
                    }
                } else {
                    Formulaire.update(vm.formulaire, onSuccessSauvegarder, onErrorSauvegarder);
                }
             }else{
                 alert("La répartition des effectifs formés aux risques psycho-sociaux en distribution Homme/Femme n’est pas cohérente avec la répartition en distribution Cadre/Non cadre.");
             }
         }

         function loadOldCampagnesFormulairesByCCI(){
             var idCampagne1 = -1;
             var idCampagne2 = -1;
             if(vm.campagnes.length>2){
                 idCampagne2 = vm.campagnes[2].id;
             }
             if(vm.campagnes.length>1){
                 idCampagne1 = vm.campagnes[1].id;
             }
             Formulaire.getOldCampagnesFormulairesByCCI({idCCI: vm.cci.id, idCampagne1: idCampagne1, idCampagne2:idCampagne2, type: 'formations'}, onSuccess,onError);
             function onSuccess(data) {
                 vm.oldCampagnesFormulaires = data;
                 if(data!== null){
                     if(data.length>1){
                         vm.totalHommeCampagneFermee2 =0;
                         vm.totalCadreCampagneFermee2 =0;
                         vm.totalFemmeCampagneFermee2 =0;
                         vm.totalNonCadreCampagneFermee2 =0;
                         vm.totalCampagneFermee2 =0;
                         vm.totalCampagneFermee2Bis = 0;
                         vm.totalHomme_71_CampagneFermee2 =0;
                         vm.total_71_CampagneFermee2 =0;
                         vm.totalFemme_71_CampagneFermee2 =0;
                          var listeData = vm.oldCampagnesFormulaires[1].data.sort(function(a,b) {return a.colonne - b.colonne ;});
                          var listeDataTableau1 = [] ;
                          var listeDataTableau2 = [];
                           angular.forEach(listeData, function(v,k){
                               if(v.tableau === 1){
                                   listeDataTableau1.push(v);
                               }else if(v.tableau ===2){
                                   listeDataTableau2.push(v);
                               }
                           });
                          angular.forEach(listeDataTableau2, function(valueData2,keyData2){
                                if(valueData2.colonne !== null && valueData2.colonne<5 && valueData2.colonne >0){
                                  vm.dataCF2ByColonneTableau2[valueData2.colonne - 1] = valueData2;
                                } else if(valueData2.autreActivite !== null){
                                    //traitement des autres activités
                                    var found =false;
                                    for(var i=0; i<vm.autreActivites.length; i++){
                                        if(vm.autreActivites[i].nom === valueData2.autreActivite.nom && !found && vm.dataCF2ByColonneTableau2[i+4]!=={}){
                                            vm.dataCF2ByColonneTableau2[i+4] = valueData2;
                                            found = true;
                                        }
                                    }
                                }
                              vm.totalHommeCampagneFermee2 += valueData2.effectifHomme;
                              vm.totalCadreCampagneFermee2 += valueData2.effectifCadre;
                              vm.totalFemmeCampagneFermee2 += valueData2.effectifFemme;
                              vm.totalNonCadreCampagneFermee2 += valueData2.effectifNonCadre;
                              vm.totalCampagneFermee2 += valueData2.effectifHomme + valueData2.effectifFemme;
                              vm.totalCampagneFermee2Bis += valueData2.effectifCadre + valueData2.effectifNonCadre;
                         });
                         angular.forEach(listeDataTableau1, function(valueData1,keyData){
                                if(valueData1.colonne !== null && valueData1.colonne<5 && valueData1.colonne >0){
                                  vm.dataCF2ByColonneTableau1[valueData1.colonne - 1] = valueData1;
                                } else if(valueData1.autreActivite !== null){
                                    //traitement des autres activités
                                    var found =false;
                                    for(var i=0; i<vm.autreActivites.length; i++){
                                        if(vm.autreActivites[i].nom === valueData1.autreActivite.nom && !found && vm.dataCF2ByColonneTableau1[i+4]!=={}){
                                            vm.dataCF2ByColonneTableau1[i+4] = valueData1;
                                            found = true;
                                        }
                                    }
                                }
                              vm.totalHomme_71_CampagneFermee2 +=valueData1.effectifHomme;
                              vm.totalFemme_71_CampagneFermee2 +=valueData1.effectifFemme;
                              vm.total_71_CampagneFermee2 +=valueData1.effectifHomme + valueData1.effectifFemme;
                         });
                     }
                     if(data.length>0){
                         vm.totalFemmeCampagneFermee1 =0;
                         vm.totalNonCadreCampagneFermee1 =0;
                         vm.totalHommeCampagneFermee1 =0;
                         vm.totalCadreCampagneFermee1 =0;
                         vm.totalCampagneFermee1 =0;
                         vm.totalCampagneFermee1Bis = 0;
                         vm.totalFemme_71_CampagneFermee1 =0;
                         vm.total_71_CampagneFermee1 =0;
                         vm.totalHomme_71_CampagneFermee1 =0;
                          var listeData = vm.oldCampagnesFormulaires[0].data.sort(function(a,b) {return a.colonne - b.colonne ;});
                          var listeDataTableau1 = [] ;
                          var listeDataTableau2 = [];
                           angular.forEach(listeData, function(v,k){
                               if(v.tableau === 1){
                                   listeDataTableau1.push(v);
                               }else if(v.tableau ===2){
                                   listeDataTableau2.push(v);
                               }
                           });
                          angular.forEach(listeDataTableau2, function(valueData2,keyData){
                                if(valueData2.colonne !== null && valueData2.colonne<5 && valueData2.colonne >0){
                                    vm.dataCF1ByColonneTableau2[valueData2.colonne - 1] = valueData2;
                                } else if(valueData2.autreActivite !== null){
                                    //traitement des autres activités
                                    var found =false;
                                    for(var i=0; i<vm.autreActivites.length; i++){
                                        if(vm.autreActivites[i].nom === valueData2.autreActivite.nom && !found && vm.dataCF1ByColonneTableau2[i+4]!=={}){
                                            vm.dataCF1ByColonneTableau2[i+4] = valueData2;
                                            found = true;
                                        }
                                    }
                                }
                              vm.totalHommeCampagneFermee1 += valueData2.effectifHomme;
                              vm.totalCadreCampagneFermee1 += valueData2.effectifCadre;
                              vm.totalFemmeCampagneFermee1 += valueData2.effectifFemme;
                              vm.totalNonCadreCampagneFermee1 += valueData2.effectifNonCadre;
                              vm.totalCampagneFermee1 += valueData2.effectifHomme + valueData2.effectifFemme;
                              vm.totalCampagneFermee1Bis += valueData2.effectifCadre + valueData2.effectifNonCadre;
                          });
                          angular.forEach(listeDataTableau1, function(valueData1,keyData){
                                if(valueData1.colonne !== null && valueData1.colonne<5 && valueData1.colonne >0){
                                  vm.dataCF1ByColonneTableau1[valueData1.colonne - 1] = valueData1;
                                } else if(valueData1.autreActivite !== null){
                                    //traitement des autres activités
                                    var found =false;
                                    for(var i=0; i<vm.autreActivites.length; i++){
                                        if(vm.autreActivites[i].nom === valueData1.autreActivite.nom && !found && vm.dataCF1ByColonneTableau1[i+4]!=={}){
                                            vm.dataCF1ByColonneTableau1[i+4] = valueData1;
                                            found = true;
                                        }
                                    }
                                }
                              vm.totalHomme_71_CampagneFermee1 += valueData1.effectifHomme;
                              vm.totalFemme_71_CampagneFermee1 += valueData1.effectifFemme;
                              vm.total_71_CampagneFermee1 += valueData1.effectifHomme + valueData1.effectifFemme;
                          });
                     }
                  }
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

         function showHelp1(){
             if(!vm.helpMessage1){
                 vm.helpMessage1=true;
             } else {
                 vm.helpMessage1=false;
             }
         }

         function showHelp2(){
              if(!vm.helpMessage2){
                  vm.helpMessage2=true;
              } else {
                  vm.helpMessage2=false;
              }
          }

         function confirmerRetour(){
            getAccount();
            var message = 'Les informations non sauvegardées seront perdues.';
             if (window.confirm(message)) {
                 if(vm.isAdmin) {
                     $state.go('list-formulaires-cci', {id:vm.cci.id,idCampagne:vm.campagneSelected.id});
                 }else if(Principal.hasAuthority("ROLE_CCIR") && vm.cci.id !== vm.account.cci.id) {  //si la cci du formulaire en question est differente de celle du user connecté
                     $state.go('list-formulaires-cci', {id:vm.cci.id,idCampagne:vm.campagneSelected.id});
                 }else if(Principal.hasAuthority("ROLE_CCIR") && vm.cci.id === vm.account.cci.id) {
                     $state.go('mon-bilan-formulaire');
                 }else {
                     $state.go('mon-bilan-formulaire');
                 }
             }
         }
     }
 })();
