(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('DataDialogController', DataDialogController);

    DataDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Data', 'Formulaire', 'AutreActivite'];

    function DataDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Data, Formulaire, AutreActivite) {
        var vm = this;

        vm.data = entity;
        vm.clear = clear;
        vm.save = save;
        vm.formulaires = Formulaire.query();
        vm.autreactivites = AutreActivite.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.data.id !== null) {
                Data.update(vm.data, onSaveSuccess, onSaveError);
            } else {
                Data.save(vm.data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bhsApp:dataUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
