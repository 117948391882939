(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('AutreActiviteDetailController', AutreActiviteDetailController);

    AutreActiviteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AutreActivite', 'Data', 'DataElement', 'CCI', 'Campagne'];

    function AutreActiviteDetailController($scope, $rootScope, $stateParams, previousState, entity, AutreActivite, Data, DataElement, CCI, Campagne) {
        var vm = this;

        vm.autreActivite = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bhsApp:autreActiviteUpdate', function(event, result) {
            vm.autreActivite = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
