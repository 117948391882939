(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('CCIDialogController', CCIDialogController);

    CCIDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'CCI', 'Formulaire', 'AutreActivite', 'Region'];

    function CCIDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, CCI, Formulaire, AutreActivite, Region) {
        var vm = this;

        vm.cCI = entity;
        vm.clear = clear;
        vm.save = save;
        vm.update_statut = update_statut;
        vm.ccirattachements = CCI.query({filter: 'cci-is-null'});
        $q.all([vm.cCI.$promise, vm.ccirattachements.$promise]).then(function() {
            if (!vm.cCI.cciRattachement || !vm.cCI.cciRattachement.id) {
                return $q.reject();
            }
            return CCI.get({id : vm.cCI.cciRattachement.id}).$promise;
        }).then(function(cciRattachement) {
            vm.ccirattachements.push(cciRattachement);
        });
        vm.formulaires = Formulaire.query();
        vm.autreactivites = AutreActivite.query();
        vm.regions = Region.query();
        vm.isActive = vm.cCI.actif ;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
                CCI.save(vm.cCI, onSaveSuccess, onSaveError);
        }

        function update_statut(){
             vm.cCI.actif = ! vm.cCI.actif ;
             CCI.update(vm.cCI, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('bhsApp:cCIUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.isActive = result.active ;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
