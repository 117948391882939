(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', '$uibModalInstance', 'entity', '$stateParams', 'User'];

    function SettingsController (Principal, Auth, JhiLanguageService, $translate, $uibModalInstance, entity, $stateParams, User) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.success = null;
        vm.user = {};
        vm.showHelp = showHelp;
        vm.clear = clear;
        vm.helpMdp = false;
        vm.doNotMatch = null;
        vm.regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/;
        vm.confirmPassword = null;
        vm.newPassword = null;
        vm.authorities =    [{libelle : 'Administrateur', role : 'ROLE_ADMIN'},
                            {libelle : 'CCIR', role : 'ROLE_CCIR'},
                            {libelle : 'Contributeur', role : 'ROLE_USER'}];
        vm.authority= null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        Principal.identity().then(function(account) {
             vm.user = account;
                //mise à jour du rôle du user
             if(vm.user.authorities != null){
                angular.forEach(vm.authorities, function(value, key) {
                   if(value.role === vm.user.authorities[0]){
                       vm.authority = value;
                   }
                });
             }
        });
        function save () {
            vm.doNotMatch = null;
            vm.helpMdp=false;
            vm.error = null;
            if (vm.newPassword !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                if (vm.user.authorities[0] === 'ROLE_ADMIN'){
                    vm.user.cci = null;
                }
                vm.user.password = vm.newPassword;
                User.update(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.success = 'OK';
            vm.helpMdp=false;
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.success = null;
            vm.error = 'ERROR';
            vm.helpMdp=false;
        }

        function showHelp () {
            vm.success = null;
            vm.error = null;
            vm.doNotMatch = null;
            if(!vm.helpMdp){
                vm.helpMdp=true;
            } else {
                vm.helpMdp=false;
            }

        }
    }
})();
