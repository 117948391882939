(function () {
    'use strict';
    angular
        .module('bhsApp')
        .factory('BilanNational', BilanNational);

    BilanNational.$inject = ['$resource', 'DateUtils'];

    function BilanNational($resource, DateUtils) {
        var resourceUrl = 'api/bilan-nationals/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getAllBilans': {
                url: 'api/bilan-nationals',
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.datePublication = DateUtils.convertDateTimeFromServer(data.datePublication);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'updateData': {
                method: 'POST',
                url: 'api/bilan-nationals/update/:idCampagne',
                headers: {'Content-Type': undefined},
                transformRequest: function (data) {
                    var formData = new FormData();
                    formData.append("file", data);
                    return formData;
                }
            },
             'getBilanNationalFile': {
                 url: 'api/bilan-nationals/getBilanNationalFile',
                 method: 'POST',
                 cache: false,
                 responseType: 'arraybuffer',
                 transformResponse: function (data, headers) {
                     var zip = null;
                     if (data) {
                         zip = new Blob([data], {
                             type: '/vnd.ms-excel'
                         });
                     }
                     var fileName = getFileNameFromHeader(headers('content-disposition'));
                     var result = {
                         blob: zip,
                         fileName: fileName
                     };
                     return {
                         response: result
                     };
                 }
             }
        });
    }

    function getFileNameFromHeader(header) {
        if (!header) return null;
        var result = header.split(";")[1].trim().split("=")[1];
        return result.replace(/"/g, '');
    }
})();
