(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('CCI', CCI);

    CCI.$inject = ['$resource'];

    function CCI ($resource) {
        var resourceUrl =  'api/c-cis';

        return $resource(resourceUrl, {}, {
            'query': {
                url: 'api/c-cis/:id',
                method: 'GET',
                isArray: true
            },
            'get': {
                url: 'api/c-cis/:id',
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                url: 'api/c-cis/:id',
                method:'PUT'
            },
            'sort': {
                url: 'api/ccis/sorting',
                method:'PUT'
            },
            'getSort': {
                url: 'api/ccis/sorting',
                method:'GET'
            },
            'getAllCCIS': {
                url: 'api/c-cis/all',
                method:'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
