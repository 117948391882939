(function() {
    'use strict';
    angular
        .module('bhsApp')
        .controller('ValidNumberCtrl', ValidNumberCtrl);
         ValidNumberCtrl.$inject = ['$scope'];

    function ValidNumberCtrl($scope) {
        var vm = this;
        vm.positiveNumber = 1;
        vm.negativeNumber = 2;
        vm.decimal = 3;
        vm.decimalUpto = 4;
        vm.negativedecimal = 0;
        vm.negativedecimalUpto = 0;
        vm.total = 0;

    }
})();
