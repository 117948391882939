(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('TableauNombreJourneePerduAccidentsCtrl', TableauNombreJourneePerduAccidentsCtrl);

    TableauNombreJourneePerduAccidentsCtrl.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function TableauNombreJourneePerduAccidentsCtrl ($scope, $state, ParseLinks, AlertService) {


    }
})();
