(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireAccidentTrajetController', FormulaireAccidentTrajetController);

    FormulaireAccidentTrajetController.$inject = ['$scope', '$state', 'Formulaire', 'AlertService', 'AutreActivite', 'ParamFormulaire', 'Campagne', 'entityCCI', 'Principal', 'entityCampagne', '$stateParams', 'Element'];

    function FormulaireAccidentTrajetController($scope, $state, Formulaire, AlertService, AutreActivite, ParamFormulaire, Campagne, entityCCI, Principal, entityCampagne, $stateParams, Element) {
        var vm = this;

        //Init functions
        vm.printFormulaire = printFormulaire;
        vm.sauvegarder = sauvegarder;
        vm.showHelp1 = showHelp1;
        vm.showHelp2 = showHelp2;
        vm.showHelp3 = showHelp3;
        vm.showHelp4 = showHelp4;
        vm.showHelp5 = showHelp5;
        vm.confirmerRetour = confirmerRetour;
        vm.ajouterTypeAccident = ajouterTypeAccident;
        vm.displayPlus = displayPlus;
        vm.displayMinus = displayMinus;
        vm.hideMinus = hideMinus;
        vm.hidePlus = hidePlus;
        vm.supprimerTypeAccident = supprimerTypeAccident;
        vm.calculerTotauxByColonne = calculerTotauxByColonne;  //Calcul du total de chaque colonne pour l'ensemble des types d'accident
        vm.getOldvalueByElementId = getOldvalueByElementId;
        vm.getOldvalueByElementIdBis = getOldvalueByElementIdBis;  //Pour la 2eme campagne fermée
        vm.getOldvalueOrNullByElementId = getOldvalueOrNullByElementId;
        vm.getOldvalueOrNullByElementIdBis = getOldvalueOrNullByElementIdBis;
        vm.getOldvaluesTotalByElementId = getOldvaluesTotalByElementId;
        vm.getOldvaluesTotalByElementIdBis = getOldvaluesTotalByElementIdBis;
        vm.loadFormulaire = loadFormulaire;

        // Init variables
        vm.totalCF1 = 0;
        vm.totalCF1Colonne1 = 0;
        vm.totalCF1Colonne2 = 0;
        vm.totalCF1Colonne3 = 0;
        vm.totalCF1Colonne4 = 0;
        vm.totalCF1Colonne5 = 0;
        vm.totalCF1Colonne6 = 0;
        vm.totalCF1Colonne7 = 0;
        vm.totalCF1Colonne8 = 0;
        vm.totalCF1Colonne9 = 0;
        vm.totalCF2 = 0;
        vm.totalCF2Colonne1 = 0;
        vm.totalCF2Colonne2 = 0;
        vm.totalCF2Colonne3 = 0;
        vm.totalCF2Colonne4 = 0;
        vm.totalCF2Colonne5 = 0;
        vm.totalCF2Colonne6 = 0;
        vm.totalCF2Colonne7 = 0;
        vm.totalCF2Colonne8 = 0;
        vm.totalCF2Colonne9 = 0;
        vm.totalColonne = [{ 1: 0 }, { 2: 0 }, { 3: 0 }, { 4: 0 }, { 5: 0 }, { 6: 0 }, { 7: 0 }, { 8: 0 }, { 9: 0 }];
        vm.totalColonne1 = 0;
        vm.totalColonne2 = 0;
        vm.totalColonne3 = 0;
        vm.totalColonne4 = 0;
        vm.totalColonne5 = 0;
        vm.totalColonne6 = 0;
        vm.totalColonne7 = 0;
        vm.totalColonne8 = 0;
        vm.totalColonne9 = 0;
        vm.allOk = true;
        vm.typeAccidentAffichageTitre = [];
        vm.typeAccidentSelectionne = null;
        vm.typeAccidentForSelect = [];  //pour remplir le select types accidents
        vm.typeAccidentSelected = {};
        vm.allTypeAccident = [];
        vm.campagneArchivee = {};
        vm.campagnes = entityCampagne;  //Les campagnes archivées + celle sélectionnée ( ou en cours)
        vm.campagneSelected = null;  // La campagne sélectionnée
        vm.idCampagneSelected = $stateParams.idCampagneSelected;
        vm.autreActivites = [];
        vm.paramFormulaires = [];
        vm.paramTableaux = [];
        vm.formulaire = null;
        vm.cci = entityCCI;
        vm.colspanValue = 6;  //les 4 colonnes fixes + celles du nom de la campagne et du total
        vm.dataByColonneTableau1 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataByColonneTableau2 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataByColonneTableau3 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataByColonneTableau4 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataByColonneTableau5 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF1ByColonneTableau1 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF2ByColonneTableau1 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF1ByColonneTableau2 = [];
        vm.dataCF2ByColonneTableau2 = [];
        vm.dataCF1ByColonneTableau3 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF2ByColonneTableau3 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF1ByColonneTableau4 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF2ByColonneTableau4 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF1ByColonneTableau5 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF2ByColonneTableau5 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.listeDataElementTableau2CF1 = [];
        vm.listeDataElementTableau2CF2 = [];
        vm.helpMessage1 = false;
        vm.helpMessage2 = false;
        vm.helpMessage3 = false;
        vm.helpMessage4 = false;
        vm.helpMessage5 = false;
        vm.saveMessage = false;
        vm.campagnes = [];
        vm.oldCampagnesFormulaires = [];
        vm.isAdmin = false;
        vm.account = null;

        vm.campagneFermee1 = false;
        vm.campagneFermee2 = false;
        vm.campagneFermee1Bis = false;
        vm.campagneFermee2Bis = false;
        vm.campagneFermee1Ter = false;
        vm.campagneFermee2Ter = false;
        vm.campagneFermee1Quarter = false;
        vm.campagneFermee2Quarter = false;
        vm.campagneFermee1Quinquies = false;
        vm.campagneFermee2Quinquies = false;

        vm.totalNbAccAvecArretCampagneFermee2 = 0;
        vm.totalNbAccAvecArretCampagneFermee1 = 0;
        vm.totalNbAccSansArretCampagneFermee2 = 0;
        vm.totalNbAccSansArretCampagneFermee1 = 0;

        //Totaux tableau 2
        vm.totalItemTypeAccCampagneFermee2 = 0;
        vm.totalItemTypeAccCampagneFermee1 = 0;

        //tab 3
        vm.totalNbJourneePerduCampagneFermee2 = 0;
        vm.totalNbJourneePerduCampagneFermee1 = 0;

        vm.totalHommeCampagneFermee2Tableau4 = 0;
        vm.totalHommeCampagneFermee2Tableau5 = 0;
        vm.totalHommeCampagneFermee1Tableau4 = 0;
        vm.totalHommeCampagneFermee1Tableau5 = 0;

        vm.totalCadreCampagneFermee2Tableau4 = 0;
        vm.totalCadreCampagneFermee2Tableau5 = 0;
        vm.totalCadreCampagneFermee1Tableau4 = 0;
        vm.totalCadreCampagneFermee1Tableau5 = 0;

        vm.totalFemmeCampagneFermee2Tableau4 = 0;
        vm.totalFemmeCampagneFermee2Tableau5 = 0;
        vm.totalFemmeCampagneFermee1Tableau4 = 0;
        vm.totalFemmeCampagneFermee1Tableau5 = 0;

        vm.totalNonCadreCampagneFermee2Tableau4 = 0;
        vm.totalNonCadreCampagneFermee2Tableau5 = 0;
        vm.totalNonCadreCampagneFermee1Tableau4 = 0;
        vm.totalNonCadreCampagneFermee1Tableau5 = 0;

        vm.totalCampagneFermee2 = 0;
        vm.totalCampagneFermee1 = 0;
        vm.totalCampagneFermee2Bis = 0;
        vm.totalCampagneFermee1Bis = 0;

        //Call functions when page load
        loadFormulaire();
        loadAllParamFormulaires();
        getAccount();

        function loadFormulaire() {
            Formulaire.getFormulairesWithDatas({ id: $stateParams.id }, onSuccess, onError);
            function onSuccess(data) {
                vm.formulaire = data;
                loadAllElements();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

        function getCampagnes() {
            Campagne.getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires({ idCampagneSelected: vm.idCampagneSelected }, onSuccess, onError);
            function onSuccess(data) {
                vm.campagneSelected = data[0];
                vm.campagnes = data;
                loadAllAutreActivites();

            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllParamFormulaires() {
            ParamFormulaire.loadAll({}, onSuccess, onError);
            function onSuccess(data) {
                vm.paramFormulaires = data;
                if (vm.paramFormulaires[2].descriptif) {
                    vm.paramFormulaires[2].descriptif = vm.paramFormulaires[2].descriptif.replace('![CDATA[', '').replace(']]', '');
                }
                vm.paramTableaux = vm.paramFormulaires[2].paramTableaus.sort(function (a, b) { return a.ordre - b.ordre; });
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace(']]', '');
                vm.paramTableaux[1].descriptif = vm.paramTableaux[1].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[1].descriptif = vm.paramTableaux[1].descriptif.replace(']]', '');
                vm.paramTableaux[2].descriptif = vm.paramTableaux[2].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[2].descriptif = vm.paramTableaux[2].descriptif.replace(']]', '');
                vm.paramTableaux[3].descriptif = vm.paramTableaux[3].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[3].descriptif = vm.paramTableaux[3].descriptif.replace(']]', '');
                vm.paramTableaux[4].descriptif = vm.paramTableaux[4].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[4].descriptif = vm.paramTableaux[4].descriptif.replace(']]', '');
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllAutreActivites() {
            AutreActivite.getAutreActivitesByCciAndCampagne({ idCCI: vm.cci.id, idCampagne: vm.campagneSelected.id }, onSuccess, onError);
            function onSuccess(data) {
                vm.autreActivites = [];
                angular.forEach(data, function (value, key) {
                    if (vm.campagnes[0] !== null) {
                        if (value.campagne.id === vm.campagnes[0].id && value.cci.id === vm.cci.id) {
                            vm.autreActivites.push(value);
                        }
                    }
                });
                vm.colspanValue = vm.colspanValue + vm.autreActivites.length;
                //Récupération des données des inputs des tableaux
                var listeData = vm.formulaire.data.sort(function (a, b) { return a.colonne - b.colonne; });
                var listeDataElementTableau2 = vm.formulaire.dataElements.sort(function (a, b) { return a.colonne - b.colonne; });  //listeDataElement
                var listeDataTableau1 = [];
                var listeDataTableau3 = [];
                var listeDataTableau4 = [];
                var listeDataTableau5 = [];
                //Remplissage des datas des differents tableaux
                angular.forEach(listeData, function (v, k) {
                    switch (v.tableau) {
                        case 1:
                            listeDataTableau1.push(v);
                            break;
                        case 3:
                            listeDataTableau3.push(v);
                            break;
                        case 4:
                            listeDataTableau4.push(v);
                            break;
                        case 5:
                            listeDataTableau5.push(v);
                            break;
                        default:
                            break;
                    }
                });
                //Hydratation des datas des inputs du tableau 1
                angular.forEach(listeDataTableau1, function (valueData1, keyData1) {
                    if (valueData1.colonne !== null && valueData1.colonne < 5 && valueData1.colonne > 0) {
                        vm.dataByColonneTableau1[valueData1.colonne - 1] = valueData1;
                    } else if (valueData1.autreActivite !== null) {
                        var found = false;
                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData1.autreActivite.id && !found && vm.dataByColonneTableau1[i + 4] !== {}) {
                                vm.dataByColonneTableau1[i + 4] = valueData1;
                                found = true;
                            }
                        }
                    }
                });
                //Hydratation des dataElements des inputs du tableau dynamique 2
                angular.forEach(listeDataElementTableau2, function (valueDataElement, keyDataElement) {
                    if (valueDataElement.colonne !== null && valueDataElement.colonne < 5 && valueDataElement.colonne > 0) {
                        angular.forEach(vm.allTypeAccident, function (valueTypeAccident, keyTypeAccident) {
                            if (valueDataElement.element.id === valueTypeAccident[10]) {
                                valueTypeAccident[valueDataElement.colonne] = valueDataElement;
                            }
                        });
                    } else if (valueDataElement.autreActivite !== null) {
                        angular.forEach(vm.allTypeAccident, function (valueTypeAccident, keyTypeAccident) {
                            for (var i = 0; i < vm.autreActivites.length; i++) {
                                var found = false;
                                if (vm.autreActivites[i].id === valueDataElement.autreActivite.id && !found && valueTypeAccident[i + 5] !== {} && valueDataElement.element.id === valueTypeAccident[10]) {
                                    valueTypeAccident[i + 5] = valueDataElement;
                                    found = true;
                                }
                            }
                        });
                    }
                });
                // Dispatch entre vm.typeAccidentForSelect et vm.typeAccidentSelected
                angular.forEach(vm.allTypeAccident, function (valueTypeAccident, keyTypeAccident) {
                    var valeurRenseigne = false;
                    for (var i = 0; i < 9; i++) {
                        if (valueTypeAccident[i + 1].valeur > 0) {
                            valeurRenseigne = true;
                        }
                    }
                    if (!valeurRenseigne) {
                        vm.typeAccidentForSelect.push(valueTypeAccident);
                    } else if (valeurRenseigne) {
                        vm.typeAccidentSelected[valueTypeAccident[10]] = valueTypeAccident;
                    }

                });
                vm.typeAccidentAffichageTitre = vm.allTypeAccident[0];
                calculerTotauxByColonne();

                angular.forEach(listeDataTableau3, function (valueData3, keyData3) {
                    if (valueData3.colonne !== null && valueData3.colonne < 5 && valueData3.colonne > 0) {
                        vm.dataByColonneTableau3[valueData3.colonne - 1] = valueData3;
                    } else if (valueData3.autreActivite !== null) {
                        var found = false;
                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData3.autreActivite.id && !found && vm.dataByColonneTableau3[i + 4] !== {}) {
                                vm.dataByColonneTableau3[i + 4] = valueData3;
                                found = true;
                            }
                        }
                    }
                });
                angular.forEach(listeDataTableau4, function (valueData4, keyData4) {
                    if (valueData4.colonne !== null && valueData4.colonne < 5 && valueData4.colonne > 0) {
                        vm.dataByColonneTableau4[valueData4.colonne - 1] = valueData4;
                    } else if (valueData4.autreActivite !== null) {
                        var found = false;
                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData4.autreActivite.id && !found && vm.dataByColonneTableau4[i + 4] !== {}) {
                                vm.dataByColonneTableau4[i + 4] = valueData4;
                                found = true;
                            }
                        }
                    }
                });
                angular.forEach(listeDataTableau5, function (valueData5, keyData5) {
                    if (valueData5.colonne !== null && valueData5.colonne < 5 && valueData5.colonne > 0) {
                        vm.dataByColonneTableau5[valueData5.colonne - 1] = valueData5;
                    } else if (valueData5.autreActivite !== null) {
                        var found = false;
                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData5.autreActivite.id && !found && vm.dataByColonneTableau5[i + 4] !== {}) {
                                vm.dataByColonneTableau5[i + 4] = valueData5;
                                found = true;
                            }
                        }
                    }
                });
                loadOldCampagnesFormulairesByCCI();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function printFormulaire() {
            window.print();
        }

        function sauvegarder() {
            vm.allOk = true;
            var message1 = "";
            var message2 = "";
            var message3 = "";
            var totalHommeFemmeByColonneTableau4 = 0;
            var totalHommeFemmeByColonneTableau5 = 0;
            var totalCadreNonCadreByColonneTableau4 = 0;
            var totalCadreNonCadreByColonneTableau5 = 0;
            var totalNombreAccident = 0;
            for (var i = 0; i < vm.autreActivites.length + 4; i++) {
                // mise à jour des valeurs
                totalHommeFemmeByColonneTableau4 = (parseInt(vm.dataByColonneTableau4[i].effectifHomme) || 0) + (parseInt(vm.dataByColonneTableau4[i].effectifFemme) || 0);
                totalHommeFemmeByColonneTableau5 = (parseInt(vm.dataByColonneTableau5[i].effectifHomme) || 0) + (parseInt(vm.dataByColonneTableau5[i].effectifFemme) || 0);
                totalCadreNonCadreByColonneTableau4 = (parseInt(vm.dataByColonneTableau4[i].effectifCadre) || 0) + (parseInt(vm.dataByColonneTableau4[i].effectifNonCadre) || 0);
                totalCadreNonCadreByColonneTableau5 = (parseInt(vm.dataByColonneTableau5[i].effectifCadre) || 0) + (parseInt(vm.dataByColonneTableau5[i].effectifNonCadre) || 0);
                vm.dataByColonneTableau1[i].nbAccidentsAvecArret = totalHommeFemmeByColonneTableau4;
                vm.dataByColonneTableau1[i].nbAccidentsSansArret = totalHommeFemmeByColonneTableau5;
                totalNombreAccident = (parseInt(vm.dataByColonneTableau1[i].nbAccidentsSansArret) || 0) + (parseInt(vm.dataByColonneTableau1[i].nbAccidentsAvecArret) || 0);
                // lancer les vérifications
                if (message1 === "" && totalHommeFemmeByColonneTableau4 !== totalCadreNonCadreByColonneTableau4) {
                    vm.allOk = false;
                    message1 = "La répartition des accidents de trajet avec arrêt en distribution Homme/Femme n’est pas cohérente avec la répartition en distribution Cadre/Non cadre.";
                }
                if (message2 === "" && totalHommeFemmeByColonneTableau5 !== totalCadreNonCadreByColonneTableau5) {
                    vm.allOk = false;
                    message2 = "La répartition des accidents de trajet sans arrêt en distribution Homme/Femme n’est pas cohérente avec la répartition en distribution Cadre/Non cadre.";
                }
                if (message3 === "") {
                    switch (i) {
                        case 0:
                            if (vm.totalColonne1 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                        case 1:
                            if (vm.totalColonne2 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                        case 2:
                            if (vm.totalColonne3 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                        case 3:
                            if (vm.totalColonne4 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                        case 4:
                            if (vm.totalColonne5 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                        case 5:
                            if (vm.totalColonne6 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                        case 6:
                            if (vm.totalColonne7 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;

                        case 7:
                            if (vm.totalColonne8 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;

                        case 8:
                            if (vm.totalColonne9 !== totalNombreAccident) {
                                vm.allOk = false;
                                message3 = "La répartition des types d’accidents n’est pas cohérente avec le nombre total d’accidents de trajet renseigné dans le dernier tableau.";
                            }
                            break;
                    }
                }
            }
            if (vm.allOk) {
                var warning = 'Attention, tous les champs laissés à vide sont considérés comme étant à 0';
                if (!vm.formulaire.complet) {
                    if (window.confirm(warning)) {
                        Formulaire.update(vm.formulaire, onSuccessSauvegarder, onErrorSauvegarder);
                    }
                } else {
                    Formulaire.update(vm.formulaire, onSuccessSauvegarder, onErrorSauvegarder);
                }
            } else {
                var message = "";
                if (message1 !== "") {
                    message = message1;
                }
                if (message2 !== "") {
                    if (message !== "") {
                        message += "\n\n";
                    }
                    message += message2;
                }
                if (message3 !== "") {
                    if (message !== "") {
                        message += "\n\n";
                    }
                    message += message3;
                }
                alert(message);
            }
        }

        function onSuccessSauvegarder(data) {
            //scroll to top
            window.scrollTo(0, 0);
            //display success message
            vm.saveMessage = true;
            //reset variables
            vm.totalColonne = [{ 1: 0 }, { 2: 0 }, { 3: 0 }, { 4: 0 }, { 5: 0 }, { 6: 0 }, { 7: 0 }, { 8: 0 }, { 9: 0 }];
            vm.typeAccidentAffichageTitre = [];
            vm.typeAccidentSelectionne = null;
            vm.typeAccidentForSelect = [];  //pour remplir le select types accidents
            vm.typeAccidentSelected = {};
            vm.allTypeAccident = [];
            vm.campagneArchivee = {};
            vm.autreActivites = [];
            vm.paramFormulaires = [];
            vm.paramTableaux = [];
            vm.formulaire = null;
            vm.dataByColonneTableau1 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataByColonneTableau2 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataByColonneTableau3 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataByColonneTableau4 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataByColonneTableau5 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF1ByColonneTableau1 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF2ByColonneTableau1 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF1ByColonneTableau2 = [];
            vm.dataCF2ByColonneTableau2 = [];
            vm.dataCF1ByColonneTableau3 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF2ByColonneTableau3 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF1ByColonneTableau4 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF2ByColonneTableau4 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF1ByColonneTableau5 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.dataCF2ByColonneTableau5 = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
            vm.campagnes = [];
            vm.oldCampagnesFormulaires = [];

            //reload datas
            loadFormulaire();
            loadAllParamFormulaires();
        }
        function onErrorSauvegarder(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            vm.saveMessage = false;
        }

        function loadOldCampagnesFormulairesByCCI() {
            var idCampagne1 = -1;
            var idCampagne2 = -1;
            if (vm.campagnes.length > 2) {
                idCampagne2 = vm.campagnes[2].id;
            }
            if (vm.campagnes.length > 1) {
                idCampagne1 = vm.campagnes[1].id;
            }
            Formulaire.getOldCampagnesFormulairesByCCI({ idCCI: vm.cci.id, idCampagne1: idCampagne1, idCampagne2: idCampagne2, type: 'accidentsTrajet' }, onSuccess, onError);
            function onSuccess(data) {
                vm.oldCampagnesFormulaires = data;
                vm.totalNbAccAvecArretCampagneFermee2 = 0;
                vm.totalNbAccAvecArretCampagneFermee1 = 0;
                vm.totalNbAccSansArretCampagneFermee2 = 0;
                vm.totalNbAccSansArretCampagneFermee1 = 0;

                //Totaux tableau 2
                vm.totalItemTypeAccCampagneFermee2 = 0;
                vm.totalItemTypeAccCampagneFermee1 = 0;

                //tab 3
                vm.totalNbJourneePerduCampagneFermee2 = 0;
                vm.totalNbJourneePerduCampagneFermee1 = 0;

                vm.totalHommeCampagneFermee2Tableau4 = 0;
                vm.totalHommeCampagneFermee2Tableau5 = 0;
                vm.totalHommeCampagneFermee1Tableau4 = 0;
                vm.totalHommeCampagneFermee1Tableau5 = 0;

                vm.totalCadreCampagneFermee2Tableau4 = 0;
                vm.totalCadreCampagneFermee2Tableau5 = 0;
                vm.totalCadreCampagneFermee1Tableau4 = 0;
                vm.totalCadreCampagneFermee1Tableau5 = 0;

                vm.totalFemmeCampagneFermee2Tableau4 = 0;
                vm.totalFemmeCampagneFermee2Tableau5 = 0;
                vm.totalFemmeCampagneFermee1Tableau4 = 0;
                vm.totalFemmeCampagneFermee1Tableau5 = 0;

                vm.totalNonCadreCampagneFermee2Tableau4 = 0;
                vm.totalNonCadreCampagneFermee2Tableau5 = 0;
                vm.totalNonCadreCampagneFermee1Tableau4 = 0;
                vm.totalNonCadreCampagneFermee1Tableau5 = 0;

                vm.totalCampagneFermee2 = 0;
                vm.totalCampagneFermee1 = 0;
                vm.totalCampagneFermee2Bis = 0;
                vm.totalCampagneFermee1Bis = 0;
                if (data !== null) {
                    if (data.length > 1) {
                        var listeData = vm.oldCampagnesFormulaires[1].data.sort(function (a, b) { return a.colonne - b.colonne; }); //Tri par colonne
                        vm.listeDataElementTableau2CF2 = vm.oldCampagnesFormulaires[1].dataElements.sort(function (a, b) { return a.colonne - b.colonne; });
                        var listeDataTableau1 = [];
                        var listeDataTableau3 = [];
                        var listeDataTableau4 = [];
                        var listeDataTableau5 = [];
                        vm.totalCF2Colonne1 = 0;
                        vm.totalCF2Colonne2 = 0;
                        vm.totalCF2Colonne3 = 0;
                        vm.totalCF2Colonne4 = 0;
                        vm.totalCF2Colonne5 = 0;
                        vm.totalCF2Colonne6 = 0;
                        vm.totalCF2Colonne7 = 0;
                        vm.totalCF2Colonne8 = 0;
                        vm.totalCF2Colonne9 = 0;
                        angular.forEach(listeData, function (v, k) {
                            switch (v.tableau) {
                                case 1:
                                    listeDataTableau1.push(v);
                                    break;
                                case 3:
                                    listeDataTableau3.push(v);
                                    break;
                                case 4:
                                    listeDataTableau4.push(v);
                                    break;
                                case 5:
                                    listeDataTableau5.push(v);
                                    break;
                                default:
                                    break;
                            }
                        });
                        angular.forEach(listeDataTableau1, function (valueData1, keyData1) {
                            if (valueData1.colonne !== null && valueData1.colonne < 5 && valueData1.colonne > 0) {
                                vm.dataCF2ByColonneTableau1[valueData1.colonne - 1] = valueData1;
                            } else if (valueData1.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData1.autreActivite.nom && !found && vm.dataCF2ByColonneTableau1[i + 4] !== {}) {
                                        vm.dataCF2ByColonneTableau1[i + 4] = valueData1;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalNbAccAvecArretCampagneFermee2 += valueData1.nbAccidentsAvecArret;
                            vm.totalNbAccSansArretCampagneFermee2 += valueData1.nbAccidentsSansArret;
                            vm.totalCampagneFermee2 += valueData1.nbAccidentsAvecArret + valueData1.nbAccidentsSansArret;
                        });
                        angular.forEach(vm.listeDataElementTableau2CF2, function (valueDataElement, keyDataElement) {
                            if (valueDataElement.valeur !== null && valueDataElement.valeur !== undefined && valueDataElement.valeur !== '') {
                                vm.totalCF2 += valueDataElement.valeur;
                            }
                            angular.forEach(vm.dataCF2ByColonneTableau2, function (valueTypeAccident, keyTypeAccident) {
                                if (valueDataElement.element.id === valueTypeAccident[10]) {
                                    if (valueDataElement.colonne !== null && valueDataElement.colonne < 5 && valueDataElement.colonne > 0) {
                                        valueTypeAccident[valueDataElement.colonne] = valueDataElement;
                                        if (valueDataElement.colonne === 1) {
                                            vm.totalCF2Colonne1 += valueDataElement.valeur;
                                        }
                                        if (valueDataElement.colonne === 2) {
                                            vm.totalCF2Colonne2 += valueDataElement.valeur;
                                        }
                                        if (valueDataElement.colonne === 3) {
                                            vm.totalCF2Colonne3 += valueDataElement.valeur;
                                        }
                                        if (valueDataElement.colonne === 4) {
                                            vm.totalCF2Colonne4 += valueDataElement.valeur;
                                        }
                                    } else if (valueDataElement.autreActivite !== null) {
                                        //traitement des autres activités
                                        var found = false;
                                        for (var i = 0; i < vm.autreActivites.length; i++) {
                                            if (vm.autreActivites[i].nom === valueDataElement.autreActivite.nom && !found && valueTypeAccident[i + 5] !== {}) {
                                                valueTypeAccident[i + 5] = valueDataElement;
                                                found = true;
                                                if (i === 0) {
                                                    vm.totalCF2Colonne5 += valueDataElement.valeur;
                                                }
                                                if (i === 1) {
                                                    vm.totalCF2Colonne6 += valueDataElement.valeur;
                                                }
                                                if (i === 2) {
                                                    vm.totalCF2Colonne7 += valueDataElement.valeur;
                                                }
                                                if (i === 3) {
                                                    vm.totalCF2Colonne8 += valueDataElement.valeur;
                                                }
                                                if (i === 4) {
                                                    vm.totalCF2Colonne9 += valueDataElement.valeur;
                                                }
                                            }
                                        }
                                    }
                                }
                            });

                        });
                        angular.forEach(listeDataTableau3, function (valueData3, keyData3) {
                            if (valueData3.colonne !== null && valueData3.colonne < 5 && valueData3.colonne > 0) {
                                vm.dataCF2ByColonneTableau3[valueData3.colonne - 1] = valueData3;
                            } else if (valueData3.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData3.autreActivite.nom && !found && vm.dataCF2ByColonneTableau3[i + 4] !== {}) {
                                        vm.dataCF2ByColonneTableau3[i + 4] = valueData3;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalNbJourneePerduCampagneFermee2 += valueData3.nbJourneesPerdues;
                            vm.totalCampagneFermee2 += valueData3.nbJourneesPerdues;
                        });
                        angular.forEach(listeDataTableau4, function (valueData4, keyData4) {
                            if (valueData4.colonne !== null && valueData4.colonne < 5 && valueData4.colonne > 0) {
                                vm.dataCF2ByColonneTableau4[valueData4.colonne - 1] = valueData4;
                            } else if (valueData4.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData4.autreActivite.nom && !found && vm.dataCF2ByColonneTableau4[i + 4] !== {}) {
                                        vm.dataCF2ByColonneTableau4[i + 4] = valueData4;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalHommeCampagneFermee2Tableau4 += valueData4.effectifHomme;
                            vm.totalCadreCampagneFermee2Tableau4 += valueData4.effectifCadre;
                            vm.totalFemmeCampagneFermee2Tableau4 += valueData4.effectifFemme;
                            vm.totalNonCadreCampagneFermee2Tableau4 += valueData4.effectifNonCadre;
                            vm.totalCampagneFermee2 += valueData4.effectifHomme + valueData4.effectifFemme;
                            vm.totalCampagneFermee2Bis += valueData4.effectifCadre + valueData4.effectifNonCadre;
                        });
                        angular.forEach(listeDataTableau5, function (valueData5, keyData5) {
                            if (valueData5.colonne !== null && valueData5.colonne < 5 && valueData5.colonne > 0) {
                                vm.dataCF2ByColonneTableau5[valueData5.colonne - 1] = valueData5;
                            } else if (valueData5.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData5.autreActivite.nom && !found && vm.dataCF2ByColonneTableau5[i + 4] !== {}) {
                                        vm.dataCF2ByColonneTableau5[i + 4] = valueData5;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalHommeCampagneFermee2Tableau5 += valueData5.effectifHomme;
                            vm.totalCadreCampagneFermee2Tableau5 += valueData5.effectifCadre;
                            vm.totalFemmeCampagneFermee2Tableau5 += valueData5.effectifFemme;
                            vm.totalNonCadreCampagneFermee2Tableau5 += valueData5.effectifNonCadre;
                            vm.totalCampagneFermee2 += valueData5.effectifHomme + valueData5.effectifFemme;
                            vm.totalCampagneFermee2Bis += valueData5.effectifCadre + valueData5.effectifNonCadre;
                        });
                    }
                    if (data.length > 0) {
                        var listeData = vm.oldCampagnesFormulaires[0].data.sort(function (a, b) { return a.colonne - b.colonne; }); //Tri par colonne
                        var listeDataTableau1 = [];
                        vm.listeDataElementTableau2CF1 = vm.oldCampagnesFormulaires[0].dataElements.sort(function (a, b) { return a.colonne - b.colonne; });
                        var listeDataTableau3 = [];
                        var listeDataTableau4 = [];
                        var listeDataTableau5 = [];
                        vm.totalCF1Colonne1 = 0;
                        vm.totalCF1Colonne2 = 0;
                        vm.totalCF1Colonne3 = 0;
                        vm.totalCF1Colonne4 = 0;
                        vm.totalCF1Colonne5 = 0;
                        vm.totalCF1Colonne6 = 0;
                        vm.totalCF1Colonne7 = 0;
                        vm.totalCF1Colonne8 = 0;
                        vm.totalCF1Colonne9 = 0;
                        angular.forEach(listeData, function (v, k) {
                            switch (v.tableau) {
                                case 1:
                                    listeDataTableau1.push(v);
                                    break;
                                case 3:
                                    listeDataTableau3.push(v);
                                    break;
                                case 4:
                                    listeDataTableau4.push(v);
                                    break;
                                case 5:
                                    listeDataTableau5.push(v);
                                    break;
                                default:
                                    break;
                            }
                        });
                        angular.forEach(listeDataTableau1, function (valueData1, keyData1) {
                            if (valueData1.colonne !== null && valueData1.colonne < 5 && valueData1.colonne > 0) {
                                vm.dataCF1ByColonneTableau1[valueData1.colonne - 1] = valueData1;
                            } else if (valueData1.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData1.autreActivite.nom && !found && vm.dataCF1ByColonneTableau1[i + 4] !== {}) {
                                        vm.dataCF1ByColonneTableau1[i + 4] = valueData1;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalNbAccAvecArretCampagneFermee1 += valueData1.nbAccidentsAvecArret;
                            vm.totalNbAccSansArretCampagneFermee1 += valueData1.nbAccidentsSansArret;
                            vm.totalCampagneFermee1 += valueData1.nbAccidentsAvecArret + valueData1.nbAccidentsSansArret;
                        });
                        angular.forEach(vm.listeDataElementTableau2CF1, function (valueDataElement, keyDataElement) {
                            if (valueDataElement.valeur !== null && valueDataElement.valeur !== undefined && valueDataElement.valeur !== '') {
                                vm.totalCF1 += valueDataElement.valeur;
                            }
                            angular.forEach(vm.dataCF1ByColonneTableau2, function (valueTypeAccident, keyTypeAccident) {
                                if (valueDataElement.element.id === valueTypeAccident[10]) {
                                    if (valueDataElement.colonne !== null && valueDataElement.colonne < 5 && valueDataElement.colonne > 0) {
                                        valueTypeAccident[valueDataElement.colonne] = valueDataElement;
                                        if (valueDataElement.colonne === 1) {
                                            vm.totalCF1Colonne1 += valueDataElement.valeur;
                                        }
                                        if (valueDataElement.colonne === 2) {
                                            vm.totalCF1Colonne2 += valueDataElement.valeur;
                                        }
                                        if (valueDataElement.colonne === 3) {
                                            vm.totalCF1Colonne3 += valueDataElement.valeur;
                                        }
                                        if (valueDataElement.colonne === 4) {
                                            vm.totalCF1Colonne4 += valueDataElement.valeur;
                                        }
                                    } else if (valueDataElement.autreActivite !== null) {
                                        //traitement des autres activités
                                        var found = false;
                                        for (var i = 0; i < vm.autreActivites.length; i++) {
                                            if (vm.autreActivites[i].nom === valueDataElement.autreActivite.nom && !found && valueTypeAccident[i + 5] !== {}) {
                                                valueTypeAccident[i + 5] = valueDataElement;
                                                found = true;
                                                if (i === 0) {
                                                    vm.totalCF1Colonne5 += valueDataElement.valeur;
                                                }
                                                if (i === 1) {
                                                    vm.totalCF1Colonne6 += valueDataElement.valeur;
                                                }
                                                if (i === 2) {
                                                    vm.totalCF1Colonne7 += valueDataElement.valeur;
                                                }
                                                if (i === 3) {
                                                    vm.totalCF1Colonne8 += valueDataElement.valeur;
                                                }
                                                if (i === 4) {
                                                    vm.totalCF1Colonne9 += valueDataElement.valeur;
                                                }
                                            }
                                        }
                                    }

                                }
                            });

                        });
                        angular.forEach(listeDataTableau3, function (valueData3, keyData3) {
                            if (valueData3.colonne !== null && valueData3.colonne < 5 && valueData3.colonne > 0) {
                                vm.dataCF1ByColonneTableau3[valueData3.colonne - 1] = valueData3;
                            } else if (valueData3.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData3.autreActivite.nom && !found && vm.dataCF1ByColonneTableau3[i + 4] !== {}) {
                                        vm.dataCF1ByColonneTableau3[i + 4] = valueData3;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalNbJourneePerduCampagneFermee1 += valueData3.nbJourneesPerdues;
                            vm.totalCampagneFermee1 += valueData3.nbJourneesPerdues;
                        });
                        angular.forEach(listeDataTableau4, function (valueData4, keyData4) {
                            if (valueData4.colonne !== null && valueData4.colonne < 5 && valueData4.colonne > 0) {
                                vm.dataCF1ByColonneTableau4[valueData4.colonne - 1] = valueData4;
                            } else if (valueData4.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData4.autreActivite.nom && !found && vm.dataCF1ByColonneTableau4[i + 4] !== {}) {
                                        vm.dataCF1ByColonneTableau4[i + 4] = valueData4;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalHommeCampagneFermee1Tableau4 += valueData4.effectifHomme;
                            vm.totalCadreCampagneFermee1Tableau4 += valueData4.effectifCadre;
                            vm.totalFemmeCampagneFermee1Tableau4 += valueData4.effectifFemme;
                            vm.totalNonCadreCampagneFermee1Tableau4 += valueData4.effectifNonCadre;
                            vm.totalCampagneFermee1 += valueData4.effectifHomme + valueData4.effectifFemme;
                            vm.totalCampagneFermee1Bis += valueData4.effectifCadre + valueData4.effectifNonCadre;
                        });
                        angular.forEach(listeDataTableau5, function (valueData5, keyData5) {
                            if (valueData5.colonne !== null && valueData5.colonne < 5 && valueData5.colonne > 0) {
                                vm.dataCF1ByColonneTableau5[valueData5.colonne - 1] = valueData5;
                            } else if (valueData5.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData5.autreActivite.nom && !found && vm.dataCF1ByColonneTableau5[i + 4] !== {}) {
                                        vm.dataCF1ByColonneTableau5[i + 4] = valueData5;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalHommeCampagneFermee1Tableau5 += valueData5.effectifHomme;
                            vm.totalCadreCampagneFermee1Tableau5 += valueData5.effectifCadre;
                            vm.totalFemmeCampagneFermee1Tableau5 += valueData5.effectifFemme;
                            vm.totalNonCadreCampagneFermee1Tableau5 += valueData5.effectifNonCadre;
                            vm.totalCampagneFermee1 += valueData5.effectifHomme + valueData5.effectifFemme;
                            vm.totalCampagneFermee1Bis += valueData5.effectifCadre + valueData5.effectifNonCadre;
                        });
                    }
                }
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }
        function showHelp1() {
            if (!vm.helpMessage1) {
                vm.helpMessage1 = true;
            } else {
                vm.helpMessage1 = false;
            }
        }
        function showHelp2() {
            if (!vm.helpMessage2) {
                vm.helpMessage2 = true;
            } else {
                vm.helpMessage2 = false;
            }
        }

        function showHelp3() {
            if (!vm.helpMessage3) {
                vm.helpMessage3 = true;
            } else {
                vm.helpMessage3 = false;
            }
        }

        function showHelp4() {
            if (!vm.helpMessage4) {
                vm.helpMessage4 = true;
            } else {
                vm.helpMessage4 = false;
            }
        }

        function showHelp5() {
            if (!vm.helpMessage5) {
                vm.helpMessage5 = true;
            } else {
                vm.helpMessage5 = false;
            }
        }

        function confirmerRetour() {
            getAccount();
            var message = 'Les informations non sauvegardées seront perdues.';
            if (window.confirm(message)) {
                if (vm.isAdmin) {
                    $state.go('list-formulaires-cci', { id: vm.cci.id, idCampagne: vm.campagneSelected.id });
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id !== vm.account.cci.id) {  //si la cci du formulaire en question est differente de celle du user connecté
                    $state.go('list-formulaires-cci', { id: vm.cci.id, idCampagne: vm.campagneSelected.id });
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id === vm.account.cci.id) {
                    $state.go('mon-bilan-formulaire');
                } else {
                    $state.go('mon-bilan-formulaire');
                }
            }
        }

        function loadAllElements() {
            Element.query({}, onSuccess, onError);
            function onSuccess(data) {
             //Supprimer les élements de type accident
             data = data.filter(function (value) {
                return value["typeElement"] == "typeAccident";
             });
                angular.forEach(data, function (value, key) {
                    var id = value.id;
                    var libelle = value.libelle;
                    var typeElement = value.typeElement;
                    var value = {
                        10: id,
                        11: libelle,
                        12: typeElement,
                        1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {}
                    };
                    var valueCF1 = {
                        10: id,
                        11: libelle,
                        12: typeElement,
                        1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {}
                    };
                    var valueCF2 = {
                        10: id,
                        11: libelle,
                        12: typeElement,
                        1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {}
                    };
                    vm.allTypeAccident.push(value);
                    vm.dataCF1ByColonneTableau2.push(valueCF1);
                    vm.dataCF2ByColonneTableau2.push(valueCF2);
                });
                getCampagnes();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function ajouterTypeAccident(typeAccidentSelectionne) {  //recupere l'objet selectionné
            if (typeAccidentSelectionne !== null) {
                typeAccidentSelectionne[1].valeur = 0;
                typeAccidentSelectionne[2].valeur = 0;
                typeAccidentSelectionne[3].valeur = 0;
                typeAccidentSelectionne[4].valeur = 0;
                typeAccidentSelectionne[5].valeur = 0;
                typeAccidentSelectionne[6].valeur = 0;
                typeAccidentSelectionne[7].valeur = 0;
                typeAccidentSelectionne[8].valeur = 0;
                typeAccidentSelectionne[9].valeur = 0;
                vm.typeAccidentSelectionne = typeAccidentSelectionne;
                //ajouter le modèle de données :
                vm.typeAccidentSelected[typeAccidentSelectionne[10]] = typeAccidentSelectionne;
                for (var i = 0; i < vm.typeAccidentForSelect.length; i++) {
                    if (vm.typeAccidentForSelect[i][10] === vm.typeAccidentSelectionne[10]) {
                        vm.typeAccidentForSelect.splice(i, 1);  //Enlever l'element ajouté, du select
                    }
                }
            }
        }

        function supprimerTypeAccident(valueTypeAccident) {
            if (vm.typeAccidentSelected !== {}) {
                angular.forEach(vm.typeAccidentSelected, function (value, key) {
                    if (value[10] === valueTypeAccident[10]) {
                        var message = 'Supprimer la ligne « ' + valueTypeAccident[11] + ' » ?';
                        if (window.confirm(message)) {
                            //Recalculer les totaux
                            if (valueTypeAccident[1].valeur !== null && valueTypeAccident[1].valeur !== undefined && valueTypeAccident[1].valeur !== '') {
                                vm.totalColonne1 = vm.totalColonne1 - parseInt(valueTypeAccident[1].valeur);
                            }
                            if (valueTypeAccident[2].valeur !== null && valueTypeAccident[2].valeur !== undefined && valueTypeAccident[2].valeur !== '') {
                                vm.totalColonne2 = vm.totalColonne2 - parseInt(valueTypeAccident[2].valeur);
                            }
                            if (valueTypeAccident[3].valeur !== null && valueTypeAccident[3].valeur !== undefined && valueTypeAccident[3].valeur !== '') {
                                vm.totalColonne3 = vm.totalColonne3 - parseInt(valueTypeAccident[3].valeur);
                            }
                            if (valueTypeAccident[4].valeur !== null && valueTypeAccident[4].valeur !== undefined && valueTypeAccident[4].valeur !== '') {
                                vm.totalColonne4 = vm.totalColonne4 - parseInt(valueTypeAccident[4].valeur);
                            }
                            if (valueTypeAccident[5].valeur !== null && valueTypeAccident[5].valeur !== undefined && valueTypeAccident[5].valeur !== '') {
                                vm.totalColonne5 = vm.totalColonne5 - parseInt(valueTypeAccident[5].valeur);
                            }
                            if (valueTypeAccident[6].valeur !== null && valueTypeAccident[6].valeur !== undefined && valueTypeAccident[6].valeur !== '') {
                                vm.totalColonne6 = vm.totalColonne6 - parseInt(valueTypeAccident[6].valeur);
                            }
                            if (valueTypeAccident[7].valeur !== null && valueTypeAccident[7].valeur !== undefined && valueTypeAccident[7].valeur !== '') {
                                vm.totalColonne7 = vm.totalColonne7 - parseInt(valueTypeAccident[7].valeur);
                            }
                            if (valueTypeAccident[8].valeur !== null && valueTypeAccident[8].valeur !== undefined && valueTypeAccident[8].valeur !== '') {
                                vm.totalColonne8 = vm.totalColonne8 - parseInt(valueTypeAccident[8].valeur);
                            }
                            if (valueTypeAccident[9].valeur !== null && valueTypeAccident[9].valeur !== undefined && valueTypeAccident[9].valeur !== '') {
                                vm.totalColonne9 = vm.totalColonne9 - parseInt(valueTypeAccident[9].valeur);
                            }

                            delete vm.typeAccidentSelected[value[10]]; //must return true
                            vm.typeAccidentForSelect.push(valueTypeAccident); //Pour remettre l'objet supprimé dans le select

                            //mettre à jour les valeurs dans l'objet supprimé
                            valueTypeAccident[1].valeur = null;
                            valueTypeAccident[2].valeur = null;
                            valueTypeAccident[3].valeur = null;
                            valueTypeAccident[4].valeur = null;
                            valueTypeAccident[5].valeur = null;
                            valueTypeAccident[6].valeur = null;
                            valueTypeAccident[7].valeur = null;
                            valueTypeAccident[8].valeur = null;
                            valueTypeAccident[9].valeur = null;
                        }
                    }
                });
            }
        }

        function displayPlus(idElement) {
            var elmts = document.getElementsByClassName(idElement);
            for (var i = 0; i < elmts.length; i++) {
                elmts[i].style.visibility = "visible";
            }
        }

        function displayMinus(idElement) {
            idElement = "idRemove" + idElement;
            var elmts = document.getElementsByClassName(idElement);
            for (var i = 0; i < elmts.length; i++) {
                elmts[i].style.visibility = "visible";
            }
        }

        function hideMinus(idElement) {
            idElement = "idRemove" + idElement;
            var elmts = document.getElementsByClassName(idElement);
            for (var i = 0; i < elmts.length; i++) {
                elmts[i].style.visibility = "hidden";
            }
        }

        function hidePlus(idElement) {
            var elmts = document.getElementsByClassName(idElement);
            for (var i = 0; i < elmts.length; i++) {
                elmts[i].style.visibility = "hidden";
            }
        }

        function calculerTotauxByColonne() {
            for (var i = 0; i < vm.autreActivites.length + 4; i++) {
                vm.totalColonne[i][i] = 0;
            }
            for (var i = 0; i < vm.autreActivites.length + 4; i++) {
                angular.forEach(vm.typeAccidentSelected, function (valueTypeAccident, key) {
                    if (valueTypeAccident[i + 1].valeur !== null && valueTypeAccident[i + 1].valeur !== undefined && valueTypeAccident[i + 1].valeur !== '') {
                        vm.totalColonne[i][i] = vm.totalColonne[i][i] + parseInt(valueTypeAccident[i + 1].valeur);
                    }
                });
                switch (i) {
                    case 0:
                        vm.totalColonne1 = vm.totalColonne[i][i];
                        break;
                    case 1:
                        vm.totalColonne2 = vm.totalColonne[i][i];
                        break;
                    case 2:
                        vm.totalColonne3 = vm.totalColonne[i][i];
                        break;
                    case 3:
                        vm.totalColonne4 = vm.totalColonne[i][i];
                        break;
                    case 4:
                        vm.totalColonne5 = vm.totalColonne[i][i];
                        break;
                    case 5:
                        vm.totalColonne6 = vm.totalColonne[i][i];
                        break;
                    case 6:
                        vm.totalColonne7 = vm.totalColonne[i][i];
                        break;
                    case 7:
                        vm.totalColonne8 = vm.totalColonne[i][i];
                        break;
                    case 8:
                        vm.totalColonne9 = vm.totalColonne[i][i];
                        break;
                    default:
                        break;
                }
            }
        }

        function getOldvalueByElementId(idElement, position) {
            var value = 0;
            angular.forEach(vm.dataCF1ByColonneTableau2, function (valueTypeAccident, key) {
                if (valueTypeAccident[10] === idElement) {
                    value = valueTypeAccident[position].valeur;
                }
            });
            return value;
        }

        function getOldvalueByElementIdBis(idElement, position) {
            var value = 0;
            angular.forEach(vm.dataCF2ByColonneTableau2, function (valueTypeAccident, key) {
                if (valueTypeAccident[10] === idElement) {
                    value = valueTypeAccident[position].valeur;
                }
            });
            return value;
        }

        function getOldvalueOrNullByElementId(idElement, position) {
            var value = null;
            angular.forEach(vm.dataCF1ByColonneTableau2, function (valueTypeAccident, key) {
                if (valueTypeAccident[10] === idElement) {
                    value = valueTypeAccident[position].valeur;
                }
            });
            return value;
        }

        function getOldvalueOrNullByElementIdBis(idElement, position) {
            var value = null;
            angular.forEach(vm.dataCF2ByColonneTableau2, function (valueTypeAccident, key) {
                if (valueTypeAccident[10] === idElement) {
                    value = valueTypeAccident[position].valeur;
                }
            });
            return value;
        }

        function getOldvaluesTotalByElementId(idElement) {
            var value = null;
            angular.forEach(vm.listeDataElementTableau2CF1, function (valueDataElement, key) {
                if (valueDataElement.element.id === idElement) {
                    var valueTemp = 0;
                    if (valueDataElement.valeur !== null && valueDataElement.valeur !== undefined && valueDataElement.valeur !== '') {
                        valueTemp += valueDataElement.valeur;
                    }
                    if (valueTemp > 0) {
                        value += valueTemp;
                    }
                }
            });
            return value;
        }

        function getOldvaluesTotalByElementIdBis(idElement) {
            var value = null;
            angular.forEach(vm.listeDataElementTableau2CF2, function (valueDataElement, key) {
                if (valueDataElement.element.id === idElement) {
                    var valueTemp = 0;
                    if (valueDataElement.valeur !== null && valueDataElement.valeur !== undefined && valueDataElement.valeur !== '') {
                        valueTemp += valueDataElement.valeur;
                    }
                    if (valueTemp > 0) {
                        value += valueTemp;
                    }
                }
            });
            return value;
        }

    }
})();
