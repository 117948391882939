(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('BhsController', BhsController);

    BhsController.$inject = ['$state'];

    function BhsController($state) {
    }
})();