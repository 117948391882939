(function() {
    'use strict';

    angular
        .module('bhsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('formulaires-cci', {
            parent: 'bilan-cci',
            url: '/formulaires-cci??page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CCIR'],
                pageTitle: 'bhsApp.bilan.formulaires.cci.formulaires'
            },
            views: {
                'content@bhs': {
                    templateUrl: 'app/bilan/cci/formulaires/formulaires-cci.html',
                    controller: 'FormulairesCciController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'cci.region.libelle,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('formulairesCci');
                    return $translate.refresh();
                }]
            }
        })
        .state('list-formulaires-cci', {
                     parent: 'formulaires-cci',
                     url: '/list-formulaires-cci/{id}/{idCampagne}',
                     data: {
                         authorities: ['ROLE_ADMIN','ROLE_CCIR'],
                         pageTitle: 'bhsApp.bilan.formulaires.cci.listFormulaires.title'
                     },
                     views: {
                         'content@bhs': {
                             templateUrl: 'app/bilan/cci/formulaires/list-formulaires/list-formulaires-cci.html',
                             controller: 'ListFormulairesCciController',
                             controllerAs: 'vm'
                         }
                     },
                     resolve: {
                         entity: ['CCI', '$stateParams', function(CCI, $stateParams) {
                             return CCI.get({id : $stateParams.id});
                         }],
                         entityCampagne: ['Campagne', '$stateParams', function(Campagne, $stateParams){
                            return Campagne.get({id : $stateParams.idCampagne});
                         }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('formulaire');
                            $translatePartialLoader.addPart('formulairesCci');
                            return $translate.refresh();
                        }]
                     }
         });
    }

})();
