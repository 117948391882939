(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('TableauNombreAccidentsAvecSansArretCtrl', TableauNombreAccidentsAvecSansArretCtrl);

    TableauNombreAccidentsAvecSansArretCtrl.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function TableauNombreAccidentsAvecSansArretCtrl ($scope, $state, ParseLinks, AlertService) {
        var vm = this;
    }
})();
