(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('ExportBilansController', ExportBilansController);

    //injection des dépendances
    ExportBilansController.$inject = ['$scope', '$timeout', '$state', 'ExportBilansService', 'AlertService', 'CCI', 'Region', 'Campagne', 'Principal', '$window'];

    // déclaration du controleur
    function ExportBilansController ($scope, $timeout, $state, ExportBilansService, AlertService, CCI, Region, Campagne, Principal, $window) {
        // Init variables
        var vm = this;
        vm.campagnes = [];
        vm.regions = [];
        vm.ccis = [];
        vm.campagneSelection=null;
        vm.bilanNational=false;
        vm.bilanRegional=false;
        vm.bilanCCI=false;
        vm.errorValidationVerbatim = false;
        vm.errorValidationDepenses = false;
        vm.regionSelection=null;
        vm.cciSelection=null;
        vm.campagnesSelection=[];
        vm.campagnesSelectionDepenses=[];
        vm.ccis_correspondantes = [];
        vm.authority=null;
        vm.errorSelectionCCI=false;
        vm.errorSelectionRegion=false;
        vm.errorSelectionCampagnes=false;
        vm.errorSelection=false;
        vm.account = null;

        //Init functions
        vm.loadAllCampagnes = loadAllCampagnes;
        vm.loadAllRegions = loadAllRegions;
        vm.loadAllCcis = loadAllCcis;
        vm.findCcisCorrespondantes = findCcisCorrespondantes;
        vm.genererVerbatim = genererVerbatim;
        vm.genererBilan = genererBilan;
        vm.genererDepenses = genererDepenses;

        //appels aux méthodes au chargement
        getAccount();
        loadAllCampagnes ();
        loadAllCcis();



        //définition des fonctions
        function getAccount() {
            Principal.identity().then(function(account) {
            vm.account = account;
            });
        }

        Principal.hasAuthority("ROLE_CCIR")
                                .then(function (result) {
                                    if (result) {
                                        vm.authority="ROLE_CCIR";
                                    }
                                });
        Principal.hasAuthority("ROLE_USER")
                                .then(function (result) {
                                    if (result) {
                                        vm.authority="ROLE_USER";
                                    }
                                });

        function loadAllCampagnes () {

            Campagne.getAllCampagnes({}, onSuccess, onError);

            function onSuccess(data) {
                vm.campagnes = data;
            }
            function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllRegions () {
                    Region.getRegionsByProfil({}, onSuccess, onError);

                    function onSuccess(data, headers) {
                        vm.regions = data;
                        if(vm.authority==='ROLE_CCIR'){
                            vm.regionSelection=vm.regions[0];
                            vm.findCcisCorrespondantes(vm.regionSelection.id);
                        }
                    }
                    function onError(error) {
                         AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                    }
        }
        function loadAllCcis () {
                    CCI.getAllCCIS({}, onSuccess, onError);

                    function onSuccess(data, headers) {
                        vm.ccis = data;
                        loadAllRegions();
                    }
                    function onError(error) {
                         AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                    }
        }

         function findCcisCorrespondantes(idRegionSelected){
            vm.ccis_correspondantes = [];
            if(idRegionSelected != null){
                angular.forEach(vm.ccis, function(value, key) {
                   if(value.region!= null && value.region.id == idRegionSelected){
                      vm.ccis_correspondantes.push(value);
                   }
                });
                //dans le cas ou la liste contient qu'une seule CCI => selection par défaut
                if(vm.ccis_correspondantes.length==1){
                    vm.cciSelection = vm.ccis_correspondantes[0];
                }
            } else {
                angular.forEach(vm.ccis, function (value, key) {
                    if (value.region === null) {
                        vm.ccis_correspondantes.push(value);
                    }
                });
            }
        }

        function genererVerbatim(){
            vm.errorValidationVerbatim=false;
            if(vm.campagneSelection === null){
                vm.errorValidationVerbatim = true;
            } else {
                ExportBilansService.genererVerbatim(vm.campagneSelection,onExportSuccess, onExportError);
            }
        }

        function genererDepenses(){
            vm.errorValidationDepenses=false;
            if(vm.campagnesSelectionDepenses.length<1 || vm.campagnesSelectionDepenses.length>3){
                vm.errorValidationDepenses = true;
            } else {
                ExportBilansService.genererDepenses(vm.campagnesSelectionDepenses,onExportSuccess, onExportError);
            }
        }


        function onExportSuccess (data) {
               var blob = data.response.blob;
               var fileName = data.response.fileName;

               ($window).saveAs(blob, fileName);
         }

         function onExportError () {
             AlertService.error("Un problème est survenu lors de l'export, merci de contacter votre administrateur");
         }

         function genererBilan() {
                //validation
                vm.errorSelectionCCI=false;
                vm.errorSelectionRegion=false;
                vm.errorSelectionCampagnes=false;
                vm.errorSelection=false;

                if(vm.bilanRegional && vm.regionSelection === null){
                    vm.errorSelectionRegion = true;
                }
                if(vm.bilanCCI && vm.cciSelection === null){
                    vm.errorSelectionCCI = true;
                }
                if(vm.campagnesSelection.length<1 || vm.campagnesSelection.length>3){
                    vm.errorSelectionCampagnes=true;
                }


                //en cas de connexion d'un utilisateur contributeur, prendre sa CCI
                if(vm.authority==='ROLE_USER'){
                    vm.bilanCCI = true;
                    vm.errorSelectionCCI = false;
                }

                if(!vm.bilanNational && !vm.bilanRegional && !vm.bilanCCI){
                    vm.errorSelection = true;
                }

                //appel WS
                if(!vm.errorSelection && !vm.errorSelectionRegion && !vm.errorSelectionCCI && !vm.errorSelectionCampagnes){
                //bilan national
                    if(vm.bilanNational){
                        ExportBilansService.genererBilanAnnuel(vm.campagnesSelection, onExportSuccess, onExportError);
                    }
                //bilan regional
                    if(vm.bilanRegional){
                         ExportBilansService.genererBilanRegional({region : vm.regionSelection, campagnes :
                         vm.campagnesSelection}, onExportSuccess, onExportError);
                    }
                //bilan CCI
                    if(vm.bilanCCI){
                         if(vm.authority==='ROLE_USER'){
                             ExportBilansService.genererBilanCCI({cci : vm.account.cci, campagnes : vm.campagnesSelection},onExportSuccess, onExportError);
                         } else {
                         ExportBilansService.genererBilanCCI({cci : vm.cciSelection[0], campagnes : vm.campagnesSelection},onExportSuccess, onExportError);
                         }
                    }
                }
        }


         $scope.$watch("vm.bilanNational", function(bilanNational) {
                    if(bilanNational) {
                      vm.bilanRegional = false;
                      vm.bilanCCI = false;
                      vm.regionSelection=null;
                      vm.cciSelection=null;
                      vm.ccis_correspondantes = [];
                    }
         });

         $scope.$watch("vm.bilanRegional", function(bilanRegional) {
                    if(bilanRegional) {
                      vm.bilanNational = false;
                      vm.bilanCCI = false;
                      vm.cciSelection=null;
                    }
         });

         $scope.$watch("vm.bilanCCI", function(bilanCCI) {
                    if(bilanCCI) {
                      vm.bilanRegional = false;
                      vm.bilanNational = false;
                      if(vm.regionSelection != null){
                          vm.findCcisCorrespondantes(vm.regionSelection.id);
                      }else {
                          vm.findCcisCorrespondantes(null);
                      }
                    } else{
                       vm.ccis_correspondantes = [];
                    }
         });





    }
})();
