(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('TableauRepartitionAccidentsSansArretCtrl', TableauRepartitionAccidentsSansArretCtrl);

    TableauRepartitionAccidentsSansArretCtrl.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function TableauRepartitionAccidentsSansArretCtrl ($scope, $state, ParseLinks, AlertService) {

    }
})();
