(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('BilanNationalDetailController', BilanNationalDetailController);

    BilanNationalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'BilanNational', 'Campagne'];

    function BilanNationalDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, BilanNational, Campagne) {
        var vm = this;

        vm.bilanNational = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bhsApp:bilanNationalUpdate', function(event, result) {
            vm.bilanNational = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
