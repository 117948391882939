(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var resourceUrl =  'api/users/:login';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method:'PUT'
            },
            'search': {
                url: 'api/searchUsers/:login/:email/:lastName/:firstName/:authority/:region/:cci/:actif',
                method: 'GET',
                isArray: true
            },
            'filters': {
                url: 'api/users/filters',
                method: 'GET',
                isArray: false
            },
            'sorting': {
                url: 'api/users/sorting',
                method: 'PUT'
            }
        });
    }
})();
