(function () {
    'use strict';

    angular
        .module('bhsApp')
        .component('footerComponent', {
            templateUrl: "app/layouts/footer/footer.html",
            controller: FooterController
        });

    FooterController.$inject = ['VERSION'];

    function FooterController(VERSION) {
        this.appVersion = VERSION;
    }
})();