(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('tableauNombreMaladiesProfessionellesDeclarees', tableauNombreMaladiesProfessionellesDeclarees);

    tableauNombreMaladiesProfessionellesDeclarees.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function tableauNombreMaladiesProfessionellesDeclarees ($scope, $state, ParseLinks, AlertService) {
        var vm = this;




    }
})();
