(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('CCIController', CCIController);

    CCIController.$inject = ['$scope', '$state', 'CCI', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Campagne', 'NgTableParams', '$filter'];

    function CCIController ($scope, $state, CCI, ParseLinks, AlertService, paginationConstants, pagingParams, Campagne, NgTableParams, filter) {
        var vm = this;
        vm.checkDate = false ;
        vm.tableParams = null;
        vm.getCciData = getCciData;
        vm.listCcis = [] ;
        vm.ccis = [] ;

        vm.sort = sort;

        loadAll();

        function loadAll () {
         chargerDelaisDeCampagneEnCours();
           CCI.getAllCCIS({}, onSuccess, onError).$promise.then(setMemorySorting);
            function onSuccess(data, headers) {
                vm.listCcis = data;
                 vm.tableParams = new NgTableParams({
                                  page: 1,
                                  count: 1000,
                                  sorting: {
                                      nom: 'asc' // initial sorting
                                  }
                              }, {
                                  total: data.length,
                                  counts: [50,100,1000],
                                  getData: vm.getCciData
                              });
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function setMemorySorting() {
            CCI.getSort(function success(data) {
                if (vm.tableParams != null) {
                    vm.tableParams.sorting(data);
                }
            },
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            });
        }

         function getCciData (params) {
                     //tri de la liste
                     vm.ccis = params.sorting() ? filter('orderBy')(vm.listCcis, params.orderBy()) : vm.listCcis;
                     //récuperation des éléments à afficher en fonction de la page
                     vm.ccis = vm.ccis.slice((params.page() - 1) * params.count(), params.page() * params.count());
                     return vm.ccis;
                 }

         // si checkDate est false nous sommes en dehors de la campagne en cours
         function chargerDelaisDeCampagneEnCours() {
                  Campagne.campagneEncours({}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.checkDate = data.length != 0;
                    }

                    function onError(error) {
                        AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                    }
                }

        function sort() {
            CCI.sort({
                nom: getSort("nom"),
                "region.libelle": getSort("region.libelle"),
                actif: getSort("actif")
            });

            function getSort(field) {
                if (vm.tableParams == null) {
                    return null;
                }
                if (vm.tableParams.isSortBy(field, "asc")) {
                    return "asc";
                } else if (vm.tableParams.isSortBy(field, "desc")) {
                    return "desc";
                } else {
                    return null;
                }
            }
        }
    }
})();
