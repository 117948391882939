(function() {
    'use strict';

    angular
        .module('bhsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('mon-bilan-formulaire', {
            parent: 'mon-bilan',
            url: '/mon-bilan-formulaire',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CCIR'],
                pageTitle: 'bhsApp.bilan.formulaires.cci.monBilanFormulaires.title'
            },
            views: {
                'content@bhs': {
                    templateUrl: 'app/bilan/cci/formulaires/mon-bilan-formulaire/mon-bilan-formulaire.html',
                    controller: 'MonBilanFormulaireController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('formulairesCci');
                      $translatePartialLoader.addPart('autreActivite');
                     return $translate.refresh();
                }]
            }
        });
    }

})();
