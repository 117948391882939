(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('MentionsController', MentionsController);

    MentionsController.$inject = [];

    function MentionsController() {}
})();