(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('AutreActiviteDialogController', AutreActiviteDialogController);

    AutreActiviteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AutreActivite', 'Data', 'DataElement', 'CCI', 'Campagne'];

    function AutreActiviteDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AutreActivite, Data, DataElement, CCI, Campagne) {
        var vm = this;

        vm.autreActivite = entity;
        vm.clear = clear;
        vm.save = save;
        vm.data = Data.query();
        vm.dataelements = DataElement.query();
        vm.ccis = CCI.query();
        vm.campagnes = Campagne.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.autreActivite.id !== null) {
                AutreActivite.update(vm.autreActivite, onSaveSuccess, onSaveError);
            } else {
                AutreActivite.save(vm.autreActivite, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bhsApp:autreActiviteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
