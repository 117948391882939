(function(){
    'use strict'

    angular
        .module('bhsApp')
        .service('SsoService', ['$http', SsoService]);

    function SsoService($http){

        /*
        * Return the url to access SSO authorized page
        * set up with all parameters
        */
        this.authorized = function(){
            return $http.get("/cciconnect/auth");
        };
        
        /*
        * Return a boolean depending on the fact that the
        * login was a success or not
        * 
        * @param code authorization_code provided by cci connect to access
        *              the user informations
        */
        this.loginViaEmail = function(code){
            return $http.post("/cciconnect/auth/sso?code="+code);
        }

    }

})();