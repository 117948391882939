(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'Principal', 'CCI', 'Region'];

    function UserManagementDialogController($stateParams, $uibModalInstance, entity, User, Principal, CCI, Region) {

        //Init variables
        var vm = this;
        vm.authorities = [
            {libelle: 'Administrateur', role: 'ROLE_ADMIN'},
            {libelle: 'CCIR', role: 'ROLE_CCIR'},
            {libelle: 'Contributeur', role: 'ROLE_USER'}
        ];
        vm.authoritiesRestricted = [{libelle: 'Contributeur', role: 'ROLE_USER'}];

        vm.user = entity;
        vm.ccis = [];
        vm.regions = [];
        vm.idRegionSelected = null;
        vm.ccis_correspondantes = [];
        vm.isCciAndRegionRequired = false;
        vm.region = null;
        vm.authority = null;
        vm.userConnectedAuthority = "";
        vm.cci = null;
        vm.activated = false;

        //Init functions
        vm.clear = clear;
        vm.cciAndRegionRequired = cciAndRegionRequired;
        vm.save = save;
        vm.loadAllRegions = loadAllRegions;
        vm.loadAllCCIs = loadAllCCIs;
        vm.findCcisCorrespondantes = findCcisCorrespondantes;
        vm.changeProfile = changeProfile;
        vm.ccisExist = []
        vm.parametreConfigurationCheckbox = {
            checkBoxes: true,
            dynamicTitle: false,
            showUncheckAll: false,
            showCheckAll: false
        };
        vm.selectTitre = {
            buttonDefaultText: "Sélectionner..."
        };

        //call functions
        vm.loadAllCCIs();
        vm.loadAllRegions();
        vm.updateCci = updateCci;
        vm.checkIfUserExistAndHerProfilIsAdmin = checkIfUserExistAndHerProfilIsAdmin;
        vm.profil = null ;
        vm.isAdmin = false;

        Principal.hasAuthority("ROLE_CCIR")
                                        .then(function (result) {
                                            if (result) {
                                                vm.userConnectedAuthority="ROLE_CCIR";
                                            }
                                        });

        Principal.hasAuthority("ROLE_ADMIN")
                        .then(function (result) {
                            if (result) {
                             vm.isAdmin=true;
                            }
                        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
             vm.user.ccis = [];
            angular.forEach(vm.authorities, function (value, key) {
                if (vm.authority && (value.libelle == vm.authority.libelle)) {
                    vm.user.authorities = [];
                    vm.user.authorities.push(value.role);
                }
            });

            angular.forEach(vm.listCcisSelectionne, function (value, key) {
               function rechercheCci(cci) {

                    if (vm.authority && (vm.authority.libelle === "Administrateur")) {
                      return cci.id = null;
                      //Pour l'admin, récupère une liste avec un seul CCI -> CCI France
                     }
                     else{
                       return cci.id == value.id;
                     }
               }

                     vm.user.ccis.push(vm.ccis.find(rechercheCci));
            });

             // update de profil au cas l'utilisateur a selectionne la region sans selectionne de cci
             if(vm.user.id != null && vm.idRegionSelected != null && vm.listCcisSelectionne.length == 0 ){

                        var getFirstCci =  vm.listeCCis[0] ;
                        function rechercheCci(cci) {
                                     return cci.id == getFirstCci.id;
                                                   }
                        vm.user.ccis[0] = vm.ccis.find(rechercheCci);
             }
               vm.user.cci = vm.user.ccis[0];
            if (vm.activated != vm.user.activated) {
                var message = '';
                if (vm.activated == true) {
                    message = 'L\'utilisateur ' + vm.user.firstName + ' ' + vm.user.lastName + ' ne va plus pouvoir accéder à l\'application. Confirmez-vous la désactivation de son compte ?'
                } else {
                    message = 'L\'utilisateur ' + vm.user.firstName + ' ' + vm.user.lastName + ' va de nouveau pouvoir accéder à l\'application. Confirmez-vous la réactivation de son compte ?';
                }
                if (window.confirm(message)) {
                    launchSave();
                } else {
                    vm.isSaving = false;
                }
            } else {
                launchSave();

        }

   }
        function launchSave() {
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function loadAllRegions() {
            Region.getRegionsByProfil({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.regions = data;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }
        function updateCci(){
                vm.cci = vm.listCcisSelectionne[0] ;
        }
        function loadAllCCIs() {
            CCI.getAllCCIS({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.ccis = data;
                vm.activated = vm.user.activated;
                //mise à jour du rôle du user
                if (vm.user.authorities != null) {
                    angular.forEach(vm.authorities, function (value, key) {
                        if (value.role == vm.user.authorities[0]) {
                            vm.authority = value;
                        }
                    });
                }
                //mise à jour de la liste des cci selon la région
                if (vm.user.cci != null) {
                    vm.findCcisCorrespondantes(vm.user.cci.region !== null ? vm.user.cci.region.id : null);
                    vm.region = vm.user.cci.region;
                    vm.cci = vm.user.cci;
                    vm.userCcisIndex = null;
                    // cochage des ccis d'un utilisateur par defaut lors de la modification
                    if(vm.user.ccis.length == 0){
                        vm.user.ccis.push(vm.user.cci);
                    }

                      angular.forEach(vm.user.ccis, function (value, key) {
                        function rechercheCciIndex(cci) {
                                             return cci.id == value.id;
                                                        }
                                             vm.userCcisIndex = vm.listeCCis.findIndex(rechercheCciIndex );
                                             vm.listCcisSelectionne.push( vm.listeCCis[vm.userCcisIndex]) });

                  }
                //mode modification Admin
                if (vm.user.id !== null) {
                    if (vm.authority && (vm.authorities[0].role == vm.authority.role)) {
                        vm.isCciAndRegionRequired = true;
                    }
                }
                if(vm.user.id === null){
                    vm.findCcisCorrespondantes(null);
                }

            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function findCcisCorrespondantes(idRegionSelected) {
            vm.idRegionSelected = idRegionSelected;
            vm.ccis_correspondantes = [];
             vm.listeCCis = [];
             vm.listCcisSelectionne = []
            if (idRegionSelected != null) {
                angular.forEach(vm.ccis, function (value, key) {
                    if (value.region != null && value.region.id == idRegionSelected) {
                        //Profil contributeur => CCI
                        if (vm.authority != null && vm.authorities[2].role == vm.authority.role && value.type == "CCI" && value.actif) {
                            vm.ccis_correspondantes.push(value);
                            vm.listeCCis.push({id: value.id,label: value.nom});
                        }
                        //Profil contributeur => ACFCI uniquement pour le role admin
                        if (vm.authority != null && vm.authorities[2].role == vm.authority.role && value.type == "ACFCI" && value.actif && vm.userConnectedAuthority != "ROLE_CCIR") {
                            vm.ccis_correspondantes.push(value);
                            vm.listeCCis.push({id: value.id,label: value.nom});
                        }
                        //profil ccir => CCIR
                        if (vm.authority != null && vm.authorities[1].role == vm.authority.role && value.type != "CCI" && value.type != "ACFCI" && value.actif) {
                            vm.ccis_correspondantes.push(value);
                            vm.listeCCis.push({id: value.id,label: value.nom});
                        }

                    }
                });

            } else {
                    angular.forEach(vm.ccis, function (value, key) {
                        if (value.region === null) {
                            //Profil contributeur => CCI
                            if (vm.authority != null && vm.authorities[2].role == vm.authority.role && value.type == "CCI" && value.actif) {
                                vm.ccis_correspondantes.push(value);
                                vm.listeCCis.push({id: value.id,label: value.nom});
                            }
                            //Profil contributeur => ACFCI uniquement pour le role admin
                            if (vm.authority != null && vm.authorities[2].role == vm.authority.role && value.type == "ACFCI" && value.actif && vm.userConnectedAuthority != "ROLE_CCIR") {
                                vm.ccis_correspondantes.push(value);
                                vm.listeCCis.push({id: value.id,label: value.nom});
                            }
                            //profil ccir => CCIR
                            if (vm.authority != null && vm.authorities[1].role == vm.authority.role && value.type != "CCI" && value.type != "ACFCI" && value.actif) {
                                vm.ccis_correspondantes.push(value);
                                vm.listeCCis.push({id: value.id,label: value.nom});
                            }

                        }
                    });
            }
        }

        function cciAndRegionRequired(profil) {
            vm.isCciAndRegionRequired = false;
            if (vm.authority && (vm.authorities[0].role == vm.authority.role)) {
                vm.isCciAndRegionRequired = true;
            }
            changeProfile(profil);
        }

        function changeProfile(profil) {
            vm.profil = profil;
            if (vm.authority && (vm.authority.libelle === "Administrateur")) {
                vm.region = null;
                vm.cci = null;
            }
            if (vm.region === null) {
                findCcisCorrespondantes(null);
            } else {
                findCcisCorrespondantes(vm.region.id);
                vm.cci = null;
            }
            if(vm.user.id !=  null){
                     vm.region = null;
                     vm.user.ccis = [];
            }
        }
        function checkIfUserExistAndHerProfilIsAdmin(){
             return vm.user.profil == 'Administrateur' && vm.user.id != null ;
        }

    }
})();
