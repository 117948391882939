(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulairesCciController', FormulairesCciController);

    // injection des dépendances
    FormulairesCciController.$inject = ['$scope', '$filter', '$timeout', '$state', 'FormulaireCciService', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'CCI', 'Region', 'DateUtils', 'Principal', '$q', '$window', 'NgTableParams'];

    // déclaration du controleur
    function FormulairesCciController($scope, $filter, $timeout, $state, FormulaireCciService, ParseLinks, AlertService, paginationConstants, pagingParams, CCI, Region, DateUtils, Principal, $q, $window, NgTableParams) {
        // Init variables
        var vm = this;
        vm.account = null;
        vm.completudes = [
            { libelle: 'Incomplet', role: '0' },
            { libelle: 'Complet', role: '1' }
        ];
        vm.validations = [
            { libelle: 'A valider', role: '0' },
            { libelle: 'Validé', role: '1' }
        ];
        vm.complet = null;
        vm.valide = null;
        vm.region = null;
        vm.cci = null;
        vm.idRegionSelected = null;
        vm.ccis_correspondantes = [];
        vm.tableParams = null;
        vm.campagnes = [];
        vm.campagnesLabels = [];
        vm.formulairesCci = [];
        vm.listeFormulairesCci = [];
        vm.regions = [];
        vm.ccis = [];
        vm.campagneSelection = null;
        vm.campagneToSelectAtInit = null;
        vm.currentDate = new Date();
        vm.hasCampagneInProgress = true;
        vm.userConnectedAuthority = null;

        // Init functions
        vm.loadAllCampagnes = loadAllCampagnes;
        vm.loadAllRegions = loadAllRegions;
        vm.loadAllCcis = loadAllCcis;
        vm.noCampagneInProgress = noCampagneInProgress;
        vm.afficherTableau = afficherTableau;
        vm.afficherHead = afficherHead;
        vm.findCcisCorrespondantes = findCcisCorrespondantes;
        vm.search = search;
        vm.getData = getData;
        vm.afficherBoutonListeFormulaire = afficherBoutonListeFormulaire;
        vm.afficherBoutonMonBilan = afficherBoutonMonBilan;
        vm.campagneEncours = null;
        vm.isEncours = false;
        vm.listFormCci = []
        vm.ccis_correspondantes_actif = []
        vm.sort = sort;

        vm.memoryFilters = null;

        // appels aux méthodes au chargement
        Principal.hasAuthority("ROLE_CCIR")
            .then(function (result) {
                if (result) {
                    vm.userConnectedAuthority = "ROLE_CCIR";
                }
            });
        getAccount();
        loadAllCampagnes().$promise
            .then(loadAllCcis); // Then : loadAllRegions | Then : FormulaireCciService.filters

        $scope.$watch('vm.campagneSelection', function () {
            if (null !== vm.campagneSelection && angular.isDefined(vm.campagneSelection)) {
                sessionStorage.formulairesCciCampagne = angular.toJson(vm.campagneSelection);
                vm.hasCampagneInProgress = true;
                vm.listeFormulairesCci = [];
                vm.isEncours = vm.campagneSelection.label === 'Campagne en cours' || vm.campagneSelection.label === undefined;
                if ((vm.userConnectedAuthority === 'ROLE_CCIR')
                        || (vm.region !== null)) {
                    if(vm.region){
                        vm.findCcisCorrespondantes(vm.region.id);
                    }else{
                        console.log("L'utilisateur n'a pas de région de rattachement");
                    }
                } else {
                    vm.findCcisCorrespondantes(null);
                }
                /**
                 * Récupérer les formulaires de la campagne sélectionnée
                 */
                //FormulaireCciService.getOne({ id: vm.campagneSelection.id }, onSuccessCampagne, onErrorCampagne);
                FormulaireCciService.getOne({ id: vm.campagneSelection.id }, onSimpleSuccessCampagne, onErrorCampagne);
                function onSimpleSuccessCampagne(campagne) {
                    vm.search();
                }
                /*function onSuccessCampagne(campagne) {
                    if (vm.isEncours) {
                        angular.forEach(campagne.formulairesCci, function (value, key) {
                            if (value.cci.actif) {
                                vm.listeFormulairesCci.push(value)
                            }
                        });

                    } else {
                        vm.listeFormulairesCci = campagne.formulairesCci;
                    }

                    vm.tableParams = new NgTableParams({
                        page: 1,
                        count: 1000,
                        sorting: {
                            'cci.region.libelle': 'asc',
                            'cci.nom': 'asc'
                        }
                    }, {
                        total: vm.listeFormulairesCci.length,
                        counts: [50, 100, 1000],
                        getData: vm.getData
                    });
                    //reset search fields
                    vm.complet = null;
                    vm.valide = null;
                    if (vm.userConnectedAuthority === 'ROLE_CCIR') {
                        vm.region = vm.regions[0];
                        vm.findCcisCorrespondantes(vm.region.id);
                    } else {
                        vm.region = null;
                        vm.findCcisCorrespondantes(null);
                    }
                    vm.cci = null;
                };*/
                function onErrorCampagne() {
                    AlertService.error("Un problème est survenu dans la récupération des détails de la campagne, merci de contacter votre administrateur");
                };
            }
        });

        function getData(params) {
            sessionStorage.formulairesCCISort = angular.toJson(createSortCriteria());
            //tri de la liste
            vm.formulairesCci = params.sorting() ? $filter('orderBy')(vm.listeFormulairesCci, params.orderBy()) : vm.listeFormulairesCci;
            //récuperation des éléments à afficher en fonction de la page
            vm.formulairesCci = vm.formulairesCci.slice((params.page() - 1) * params.count(), params.page() * params.count());
            return vm.formulairesCci;
        }

        // définition des fonctions
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        function search() {
            return FormulaireCciService.search({
                campagne: vm.campagneSelection !== null ? vm.campagneSelection.id : "-",
                region: vm.region !== null ? vm.region.id : "-",
                cci: vm.cci !== null ? vm.cci.id : "-",
                complet: vm.complet !== null ? vm.complet.role : "-",
                valide: vm.valide !== null ? vm.valide.role : "-"
            }, onSuccessSearch, onErrorSearch);
        }

        function onSuccessSearch(data) {
            var formCcis = []
            var ccis = data;
            if (vm.isEncours) {
                angular.forEach(ccis, function (value, key) {
                    if (value.cci.actif) {
                        formCcis.push(value)
                    }
                });

            } else {
                formCcis = ccis;
            }
            vm.listeFormulairesCci = formCcis;
            var sortCriteria = {
                'cci.region.libelle': 'asc',
                'cci.nom': 'asc'
            };
            if(sessionStorage.formulairesCCISort){
                sortCriteria = angular.fromJson(sessionStorage.formulairesCCISort);
            }
            vm.tableParams = new NgTableParams({
                page: 1,
                count: 1000,
                sorting: sortCriteria
            }, {
                total: vm.listeFormulairesCci.length,
                counts: [50, 100, 1000],
                getData: vm.getData
            });

            FormulaireCciService.sort(sortCriteria);
        }
        function onErrorSearch(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
        }

        function loadAllCampagnes() {

            return FormulaireCciService.getSimple({}, onSuccess, onError);

            function onSuccess(data, headers) {
                // Récupération des campagnes déjà triées de la plus recente à la plus ancienne au niveau du back
                vm.campagnes = data;
                vm.listFormCci = []
                //verifier s'il y a une campgane en cours
                vm.noCampagneInProgress();

                var selection = angular.fromJson(sessionStorage.formulairesCciCampagne);

                if (vm.campagnes.length > 0) {
                    //Clonage de vm.campagnes dans vm.campagnesLabels pour modifier le libelle de la campagne en cours dans le select 'Archives'
                    if (vm.hasCampagneInProgress === true) {

                        angular.forEach(vm.campagnes[0].formulairesCci, function (value, key) {
                            if (value.cci.actif) {
                                vm.listFormCci.push(value)
                            }
                        });
                        vm.campagnesLabels.push({
                            label: "Campagne en cours",
                            id: vm.campagnes[0].id,
                            nom: vm.campagnes[0].nom,
                            dateOuverture: vm.campagnes[0].dateOuverture,
                            dateFermeture: vm.campagnes[0].dateFermeture,
                            //formulairesCci: vm.listFormCci
                        });

                       if (!selection || selection.id != data.id) {

                            vm.campagneToSelectAtInit = vm.campagnesLabels[0];
                        }
                    }

                    //parcourrir la table, commencer avec le premier élément si pas de campagne en cours, sinon le deuxième
                    var j = (vm.hasCampagneInProgress === true ? 1 : 0);

                    for (j; j < vm.campagnes.length; j++) {

                        vm.campagnesLabels.push({
                            label: vm.campagnes[j].nom,
                            id: vm.campagnes[j].id,
                            nom: vm.campagnes[j].nom,
                            dateOuverture: vm.campagnes[j].dateOuverture,
                            dateFermeture: vm.campagnes[j].dateFermeture,
                            //formulairesCci: vm.campagnes[j].formulairesCci
                        });
                    }
                    if (selection) {
                        // On recherche la ligne correspondante parmis les campagnes
                        angular.forEach(vm.campagnesLabels, function (c, index) {
                            if (c.id === selection.id) {
                                vm.campagneToSelectAtInit = vm.campagnesLabels[index];
                                angular.forEach(c.formulairesCci, function (value, key) {
                                    if (value.cci.actif) {
                                        vm.listFormCci.push(value)
                                    }
                                });
                            }
                        });
                    }
                }
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllRegions() {
            Region.getRegionsByProfil({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.regions = data;
                if (vm.userConnectedAuthority === 'ROLE_CCIR') {
                    vm.region = vm.regions[0];
                    vm.findCcisCorrespondantes(vm.region.id);
                } else {
                    vm.findCcisCorrespondantes(null);
                }

                FormulaireCciService.filters(
                    function success(data) {

                        if (data.hasOwnProperty("region") && data.region != "-") {
                            vm.region = vm.regions.find(function (it) { return it.id == data.region });
                            vm.idRegionSelected = parseInt(data.region);
                            vm.findCcisCorrespondantes(vm.region.id);
                        }
                        if (data.hasOwnProperty("cci") && data.cci != "-") {
                            vm.cci = vm.ccis.find(function (it) { return it.id == data.cci });
                        }
                        if (data.hasOwnProperty("complet") && data.complet != "-") {
                            vm.complet = vm.completudes.find(function (it) { return it.role == data.complet });
                        }
                        if (data.hasOwnProperty("valide") && data.valide != "-") {
                            vm.valide = vm.validations.find(function (it) { return it.role == data.valide });
                        }
                        // On lance la recherche avec les éléments sélectionnés
                        vm.campagneSelection = vm.campagneToSelectAtInit;
                        /*FormulaireCciService.getSort(
                            function success(data) {
                                vm.search().$promise.then(function () {
                                    if (vm.tableParams != null) {
                                        vm.tableParams.sorting(data);
                                    }
                                });

                            },
                            function onError(error) {
                                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                            });*/

                    },
                    function error() {
                        AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                    }
                );

            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllCcis() {
            return CCI.getAllCCIS({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.ccis = data;
                vm.loadAllRegions();
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        // Fonction qui vérifie l'existence d'une campagne ouverte (en cours)
        function noCampagneInProgress() {
            if (vm.campagnes.length > 0) {
                if (vm.campagnes[0].dateOuverture != null) {
                    // Conversion des dates issues de la base en format compatible avec celles de javascript
                    vm.campagnes[0].dateOuverture = DateUtils.convertDateTimeFromServer(vm.campagnes[0].dateOuverture);
                    vm.campagnes[0].dateOuverture.setHours(0, 0, 0, 0);

                    if (vm.campagnes[0].dateFermeture != null) {
                        vm.campagnes[0].dateFermeture = DateUtils.convertDateTimeFromServer(vm.campagnes[0].dateFermeture);
                        vm.campagnes[0].dateFermeture.setHours(0, 0, 0, 0);
                    }

                    vm.currentDate.setHours(0, 0, 0, 0);

                    // S'il n'y a  pas de campagne en cours
                    if (!(vm.campagnes[0].dateOuverture <= vm.currentDate &&
                        (vm.campagnes[0].dateFermeture == null ||
                            (vm.campagnes[0].dateFermeture != null && vm.campagnes[0].dateFermeture >= vm.currentDate)))) {
                        //User connecté est forcément de type Admin car c'est pour lui qu'il ya des campagnes archivées
                        vm.hasCampagneInProgress = false;
                    }
                }
            }
            // Si la liste n'est pas >0 donc elle est nulle c'est-à-dire aucune campagne en cours.
            // De plus user connecté sera forcément de type CCIR car la liste des campagnes archivées n'est pas reçue dans ce cas donc pas de 'else'
            else {
                vm.hasCampagneInProgress = false;
            }
        }

        //vérification si profile CCIR et campagne fermée => pas d'affichage du header
        function afficherHead() {
            var afficher = true;
            if (vm.account.authorities == 'ROLE_CCIR' && !vm.hasCampagneInProgress) {
                afficher = false;
            }
            return afficher;
        }

        //vérification si profile ADMIN et campagne fermée => pas d'affichage du tableau sauf si selection de campagne archivée
        function afficherTableau() {
            var afficher = true;
            if (vm.account.authorities == 'ROLE_ADMIN') {
                if (!vm.hasCampagneInProgress) {
                    afficher = false;
                }
            }
            else {
                //vérification si profile CCIR et campagne fermée => pas d'affichage du tableau
                if (!vm.hasCampagneInProgress) {
                    afficher = false;
                }
            }
            return afficher;
        }

        function findCcisCorrespondantes(idRegionSelected) {
            vm.ccis_correspondantes = [];
            vm.ccis_correspondantes_actif = []
            if (idRegionSelected != null) {
                angular.forEach(vm.ccis, function (value, key) {
                    if (value.region != null && value.region.id == idRegionSelected) {
                        vm.ccis_correspondantes.push(value);
                    }
                });
                //dans le cas ou la liste contient qu'une seule CCI => selection par défaut
                if (vm.ccis_correspondantes.length == 1) {
                    vm.cci = vm.ccis_correspondantes[0];
                }
            } else {
                angular.forEach(vm.ccis, function (value, key) {
                    if (value.region === null) {
                        vm.ccis_correspondantes.push(value);
                    }
                });
            }

            if (vm.isEncours) {
                angular.forEach(vm.ccis_correspondantes, function (value, key) {
                    if (value.actif) {
                        vm.ccis_correspondantes_actif.push(value)
                    }
                });
            } else {
                vm.ccis_correspondantes_actif = vm.ccis_correspondantes;
            }
        }

        function afficherBoutonListeFormulaire(ligne) {
            var result = false;
            if (!ligne.formulaireCci.verifie) {
                if (vm.account.cci != null && vm.account.cci.id != ligne.formulaireCci.cci.id) {
                    result = true;
                }
            }
            return result;
        }

        function afficherBoutonMonBilan(ligne) {
            var result = false;
            if (!ligne.formulaireCci.verifie) {
                if (vm.account.cci != null && vm.account.cci.id == ligne.formulaireCci.cci.id) {
                    result = true;
                }
            }
            return result;
        }

        function sort() {
            FormulaireCciService.sort(createSortCriteria());
        }

        function createSortCriteria(){
            var criterias = {};
            var properties = ["cci.region.libelle","cci.nom","verifie","complet"]
            for (var index=0; index < properties.length; index++) {
                var propertyName = properties[index];
                if(getSort(propertyName)){
                    criterias[propertyName] = getSort(propertyName);
                }
            }
            return criterias;
        }

        function getSort(field) {
            if (vm.tableParams == null) {
                return null;
            }
            if (vm.tableParams.isSortBy(field, "asc")) {
                return "asc";
            } else if (vm.tableParams.isSortBy(field, "desc")) {
                return "desc";
            } else {
                return null;
            }
        }

    }
})();
