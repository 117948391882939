(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('MonBilanFormulaireController', MonBilanFormulaireController);

    MonBilanFormulaireController.$inject = ['$scope', '$state', 'Formulaire', 'AlertService', 'ParamFormulaire', 'Principal', '$stateParams', 'CCI', 'Campagne', 'MonBilanFormulaireService','DateUtils', 'AutreActivite'];

    function MonBilanFormulaireController($scope, $state, Formulaire, AlertService, ParamFormulaire, Principal, $stateParams, CCI, Campagne, MonBilanFormulaireService,DateUtils, AutreActivite) {
        // Init variables
        var vm = this;
        vm.paramFormulaires = [];
        vm.formulaires = [];
        vm.account = [];
        vm.liste = [];
        vm.autreActivitesDisabled = [];
        vm.formulaireChecked = false;
        vm.allFormulairesChecked = false;
        vm.hasCampagneInProgress = true;
        vm.disableInputAutreActivite = false;
        vm.help = false;
        vm.autreActivites = [];
        vm.autreActivite = null;
        vm.idFormulaireSelected = null;
        vm.cciSelected = null;
        vm.monBilanFormulaireCompleted = false;
        vm.disableInputAutreActiviteShown = false;
        vm.disableAddAutreActivite = false;
        vm.campagnes = [];
        var date = new Date();
        //Init functions
        vm.isFormulaireChecked = isFormulaireChecked;
        vm.isAllFormulaireChecked = isAllFormulaireChecked;
        vm.noCampagneInProgress = noCampagneInProgress;
        vm.hasACompletedFormulaire = hasACompletedFormulaire;
        vm.showHelp = showHelp;
        vm.addAutreActivite = addAutreActivite;
        vm.removeAutreActivite = removeAutreActivite;
        vm.modifyAutreActivite = modifyAutreActivite;
        vm.isFormulaireCompleted = isFormulaireCompleted;
        vm.getMonBilanFormulaires = getMonBilanFormulaires;
        vm.formulaireValid = formulaireValid;
        vm.activerDesactiver = activerDesactiver;
        var currentDate = new Date().toJSON().slice(0, 10);
        //Appel des méthodes lors du chargement de la page
        getAccount();
        loadAllParamFormulaires();
        getMonBilanFormulaires();

        function getMonBilanFormulaires() {
            MonBilanFormulaireService.getMonBilanFormulaire({}, onSuccess, onError);
            function onSuccess(data) {
                vm.liste = data;
                vm.campagnes = data;
                noCampagneInProgress();
                isAllFormulaireChecked();
                hasACompletedFormulaire();
                loadAllAutreActivites();
                date = new Date();
                currentDate = new Date().toJSON().slice(0, 10);
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.cciSelected = vm.account.cci;
            });
        }

        function loadAllParamFormulaires() {

            ParamFormulaire.query({}, onSuccess, onError);
            function onSuccess(data) {

                vm.paramFormulaires = data;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        //Methode pour afficher l'icône de vérification de chaque formulaire selon la valeur de l'attribut 'verifie'
        function isFormulaireChecked(paramFormulaire) {
            vm.formulaireChecked = false;
            angular.forEach(vm.liste, function (value, key) {
                if (value.type != null && paramFormulaire.type != null && value.type == paramFormulaire.type) {
                    vm.idFormulaireSelected = value.id;
                    if (value.verifie == true) {
                        vm.formulaireChecked = true;
                    }
                }
            });
            return vm.formulaireChecked;
        }

        //Methode pour afficher l'icône de vérification de chaque formulaire selon la valeur de l'attribut 'complet'
        function isFormulaireCompleted(paramFormulaire) {
            vm.monBilanFormulaireCompleted = false;
            angular.forEach(vm.liste, function (value, key) {
                if (value.type !== null && paramFormulaire.type !== null && value.type === paramFormulaire.type) {
                    vm.idFormulaireSelected = value.id;
                    if (value.complet === true) {
                        vm.monBilanFormulaireCompleted = true;
                    }
                }
            });
            return vm.monBilanFormulaireCompleted;
        }

        //Methode qui vérifie si le bilan (tous les formulaires) est validé par l'admin ou pas
        function isAllFormulaireChecked() {
            vm.allFormulairesChecked = false;
            if (vm.liste.length > 0) {
                vm.allFormulairesChecked = vm.hasCampagneInProgress;
                angular.forEach(vm.liste, function (value, key) {
                    if (!value.verifie && vm.hasCampagneInProgress) {
                        vm.allFormulairesChecked = false;
                    }
                });
            }
        }

        // Fonction qui vérifie s'il y a un formulaire déjà complet ou pas pour désactiver la possibilité d'ajouter d'autres activites
        function hasACompletedFormulaire() {
            vm.disableInputAutreActivite = false;
            angular.forEach(vm.liste, function (value, key) {
                if (value.complet) {
                    vm.disableInputAutreActivite = true;
                }
            });
        }

        //Fonction qui vérifie l'existence d'une campagne ouverte (en cours)
        function noCampagneInProgress() {
              vm.hasCampagneInProgress = false;
             if (vm.liste.length > 0 && vm.campagnes[0] && (vm.campagnes[0].campagne.dateOuverture <= currentDate || vm.campagnes[0].campagne.dateFermeture >= vm.currentDate)) {
                vm.hasCampagneInProgress = true;
             }
        }

        // Fonction pour l'infobulle
        function showHelp() {
            if (!vm.help) {
                vm.help = true;
            } else {
                vm.help = false;
            }
        }

        function addAutreActivite(){
            if(vm.autreActivite !== null && vm.autreActivites.length<5){
                if(vm.disableInputAutreActivite){
                    var warning = 'Attention, il existe un ou plusieurs formulaire(s) au statut complet. \n\n Confirmez-vous l\'ajout de l\'autre activité '+ vm.autreActivite + ' ?';
                    if (window.confirm(warning)) {
                        AutreActivite.save(vm.autreActivite, onSuccess, onError);
                    }
                } else{
                    AutreActivite.save(vm.autreActivite, onSuccess, onError);
                }
            } else if (vm.autreActivite !== null && vm.autreActivites.length === 5) {
                alert("Vous ne pouvez pas préciser plus de 5 activités.");
                vm.autreActivite = null;
            } else if (vm.autreActivite === null) {
                alert("Veuillez renseigner le nom de l'activité.");
            }
            function onSuccess(data) {
                loadAllAutreActivites();
                vm.autreActivite = null;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function removeAutreActivite(autreActivite) {
            if (autreActivite !== null) {
                if(vm.disableInputAutreActivite){
                    var warning = 'Attention, il existe un ou plusieurs formulaire(s) au statut complet. \n\n Confirmez-vous la suppression de l\'autre activité '+ autreActivite.nom + ' ?';
                    if (window.confirm(warning)) {
                        AutreActivite.delete({id: autreActivite.id}, onSuccess, onError);
                    }
                } else{
                    AutreActivite.delete({id: autreActivite.id}, onSuccess, onError);
                }
            }
            function onSuccess(data) {
                loadAllAutreActivites();
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }


        function modifyAutreActivite(autreActivite) {
            if (autreActivite === null || autreActivite.nom ==='') {
                alert("Veuillez renseigner le nom de l'activité.");
            } else {
                AutreActivite.update(autreActivite, onSuccess, onError);
            }
            function onSuccess(data) {
                loadAllAutreActivites();
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function onSuccessLoadAllAutreActivites(data) {
            vm.autreActivites = data;
            vm.disableAddAutreActivite = false;
            if(vm.disableInputAutreActivite){
                vm.disableAddAutreActivite = true;
            }
            else if (vm.autreActivites.length === 5) {
                vm.disableAddAutreActivite = true;
            }
            angular.forEach(vm.autreActivites, function (value, key) {
                vm.autreActivitesDisabled[key]= true;
            });


        }

        function onErrorLoadAllAutreActivites(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
        }

        function loadAllAutreActivites() {
            if (typeof vm.liste[0] !== 'undefined') {
                AutreActivite.getAutreActivitesByCciAndCampagne({
                    idCCI: vm.liste[0].cci.id,
                    idCampagne: vm.liste[0].campagne.id
                }, onSuccessLoadAllAutreActivites, onErrorLoadAllAutreActivites);  // Récupération des autresActivite pour cette CCI/CCIR et la campagne en cours
            }
        }

        function formulaireValid(type) {
            var message = 'Attention. Si vous souhaitez renseigner des activités dans « Autres activités » (exemple : pépinière, zone d’activité…), merci de le faire avant de commencer à saisir vos données dans les formulaires. Une fois que vous aurez validé la saisie d’un premier formulaire, vous ne pourrez plus renseigner ou modifier vos « Autres activités »';
            if(!vm.disableInputAutreActivite && !vm.disableInputAutreActiviteShown){
                //alert(message);
                vm.disableInputAutreActiviteShown = true;
                return false;
            }
        }

        function activerDesactiver(indice) {
            if(!vm.autreActivitesDisabled[indice]){
                loadAllAutreActivites();
            }
            vm.autreActivitesDisabled[indice]= !vm.autreActivitesDisabled[indice];
        }
    }
})();
