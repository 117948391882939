(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('TableauRepartitionAccTrajetAvecArretCtrl', TableauRepartitionAccTrajetAvecArretCtrl);

    TableauRepartitionAccTrajetAvecArretCtrl.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function TableauRepartitionAccTrajetAvecArretCtrl ($scope, $state, ParseLinks, AlertService) {
        var vm = this;

    }
})();
