(function() {
    'use strict';
    angular
        .module('bhsApp')
        .factory('Formulaire', Formulaire);

    Formulaire.$inject = ['$resource', 'DateUtils'];

    function Formulaire ($resource, DateUtils) {
        var resourceUrl =  'api/formulaires/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateSaisie = DateUtils.convertDateTimeFromServer(data.dateSaisie);
                        data.dateVerification = DateUtils.convertDateTimeFromServer(data.dateVerification);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getFormulairesWithDatas': {
                url: 'api/formulairesWithDatas/:id',
                method: 'GET',
                isArray: false
            },
            'getFormulaireWithDataByCCIAndCampagneAndType': {
                url: 'api/getFormulaireWithDataByCCIAndCampagneAndType/:idCCI/:idCampagne/:typeFormulaire',
                method: 'GET',
                isArray: false
            },
            'getOldCampagnesFormulairesByCCI':{
                url: 'api/oldCampagnesFormulairesByCCI/:idCCI/:idCampagne1/:idCampagne2/:type',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
