(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('CCIDetailController', CCIDetailController);

    CCIDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CCI', 'Formulaire', 'AutreActivite', 'Region'];

    function CCIDetailController($scope, $rootScope, $stateParams, entity, CCI, Formulaire, AutreActivite, Region) {
        var vm = this;

        vm.cCI = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bhsApp:cCIUpdate', function(event, result) {
            vm.cCI = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
