(function() {
    'use strict';

    angular
        .module('bhsApp', [
            'ngStorage',
            'ngSanitize',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ckeditor',
            'ngTable',
            'angularjs-dropdown-multiselect'

        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$state'];

    function run(stateHandler, translationHandler, $state) {
        stateHandler.initialize();
        translationHandler.initialize();
        $state.go('home');
    }
})();
