(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('PublicationBilansController', PublicationBilansController);

    //injection des dépendances
    PublicationBilansController.$inject = ['$scope', '$timeout', '$state', 'ExportBilansService', 'AlertService', 'CCI', 'Region', 'Campagne', 'Principal', '$window', 'BilanNational'];

    // déclaration du controleur
    function PublicationBilansController($scope, $timeout, $state, ExportBilansService, AlertService, CCI, Region, Campagne, Principal, $window, BilanNational) {
        // Init variables
        var vm = this;
        vm.campagnes = [];
        vm.bilan = null;
        vm.regions = [];
        vm.ccis = [];
        vm.campagneSelected = null;
        vm.bilansNational = [];
        vm.atLeastOneBilanNational = false;
        vm.authority = null;
        vm.errorSelectionCampagne = false;
        vm.errorNoFile = false;
        vm.errorFileType = false;
        vm.fileToUpload = null;

        // Init functions
        vm.loadAllCampagnes = loadAllCampagnes;
        vm.publierBilan = publierBilan;
        vm.loadAllBilansNational = loadAllBilansNational;
        vm.saveFile = saveFile;

        // Appels aux méthodes au chargement
        loadAllCampagnes();
        loadAllBilansNational();

        // Définition des fonctions
        Principal.hasAuthority("ROLE_CCIR")
            .then(function (result) {
                if (result) {
                    vm.authority = "ROLE_CCIR";
                }
            });

        function loadAllCampagnes() {
            Campagne.getAllCampagnes({}, onSuccess, onError);

            function onSuccess(data) {
                vm.campagnes = data;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllBilansNational() {
            BilanNational.getAllBilans(onSuccess, onError);

            function onSuccess(data) {
                vm.bilansNational = data;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function publierBilan() {
            //validation
            vm.errorSelectionCCI = false;
            vm.errorSelectionCampagne = false;
            // vérification de la selection d'un fichier
            if (vm.fileToUpload === null) {
                vm.errorNoFile = true;
            } else {
                vm.errorNoFile = false;
                //vérification de l'extension
                var fileExtension = vm.fileToUpload.name.substr(vm.fileToUpload.name.lastIndexOf('.') + 1);
                vm.errorFileType = fileExtension.toUpperCase() !== "XLSX";

                //vérification de la selection d'une campagne
                vm.errorSelectionCampagne = vm.campagneSelected === null;

                //appel WS
                if (!vm.errorSelectionCampagne && !vm.errorFileType) {
                    // campagne en cours
                    var campagneEnCours = true;
                    if(vm.campagneSelected.dateFermeture === null){
                        var message = ' La campagne est encore en cours ! Confirmez-vous la publication du bilan national ?';
                        if (window.confirm(message)) {
                            campagneEnCours = false;
                        }
                    } else {
                        campagneEnCours = false;
                    }

                    // vérification si déjà pour la campagne une publication est faite
                    var bilanNationalExistant = null;
                    angular.forEach(vm.bilansNational, function (bilan, key) {
                        if (bilan.campagne.id === vm.campagneSelected.id) {
                            bilanNationalExistant = bilan;
                        }
                    });
                    if(!campagneEnCours && bilanNationalExistant !== null){
                        var message = ' Ecraser le bilan national déjà existant pour cette campagne  ?';
                        if (window.confirm(message)) {
                            bilanNationalExistant = null;
                        }
                    }

                    if(!campagneEnCours && bilanNationalExistant === null){
                        BilanNational.updateData({idCampagne: vm.campagneSelected.id}, vm.fileToUpload, onPublierSuccess, onPublierError);
                    }
                }
            }
        }

        function onPublierSuccess() {
            loadAllBilansNational();
        }

        function onPublierError(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
        }

        function saveFile(bilanNational){
            BilanNational.getBilanNationalFile(bilanNational.id, onExportSuccess, onExportError);
        }

        function onExportSuccess (data) {
               var blob = data.response.blob;
               var fileName = data.response.fileName;

               ($window).saveAs(blob, fileName);
         }

         function onExportError () {
             AlertService.error("Un problème est survenu lors de l'export, merci de contacter votre administrateur");
         }
    }
})();
