(function() {
    'use strict';

    angular
        .module('bhsApp')
        .component('navBarComponent', {
            templateUrl : 'app/layouts/navbar/navbar.html',
            controller: NavbarController,
            controllerAs: 'vm'
        });

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService' ,'$rootScope', 'User', '$scope'];

    function NavbarController ($state, Auth, Principal, ProfileService, $rootScope, User, $scope) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = null;
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.save = save;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
            vm.account = account;
            });
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function save(cci){
            vm.account.cci = cci
            User.update(vm.account, onSaveSuccess, onSaveError);
        }

         function onSaveSuccess(result) {
                     vm.isSaving = false;
                }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
