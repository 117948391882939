(function() {
    'use strict';

    angular
        .module('bhsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('campagne', {
            parent: 'admin',
            url: '/campagne?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'bhsApp.campagne.home.title'
            },
            views: {
                'content@bhs': {
                    templateUrl: 'app/entities/campagne/campagnes.html',
                    controller: 'CampagneController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'dateOuverture,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('campagne');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('campagne.new', {
            parent: 'campagne',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/campagne/campagne-dialog.html',
                    controller: 'CampagneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nom: null,
                                dateOuverture: null,
                                dateFermeture: null,
                                id: null
                            };
                        },
                        list: ['Campagne', function(Campagne) {

                           return Campagne.getAllCampagnes().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('campagne', null, { reload: 'campagne' });
                }, function() {
                    $state.go('campagne');
                });
            }]
        })
        .state('campagne.edit', {
            parent: 'campagne',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/campagne/campagne-dialog.html',
                    controller: 'CampagneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Campagne', function(Campagne) {
                            return Campagne.get({id : $stateParams.id}).$promise;
                        }],
                        list: ['Campagne', function(Campagne) {

                            return Campagne.getAllCampagnes().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('campagne', null, { reload: 'campagne' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
