(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('tableauEffectifs31DecembreAnneeConsideree', tableauEffectifs31DecembreAnneeConsideree);

    tableauEffectifs31DecembreAnneeConsideree.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService'];

    function tableauEffectifs31DecembreAnneeConsideree ($scope, $state, ParseLinks, AlertService) {
        var vm = this;


    }
})();
