(function() {
    'use strict';

    angular
        .module('bhsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('mon-export-bilans', {
            parent: 'mon-bilan',
            url: '/mon-export-bilans',
            data: {
                authorities: ['ROLE_CCIR', 'ROLE_USER'],
                pageTitle: 'bhsApp.bilan.export.bilans.genererBilan'
            },
            views: {
                'content@bhs': {
                    templateUrl: 'app/bilan/cci/export/export-bilans.html',
                    controller: 'ExportBilansController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('exportBilans');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
