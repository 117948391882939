(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('BilanNationalDialogController', BilanNationalDialogController);

    BilanNationalDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'BilanNational', 'Campagne'];

    function BilanNationalDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, BilanNational, Campagne) {
        var vm = this;

        vm.bilanNational = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.campagnes = Campagne.query({filter: 'bilannational-is-null'});
        $q.all([vm.bilanNational.$promise, vm.campagnes.$promise]).then(function() {
            if (!vm.bilanNational.campagne || !vm.bilanNational.campagne.id) {
                return $q.reject();
            }
            return Campagne.get({id : vm.bilanNational.campagne.id}).$promise;
        }).then(function(campagne) {
            vm.campagnes.push(campagne);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.bilanNational.id !== null) {
                BilanNational.update(vm.bilanNational, onSaveSuccess, onSaveError);
            } else {
                BilanNational.save(vm.bilanNational, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bhsApp:bilanNationalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setFichierBilan = function ($file, bilanNational) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        bilanNational.fichierBilan = base64Data;
                        bilanNational.fichierBilanContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.datePublication = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
