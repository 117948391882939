(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', '$filter', 'Region', 'CCI', 'UserExport', '$window', 'NgTableParams'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, filter, Region, CCI, UserExport, $window, NgTableParams) {
        var vm = this;

        //Init variables
        vm.authorities = [
            { libelle: 'Administrateur', role: 'ROLE_ADMIN' },
            { libelle: 'CCIR', role: 'ROLE_CCIR' },
            { libelle: 'Contributeur', role: 'ROLE_USER' }
        ];
        vm.authoritiesRestricted = [{ libelle: 'Contributeur', role: 'ROLE_USER' }];
        vm.actifs = [
            { libelle: 'Non', role: '0' },
            { libelle: 'Oui', role: '1' }
        ];
        vm.actif = null;
        vm.regions = [];
        vm.ccis = [];
        vm.users = [];
        vm.listeUsers = [];

        vm.login = "";
        vm.email = "";
        vm.lastName = "";
        vm.firstName = "";
        vm.region = null;
        vm.authority = null;
        vm.userConnectedAuthority = null;
        vm.cci = null;
        vm.idRegionSelected = null;
        vm.idCciSelectionned = null;
        vm.ccis_correspondantes = [];
        vm.tableParams = null;

        vm.sorting = null;


        //Init functions
        vm.loadAllRegions = loadAllRegions;
        vm.loadAllCCIs = loadAllCCIs;
        vm.exportUsers = exportUsers;
        vm.findCcisCorrespondantes = findCcisCorrespondantes;
        vm.search = search;
        vm.getUsersData = getUsersData;
        vm.onSort = onSort;

        Principal.hasAuthority("ROLE_CCIR")
            .then(function (result) {
                if (result) {
                    vm.userConnectedAuthority = "ROLE_CCIR";
                }
            });

        function loadFilters() {
            return User.filters(
                function success(data) {
                    if (data.hasOwnProperty("login") && data.login != "-") {
                        vm.login = data.login;
                    }
                    if (data.hasOwnProperty("mail") && data.mail != "-") {
                        vm.email = data.mail;
                    }
                    if (data.hasOwnProperty("nom") && data.nom != "-") {
                        vm.lastName = data.nom;
                    }
                    if (data.hasOwnProperty("prenom") && data.prenom != "-") {
                        vm.firstName = data.prenom;
                    }
                    if (data.hasOwnProperty("region") && data.region != "-") {
                        vm.idRegionSelected = parseInt(data.region);
                    }
                    if (data.hasOwnProperty("profile") && data.profile != "-") {
                        vm.authority = vm.authorities.filter(function (it) { return it.role == data.profile })[0];
                    }
                    if (data.hasOwnProperty("cci") && data.cci != "-") {
                        vm.idCciSelectionned = parseInt(data.cci);
                    }
                    if (data.hasOwnProperty("actif") && data.actif != "-") {
                        vm.actif = vm.actifs.filter(function (it) { return it.role == data.actif })[0];
                    }
                    if (data.hasOwnProperty("tris")) {
                        vm.sorting = data.tris;
                    }
                },
                function error() {
                    AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                }
            );
        }

        loadFilters().$promise.then(function () {
            vm.loadAllCCIs().$promise.then(function () {
                vm.loadAllRegions().$promise.then(vm.search);
            })
        });


        function search() {
            User.search({
                login: vm.login !== "" ? vm.login : "-",
                email: vm.email !== "" ? vm.email : "-",
                lastName: vm.lastName !== "" ? vm.lastName : "-",
                firstName: vm.firstName !== "" ? vm.firstName : "-",
                authority: vm.authority !== null ? vm.authority.role : "-",
                region: vm.region !== null ? vm.region.id : "-",
                cci: vm.cci !== null ? vm.cci.id : "-",
                actif: vm.actif !== null ? vm.actif.role : "-"
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            //hide anonymous user from user management: it's a required user for Spring Security
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }
            vm.listeUsers = data;
            // création d'une variable cciSort sur les users => utilisée pour le tri
            angular.forEach(vm.listeUsers, function (value, key) {
                if (value.ccis != null && value.ccis.length > 0) {
                    // On choisi de trier sur la première de ccis si elle existe
                    var ccis = value.ccis.map(function (cci) {
                        return cci.nom.toUpperCase();
                    });
                    ccis.sort();
                    value.cciSort = ccis.reduce(function (str1, str2) {
                        return str1 + " " + str2;
                    }, "").substring(1);
                } else if (value.cci != null) {
                    // Tri sur cci sinon
                    value.cciSort = value.cci.nom;
                }
            });

            var sortFields = { login: 'asc' };
            if (sessionStorage.userManagementSort) {
                sortFields = angular.fromJson(sessionStorage.userManagementSort);
            }

            vm.tableParams = new NgTableParams({
                page: 1,
                count: 1000,
                sorting: sortFields
            }, {
                total: data.length,
                counts: [50, 100, 1000],
                getData: vm.getUsersData
            });


            //Paramétrage pour le filtre par profil car 'Contributeur' n'existe pas en base
            angular.forEach(vm.users, function (value, key) {
                if (value.authorities == 'ROLE_ADMIN') {
                    value.authorities = 'Administrateur';
                } if (value.authorities == 'ROLE_CCIR') {
                    value.authorities = 'CCIR';
                } if (value.authorities == 'ROLE_USER') {
                    value.authorities = 'Contributeur';
                }
            });
        }

        function onSort() {
            User.sorting(createSortCriteria());
        }

        function getSort(field) {
            if (vm.tableParams == null) {
                return null;
            }
            if (vm.tableParams.isSortBy(field, "asc")) {
                return "asc";
            } else if (vm.tableParams.isSortBy(field, "desc")) {
                return "desc";
            } else {
                return null;
            }
        }

        function loadAllCCIs() {
            return CCI.getAllCCIS({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.ccis = data;
                defaultCCI();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }

        }


        function loadAllRegions() {
            return Region.getRegionsByProfil({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.regions = data;
                if (vm.userConnectedAuthority === 'ROLE_CCIR') {
                    vm.region = vm.regions[0];
                    vm.findCcisCorrespondantes(vm.region.id);
                } else {
                    vm.findCcisCorrespondantes(null);
                }

                defaultRegion();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function onError(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
        }

        function findCcisCorrespondantes(idRegionSelected) {
            vm.ccis_correspondantes = [];
            if (idRegionSelected != null) {
                angular.forEach(vm.ccis, function (value, key) {
                    if (value.region != null && value.region.id == idRegionSelected) {
                        vm.ccis_correspondantes.push(value);
                    }
                });
                //dans le cas ou la liste contient qu'une seule CCI => selection par défaut
                if (vm.ccis_correspondantes.length == 1) {
                    vm.cci = vm.ccis_correspondantes[0];
                }
            } else {
                angular.forEach(vm.ccis, function (value, key) {
                    if (value.region === null) {
                        vm.ccis_correspondantes.push(value);
                    }
                });
            }
        }
        function exportUsers() {
            UserExport.export(onExportSuccess, onExportError);
        }

        function onExportSuccess(data) {
            var blob = data.response.blob;
            var fileName = data.response.fileName;

            ($window).saveAs(blob, fileName);
        }

        function onExportError() {
            AlertService.error("Un problème est survenu lors de l'export, merci de contacter votre administrateur");
        }

        function getUsersData(params) {
            sessionStorage.userManagementSort = angular.toJson(createSortCriteria());
            //tri de la liste
            vm.users = params.sorting() ? filter('orderBy')(vm.listeUsers, params.orderBy()) : vm.listeUsers;
            //récuperation des éléments à afficher en fonction de la page
            vm.users = vm.users.slice((params.page() - 1) * params.count(), params.page() * params.count());

            return vm.users;
        }
        function createSortCriteria(){
            var criterias = {};
            var properties = ["login","email","lastName","firstName","profil","cci.region.libelle","cciSort","activated"]
            for (var index=0; index < properties.length; index++) {
                var propertyName = properties[index];
                if(getSort(propertyName)){
                    criterias[propertyName] = getSort(propertyName);
                }
            }
            return criterias;
        }

        function defaultRegion() {
            if (vm.idRegionSelected != null) {
                vm.region = vm.regions.filter(function (it) { return it.id == vm.idRegionSelected })[0];
                if (vm.region != undefined) {
                    vm.findCcisCorrespondantes(vm.region.id);
                }
            } else {
                if (document.getElementById("defaultRegion") != null) {
                    document.getElementById("defaultRegion").selected = true;
                }
            }
        }

        function defaultCCI() {
            if (vm.idCciSelectionned != null) {
                vm.cci = vm.ccis.filter(function (it) { return it.id == vm.idCciSelectionned })[0];
            } else {
                if (document.getElementById("defaultCCI") != null) {
                    document.getElementById("defaultCCI").selected = true;
                }
            }
        }

    }
})();
