(function() {
    'use strict';

    angular
        .module('bhsApp')
        .controller('CampagneDialogController', CampagneDialogController);

    CampagneDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Campagne', 'list'];

    function CampagneDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Campagne, list) {
        var vm = this;
        vm.campagneOuverte = false;
        vm.campagneFermee = false;
        vm.campagne = entity;
        vm.campagne.mailIsSend = false;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.list=list;
        vm.errorValidationMessage = null;
        vm.errorValidation = false;
        vm.dateOptions2 = {
            minDate: new Date()
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            angular.element(document.querySelector('body')).addClass('saving');
            if (vm.campagne.id !== null) {
               Campagne.update(vm.campagne, onSaveSuccess, onSaveError);

            } else {
                Campagne.save(vm.campagne, onSaveSuccess, onSaveError);
                vm.campagne.mailIsSend = true;

            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bhsApp:campagneUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            angular.element(document.querySelector('body')).removeClass('saving');
        }

        function onSaveError () {
            vm.isSaving = false;
            angular.element(document.querySelector('body')).removeClass('saving');
        }

        vm.datePickerOpenStatus.dateOuverture = false;
        vm.datePickerOpenStatus.dateFermeture = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

       //onchange ne fonctionne pas, du coup on utilise un watcher sur la date d'ouverture
        $scope.$watch("vm.campagne.dateOuverture", function(newValue, oldValue) {
            vm.campagneOuverte = false;
            vm.errorValidation = false;

            if(vm.campagne.dateOuverture !== null) {
                var now = new Date();
                // sort
                vm.list.sort(compareCampagneDateFermeture);
                // take latest
                var latest_campagne = vm.list.length>0 ? vm.list[vm.list.length-1] : null;
                //creation
                if(vm.campagne.id === null){
                    //date d'ouverture doit être inférieure à la date de fermeture saisie pour cette même campagne
                    if(vm.campagne.dateFermeture!== null && (new Date(vm.campagne.dateOuverture)) > (new Date(vm.campagne.dateFermeture))){
                        vm.errorValidation = true;
                        vm.errorValidationMessage="La date d'ouverture doit être inférieure à la date de fermeture";
                    }
                    //dans le cas ou une campagne plus récente est créée
                    if(latest_campagne !== null && latest_campagne.dateFermeture !== null && (new Date(vm.campagne.dateOuverture)) <= (new Date(latest_campagne.dateFermeture))) {
                            vm.errorValidation = true;
                            vm.errorValidationMessage= "La date d'ouverture doit être supérieure à la date de fermeture de la campagne " + latest_campagne.nom;

                    }
                }

                //modification
                else{
                    //la campagne la plus récente ou celle en cours de modif a déjà commencée
                    if((new Date(vm.campagne.dateOuverture)) <= now){
                        vm.campagneOuverte = true;
                    }
                    //de la campagne la plus récente
                    if(vm.campagne.id === latest_campagne.id){
                        //date d'ouverture doit être inférieure à la date de fermeture saisie pour cette même campagne
                        if( (new Date(vm.campagne.dateOuverture)) > (new Date(vm.campagne.dateFermeture))){
                            if( vm.campagne.dateFermeture !== null ){
                            vm.errorValidation = true;
                            vm.errorValidationMessage="La date d'ouverture doit être inférieure à la date de femreture";
                            }
                        }
                    }
                }
            }
        });




        //onchange ne fonctionne pas, du coup on utilise un watcher sur la date de fermeture
        $scope.$watch("vm.campagne.dateFermeture", function(newValue, oldValue) {
           vm.campagneFermee = false;
           vm.errorValidation = false;
           if(vm.campagne.dateFermeture !== null) {
               var now = new Date();
               // sort
               vm.list.sort(compareCampagneDateOuverture);

               // take latest
               var latest_campagne = vm.list.length>0 ? vm.list[vm.list.length-1] : null;
               //creation
               if(vm.campagne.id === null){
                    //date de fermeture doit être supérieure à la date d’ouverture saisie pour cette même campagne
                    if((new Date(vm.campagne.dateFermeture)) <= (new Date(vm.campagne.dateOuverture))){
                        vm.errorValidation = true;
                        vm.errorValidationMessage="La date de fermeture doit être supérieure à la date d’ouverture";
                    }
                    //dans le cas ou une campagne plus récente est créée
                    if(latest_campagne !== null && (new Date(latest_campagne.dateOuverture)) > now){
                        if((new Date(vm.campagne.dateFermeture)) >(new Date(latest_campagne.dateOuverture))) {
                            vm.errorValidation = true;
                            vm.errorValidationMessage= "La date de fermeture doit être inférieure à la date d’ouverture de la campagne " + latest_campagne.nom;
                        }
                    }
               }

               //modification
               else{
                //de la campagne la plus récente
                if(vm.campagne.id === latest_campagne.id){
                    if((new Date(vm.campagne.dateFermeture)) <= (new Date(vm.campagne.dateOuverture))) {
                        vm.errorValidation = true;
                        vm.errorValidationMessage = "La date de fermeture doit être supérieure à la date d’ouverture";
                    }
                }
                //d'une autre
                else {
                    //la campagne la plus récente a déjà commencée
                    if((new Date(latest_campagne.dateOuverture)) <= now){
                        vm.campagneFermee = true;
                    }
                    //pas encore commencée
                    else {
                        if((new Date(vm.campagne.dateFermeture)) > (new Date(latest_campagne.dateOuverture))) {
                            vm.errorValidation = true;
                            vm.errorValidationMessage = "La date de fermeture doit être inférieure à la date d’ouverture de la campagne " + latest_campagne.nom;
                        }
                    }

                }
               }
           }
        });

        function compareCampagneDateOuverture(a,b) {
          if ((new Date(a.dateOuverture)) < (new Date(b.dateOuverture)))
            return -1;
          if ((new Date(a.dateOuverture)) > (new Date(b.dateOuverture)))
            return 1;
          return 0;
        }

        function compareCampagneDateFermeture(a,b) {
          if(b.dateFermeture === null)
            return -1;
          if(a.dateFermeture === null)
            return 1;
          if ((new Date(a.dateFermeture)) < (new Date(b.dateFermeture)))
            return -1;
          if ((new Date(a.dateFermeture)) > (new Date(b.dateFermeture)))
            return 1;
          return 0;
        }
    }
})();
