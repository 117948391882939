(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('LoginPageController', LoginPageController);

    LoginPageController.$inject = ['$window', '$rootScope', 'Principal', '$timeout', 'Auth', '$state', 'SsoService'];

    function LoginPageController($window, $rootScope, Principal, $timeout, Auth, $state, SsoService) {
        var vm = this;

        vm.authenticationError = false;
        vm.helpLogin = false;
        vm.inputsRequiredError = false;
        vm.cciconnectFail = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.rememberMe = true;
        vm.showHelp = showHelp;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.cciConnectAuthorized = cciConnectAuthorized;
        vm.authenticatedByCode = false;
        vm.bhsLogin = false;
        vm.bhsSwitchLogin = bhsSwitchLogin;

        $timeout(function () { angular.element('#username').focus(); });

        init();

        function init() {
            getAccount();

            //Au retour de la connexion à CCI Connect
            if (getUrlParameter('code') !== '') {
                vm.authenticatedByCode = true;
                var _code = getUrlParameter('code');
                loginViaEmail(_code);
            };
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function login(event) {
            vm.helpLogin = false;
            if ((vm.password == null || vm.password === "") && (vm.username == null || vm.username === "")) {
                vm.inputsRequiredError = true;
                vm.authenticationError = false;
            } else {
                event.preventDefault();
                return Auth.login({
                    username: vm.username,
                    password: vm.password,
                    rememberMe: vm.rememberMe
                }).then(function () {
                    vm.authenticationError = false;
                    vm.inputsRequiredError = false;
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('home');
                    }

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                    else {
                        $state.go('home');
                    }
                }).catch(function () {
                    vm.authenticationError = true;
                    vm.inputsRequiredError = false;
                });
            }

        }

        function getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        };

        /********************************************
        *******Authentification par CCI Connect******
        *********************************************/
        //Déclenchement par click
        function cciConnectAuthorized(event) {
            return SsoService.authorized()
                .then(function (response) {
                    $window.location.href = response.data;
                });
        }

        function loginViaEmail(code) {
            return SsoService.loginViaEmail(code).
                then(function () {
                    $window.location.href = '/';
                }).catch(function () {
                    vm.cciconnectFail = true;
                    vm.authenticatedByCode = false;
                });
        };
        /********************************************
        *********************************************/

        function requestResetPassword() {
            $state.go('requestReset');
        }

        function showHelp() {
            vm.authenticationError = false;
            vm.inputsRequiredError = false;
            vm.helpLogin = !vm.helpLogin
        }

        function bhsSwitchLogin() {
            vm.bhsLogin = true;
        }
    }
})();