(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('EditionTextController', EditionTextController);

    EditionTextController.$inject = ['$scope', '$state', 'EditionTextService', 'AlertService'];

    var labels = [
        {"textKey": "accueil/admin", "label": "Administrateur"},
        {"textKey": "accueil/cci", "label": "CCIR"},
        {"textKey": "accueil/contributeur", "label": "Contributeur"}
    ];

    function EditionTextController($scope, $state, EditionTextService, AlertService) {


        var vm = this;
        vm.roles = labels.map(function(it) { return it.label; });
        vm.roleSelectionne = vm.roles[0];
        vm.paramAccueilText = "";
        vm.isSaving = false;

        // Editor options.
        $scope.options = {
          language: 'fr',
          allowedContent: true,
          entities: true,
          height: 300,
          removePlugins: 'scayt, wsc'
        };

        function isRoleSelectionne(it) {
            return it.label == vm.roleSelectionne;
        }

        $scope.$watch('vm.roleSelectionne', function() {
            if (vm.roleSelectionne == null) {
                return;
            }

            var label = labels.filter(isRoleSelectionne)[0];

            EditionTextService.getOneEditionText({"textKey": label.textKey}, onSuccess, onError);

            function onSuccess(data) {
                vm.paramAccueilText = data.value;
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        });

        vm.save = function save () {
            vm.isSaving = true;

            var label = labels.filter(isRoleSelectionne)[0];

            EditionTextService.update({"textKey": label.textKey, "value": vm.paramAccueilText}, onSuccess, onError);
            function onSuccess(data) {
                vm.isSaving = false;
            }
            function onError(error) {
                vm.isSaving = false;
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }

        }

    }

})();
