(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireInitiativeCtrl', FormulaireInitiativeCtrl);

    FormulaireInitiativeCtrl.$inject = ['$scope', '$state', '$stateParams', 'Formulaire', 'AlertService', 'AutreActivite', 'ParamFormulaire', 'Campagne', 'entityCCI', 'Principal'];

    function FormulaireInitiativeCtrl($scope, $state, $stateParams , Formulaire, AlertService, AutreActivite, ParamFormulaire, Campagne, entityCCI, Principal) {
        var vm = this;

        //Init functions
        vm.printFormulaire = printFormulaire;
        vm.sauvegarder = sauvegarder;
        vm.showHelp = showHelp;
        vm.confirmerRetour = confirmerRetour;
        vm.loadFormulaire = loadFormulaire;

        // Init variables
        vm.campagneSelected = null;  // La campagne sélectionnée
        vm.idCampagneSelected = $stateParams.idCampagneSelected ;
        vm.autreActivites = [];
        vm.paramFormulaires = [];
        vm.paramTableaux = [];
        vm.formulaire = null;
        vm.cci = entityCCI;
        vm.colspanValue = 6;  //les 4 colonnes fixes + celles du nom de la campagne et du total
        vm.dataByColonne = [{1: {}}, {2: {}}, {3: {}}, {4: {}}, {5: {}}, {6: {}}, {7: {}}, {8: {}}, {9: {}}];
        vm.helpMessage = false;
        vm.saveMessage = false;
        vm.campagnes = [];
        vm.oldCampagnesFormulaires = [];
        vm.isAdmin = false;

        // Call functions when page load
        loadFormulaire();
        loadAllParamFormulaires();
        getAccount();

        function loadFormulaire(){
             Formulaire.getFormulairesWithDatas({id: $stateParams.id}, onSuccess, onError);
             function onSuccess(data){
                 vm.formulaire = data;
                 getAllCampagnes();
             }
             function onError(error) {
                 AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
             }
         }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

        function getAllCampagnes() {
            Campagne.getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires({idCampagneSelected: vm.idCampagneSelected}, onSuccess, onError);
            function onSuccess(data) {
                vm.campagneSelected = data[0];
                vm.campagnes = data.reverse();
                loadAllAutreActivites();
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllParamFormulaires() {
            ParamFormulaire.loadAll({}, onSuccess, onError);

            function onSuccess(data) {
                vm.paramFormulaires = data;
                if (vm.paramFormulaires[10].descriptif) {
                    vm.paramFormulaires[10].descriptif = vm.paramFormulaires[10].descriptif.replace('![CDATA[', '').replace(']]', '');
                }
                vm.paramTableaux = vm.paramFormulaires[10].paramTableaus.sort(function (a, b) {
                    return a.ordre - b.ordre;
                });  // Pas nécessaire pour les formulaires avec un seul tableau
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllAutreActivites() {
            AutreActivite.getAutreActivitesByCciAndCampagne({
                idCCI: vm.cci.id,
                idCampagne: vm.campagneSelected.id
            }, onSuccess, onError);
            function onSuccess(data) {
                angular.forEach(data, function (value, key) {
                    if (vm.campagnes[0] !== null) {
                        if (value.campagne.id === vm.campagnes[0].id && value.cci.id === vm.cci.id) {
                            vm.autreActivites.push(value);
                        }
                    }
                    console.log("autre Activité: " + value.nom);
                });
                vm.colspanValue = vm.colspanValue + vm.autreActivites.length;

                // Récupération des données des inputs des tableaux
                var listeData = vm.formulaire.data.sort(function (a, b) {
                    return a.colonne - b.colonne;
                });

                // Tri par colonne
                angular.forEach(listeData, function (valueData, keyData) {
                    if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                        vm.dataByColonne[valueData.colonne - 1] = valueData;
                    } else if (valueData.autreActivite !== null) {
                        var found = false;
                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData.autreActivite.id && !found && vm.dataByColonne[i + 4] !== {}) {
                                vm.dataByColonne[i + 4] = valueData;
                                found = true;
                            }
                        }
                    }
                });
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function printFormulaire() {
            window.print();
        }

        function sauvegarder() {
            if (vm.dataByColonne[0].initiatives == null || vm.dataByColonne[0].initiatives == '') {
                vm.dataByColonne[0].initiatives = "Néant";
            }
            Formulaire.update(vm.formulaire, onSuccess, onError);
            function onSuccess(data) {
                vm.saveMessage = true;
                loadFormulaire();
                window.scrollTo(0, 0);
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                vm.saveMessage = false;
            }
        }

        function showHelp() {
            vm.helpMessage = !vm.helpMessage;
        }

        function confirmerRetour() {
            getAccount();
            var message = 'Les informations non sauvegardées seront perdues.';
            if (window.confirm(message)) {
                if (vm.isAdmin) {
                    $state.go('list-formulaires-cci', {id: vm.cci.id, idCampagne: vm.campagneSelected.id});
                }
                //si la cci du formulaire en question est differente de celle du user connecté
                else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id !== vm.account.cci.id) {
                    $state.go('list-formulaires-cci', {id: vm.cci.id, idCampagne: vm.campagneSelected.id});
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id === vm.account.cci.id) {
                    $state.go('mon-bilan-formulaire');
                } else {
                    $state.go('mon-bilan-formulaire');
                }
            }
        }
    }
})();
