(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$rootScope', 'Principal', 'EditionTextService'];

    var defaultMessage = "<br/><br/>Bonjour, <br/><br/><p>Nous vous invitons à saisir les indicateurs du bilan annuel "
        + "santé sécurité et conditions de travail de l’année passée. Cette saisie peut s’effectuer de manière discontinue. "
        + "Nous vous conseillons toutefois qu’elle soit faite par une seule personne à la fois. Les formulaires de saisie "
        + "sont imprimables de manière à préparer la saisie en ligne.</p>"

    function HomeController( $rootScope, Principal, EditionTextService) {
        var vm = this;

        vm.cciconnectFail = false;
        vm.credentials = {};
        vm.account = null;
        vm.isAuthenticated = null;
        vm.authenticatedByCode = false;

        vm.textAdmin = defaultMessage;
        vm.textCCI = defaultMessage;
        vm.textContributeur = defaultMessage;

        EditionTextService.getAllEditionText(onSuccess, onError);

        function onSuccess(data) {
            data.forEach(function(it) {
                if (it.textKey == "accueil/admin") {
                    vm.textAdmin = it.value;
                } else if (it.textKey == "accueil/cci") {
                    vm.textCCI = it.value;
                } else if(it.textKey == "accueil/contributeur") {
                    vm.textContributeur = it.value;
                }
            });
        }
        function onError(error) {
            AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
    }
})();
