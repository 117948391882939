(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('FormulaireEffectifsCtrl', FormulaireEffectifsCtrl);

    FormulaireEffectifsCtrl.$inject = ['$scope', '$state', '$stateParams', 'Formulaire', 'AlertService', 'AutreActivite', 'ParamFormulaire', 'Campagne', 'entityCCI', 'Principal', 'entityCampagne'];

    function FormulaireEffectifsCtrl($scope, $state, $stateParams, Formulaire, AlertService, AutreActivite, ParamFormulaire, Campagne, entityCCI, Principal, entityCampagne) {
        var vm = this;

        //Init functions
        vm.printFormulaire = printFormulaire;
        vm.sauvegarder = sauvegarder;
        vm.showHelp = showHelp;
        vm.confirmerRetour = confirmerRetour;
        vm.loadFormulaire = loadFormulaire;

        // Init variables
        vm.campagnes = entityCampagne; //Les campagnes archivées + celle sélectionnée ( ou en cours)
        vm.campagneSelected = null;  // La campagne sélectionnée
        vm.idCampagneSelected = $stateParams.idCampagneSelected;
        vm.autreActivites = [];
        vm.paramFormulaires = [];
        vm.paramTableaux = [];
        vm.formulaire = null;
        vm.cci = entityCCI;
        vm.colspanValue = 6;  //les 4 colonnes fixes + celles du nom de la campagne et du total
        vm.dataByColonne = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF1ByColonne = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.dataCF2ByColonne = [{ 1: {} }, { 2: {} }, { 3: {} }, { 4: {} }, { 5: {} }, { 6: {} }, { 7: {} }, { 8: {} }, { 9: {} }];
        vm.helpMessage = false;
        vm.saveMessage = false;
        vm.campagnes = [];
        vm.oldCampagnesFormulaires = [];
        vm.campagneFermee1 = false;
        vm.campagneFermee2 = false;
        vm.isAdmin = false;
        vm.account = null;

        vm.totalHommeCampagneFermee2 = 0;
        vm.totalFemmeCampagneFermee2 = 0;
        vm.totalCampagneFermee2 = 0;
        vm.totalFemmeCampagneFermee1 = 0;
        vm.totalHommeCampagneFermee1 = 0;
        vm.totalCampagneFermee1 = 0;




        //Call functions when page load
        loadFormulaire();
        loadAllParamFormulaires();
        getAccount();

        function loadFormulaire() {
            Formulaire.getFormulairesWithDatas({ id: $stateParams.id }, onSuccess, onError);
            function onSuccess(data) {
                vm.formulaire = data;
                getCampagnes();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

        function getCampagnes() {
            Campagne.getCampagneSelectedAndTheTwoPreviousWithoutTheirFormulaires({ idCampagneSelected: vm.idCampagneSelected }, onSuccess, onError);
            function onSuccess(data) {
                vm.campagneSelected = data[0];
                vm.campagnes = data;
                loadAllAutreActivites();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllParamFormulaires() {
            ParamFormulaire.loadAll({}, onSuccess, onError);
            function onSuccess(data) {
                vm.paramFormulaires = data;
                if (vm.paramFormulaires[0].descriptif) {
                    vm.paramFormulaires[0].descriptif = vm.paramFormulaires[0].descriptif.replace('![CDATA[', '').replace(']]', '');
                }
                vm.paramTableaux = vm.paramFormulaires[0].paramTableaus.sort(function (a, b) { return a.ordre - b.ordre; });
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace('![CDATA[', '');
                vm.paramTableaux[0].descriptif = vm.paramTableaux[0].descriptif.replace(']]', '');
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function loadAllAutreActivites() {
            AutreActivite.getAutreActivitesByCciAndCampagne({ idCCI: vm.cci.id, idCampagne: vm.campagneSelected.id }, onSuccess, onError);
            function onSuccess(data) {
                vm.autreActivites = [];
                angular.forEach(data, function (value, key) {
                    if (vm.campagnes[0] !== null) {
                        if (value.campagne.id === vm.campagnes[0].id && value.cci.id === vm.cci.id) {
                            vm.autreActivites.push(value);
                        }
                    }
                });
                vm.colspanValue = vm.colspanValue + vm.autreActivites.length;
                //Récupération des données des inputs des tableaux
                var listeData = vm.formulaire.data.sort(function (a, b) { return a.colonne - b.colonne; });
                angular.forEach(listeData, function (valueData, keyData) {
                    if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                        vm.dataByColonne[valueData.colonne - 1] = valueData;
                    } else if (valueData.autreActivite !== null) {
                        var found = false;
                        for (var i = 0; i < vm.autreActivites.length; i++) {
                            if (vm.autreActivites[i].id === valueData.autreActivite.id && !found && vm.dataByColonne[i + 4] !== {}) {
                                vm.dataByColonne[i + 4] = valueData;
                                found = true;
                            }
                        }
                    }
                });
                loadOldCampagnesFormulairesByCCI();
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function printFormulaire() {
            window.print();
        }




        function sauvegarder() {
            var warning = 'Attention, tous les champs laissés à vide sont considérés comme étant à 0';
            if (!vm.formulaire.complet) {
                if (window.confirm(warning)) {
                    Formulaire.update(vm.formulaire, onSuccess, onError);
                }
            } else {
                Formulaire.update(vm.formulaire, onSuccess, onError);
            }
            function onSuccess(data) {
                vm.saveMessage = true;
                loadFormulaire();
                window.scrollTo(0, 0);
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
                vm.saveMessage = false;
            }
        }

        function loadOldCampagnesFormulairesByCCI() {
            var idCampagne1 = -1;
            var idCampagne2 = -1;
            if (vm.campagnes.length > 2) {
                idCampagne2 = vm.campagnes[2].id;
            }
            if (vm.campagnes.length > 1) {
                idCampagne1 = vm.campagnes[1].id;
            }
            Formulaire.getOldCampagnesFormulairesByCCI({ idCCI: vm.cci.id, idCampagne1: idCampagne1, idCampagne2: idCampagne2, type: 'effectifs' }, onSuccess, onError);
            function onSuccess(data) {
                vm.oldCampagnesFormulaires = data;
                vm.totalHommeCampagneFermee2 = 0;
                vm.totalFemmeCampagneFermee2 = 0;
                vm.totalCampagneFermee2 = 0;
                vm.totalHommeCampagneFermee1 = 0;
                vm.totalFemmeCampagneFermee1 = 0;
                vm.totalCampagneFermee1 = 0;
                if (data !== null) {
                    if (data.length > 1) {
                        var listeData = vm.oldCampagnesFormulaires[1].data.sort(function (a, b) { return a.colonne - b.colonne; }); //Tri par colonne
                        angular.forEach(listeData, function (valueData, keyData) {
                            if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                                vm.dataCF2ByColonne[valueData.colonne - 1] = valueData;
                            } else if (valueData.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataCF2ByColonne[i + 4] !== {}) {
                                        vm.dataCF2ByColonne[i + 4] = valueData;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalHommeCampagneFermee2 += valueData.effectifHomme;
                            vm.totalFemmeCampagneFermee2 += valueData.effectifFemme;
                            vm.totalCampagneFermee2 += valueData.effectifHomme + valueData.effectifFemme;

                        });
                    }
                    if (data.length > 0) {
                        var listeData = vm.oldCampagnesFormulaires[0].data.sort(function (a, b) { return a.colonne - b.colonne; }); //Tri par colonne
                        angular.forEach(listeData, function (valueData, keyData) {
                            if (valueData.colonne !== null && valueData.colonne < 5 && valueData.colonne > 0) {
                                vm.dataCF1ByColonne[valueData.colonne - 1] = valueData;
                            } else if (valueData.autreActivite !== null) {
                                //traitement des autres activités
                                var found = false;
                                for (var i = 0; i < vm.autreActivites.length; i++) {
                                    if (vm.autreActivites[i].nom === valueData.autreActivite.nom && !found && vm.dataCF1ByColonne[i + 4] !== {}) {
                                        vm.dataCF1ByColonne[i + 4] = valueData;
                                        found = true;
                                    }
                                }
                            }
                            vm.totalHommeCampagneFermee1 += valueData.effectifHomme;
                            vm.totalFemmeCampagneFermee1 += valueData.effectifFemme;
                            vm.totalCampagneFermee1 += valueData.effectifHomme + valueData.effectifFemme;

                        });
                    }
                }
            }
            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        function showHelp() {
            if (!vm.helpMessage) {
                vm.helpMessage = true;
            } else {
                vm.helpMessage = false;
            }
        }

        function confirmerRetour() {
            getAccount();
            var message = 'Les informations non sauvegardées seront perdues.';
            if (window.confirm(message)) {
                if (vm.isAdmin) {
                    $state.go('list-formulaires-cci', { id: vm.cci.id, idCampagne: vm.campagneSelected.id });
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id !== vm.account.cci.id) {  //si la cci du formulaire en question est differente de celle du user connecté
                    $state.go('list-formulaires-cci', { id: vm.cci.id, idCampagne: vm.campagneSelected.id });
                } else if (Principal.hasAuthority("ROLE_CCIR") && vm.cci.id === vm.account.cci.id) {
                    $state.go('mon-bilan-formulaire');
                } else {
                    $state.go('mon-bilan-formulaire');
                }
            }
        }
    }
})();
