(function() {
    'use strict';

    angular
        .module('bhsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('edition-text', {
            parent: "entity",
            url: "/edit-text",
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'bhsApp.paramFormulaire.home.title'
            },
            views: {
                'content@bhs': {
                    templateUrl: 'app/entities/edition-text/edition-text.html',
                    controller: 'EditionTextController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('editText');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
