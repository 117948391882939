(function () {
    'use strict';
    angular
        .module('bhsApp')
        .factory('ExportBilansService', ExportBilansService);

    ExportBilansService.$inject = ['$resource'];

    function ExportBilansService($resource) {
        var resourceUrl = 'api/export';

        return $resource(resourceUrl, {}, {
            'genererVerbatim': {
                url: 'api/export/verbatim',
                method: 'POST',
                cache: false,
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var zip = null;
                    if (data) {
                        zip = new Blob([data], {
                            type: 'text/plain'
                        });
                    }
                    var fileName = getFileNameFromHeader(headers('content-disposition'));
                    var result = {
                        blob: zip,
                        fileName: fileName
                    };
                    return {
                        response: result
                    };
                }
            },
            'genererDepenses': {
                url: 'api/export/depenses',
                method: 'POST',
                cache: false,
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var zip = null;
                    if (data) {
                        zip = new Blob([data], {
                            type: 'application/vnd.ms-excel'
                        });
                    }
                    var fileName = getFileNameFromHeader(headers('content-disposition'));
                    var result = {
                        blob: zip,
                        fileName: fileName
                    };
                    return {
                        response: result
                    };
                }
            },
            'genererBilanAnnuel': {
                url: 'api/export/bilan/annuel',
                method: 'POST',
                cache: false,
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var zip = null;
                    if (data) {
                        zip = new Blob([data], {
                            type: '/vnd.ms-excel'
                        });
                    }
                    var fileName = getFileNameFromHeader(headers('content-disposition'));
                    var result = {
                        blob: zip,
                        fileName: fileName
                    };
                    return {
                        response: result
                    };
                }
            },
            'genererBilanRegional': {
                url: 'api/export/bilan/regional',
                method: 'POST',
                cache: false,
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var zip = null;
                    if (data) {
                        zip = new Blob([data], {
                            type: '/vnd.ms-excel'
                        });
                    }
                    var fileName = getFileNameFromHeader(headers('content-disposition'));
                    var result = {
                        blob: zip,
                        fileName: fileName
                    };
                    return {
                        response: result
                    };
                }
            },
            'genererBilanCCI': {
                url: 'api/export/bilan/cci',
                method: 'POST',
                cache: false,
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var zip = null;
                    if (data) {
                        zip = new Blob([data], {
                            type: '/vnd.ms-excel'
                        });
                    }
                    var fileName = getFileNameFromHeader(headers('content-disposition'));
                    var result = {
                        blob: zip,
                        fileName: fileName
                    };
                    return {
                        response: result
                    };
                }
            }

        });
    }

    function getFileNameFromHeader(header) {
        if (!header) return null;
        var result = header.split(";")[1].trim().split("=")[1];
        return result.replace(/"/g, '');
    }
})();
