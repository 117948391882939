(function () {
    'use strict';

    angular
        .module('bhsApp')
        .controller('ListFormulairesCciController', ListFormulairesCciController);

    ListFormulairesCciController.$inject = ['$scope', '$state', 'Formulaire', 'AlertService', 'ParamFormulaire', 'Principal', '$stateParams', 'CCI', 'entity', 'Campagne', 'entityCampagne', 'FormulairesCciCampagneService', 'DateUtils'];

    function ListFormulairesCciController($scope, $state, Formulaire, AlertService, ParamFormulaire, Principal, $stateParams, CCI, entity, Campagne, entityCampagne, FormulairesCciCampagneService, DateUtils) {
        //Init variables
        var vm = this;
        vm.paramFormulaires = [];
        vm.formulaires = [];
        vm.account = [];
        vm.cciSelected = entity;
        vm.campagneSelected = entityCampagne;
        vm.allFormulairesChecked = false;
        //Liste des formulaires pour la cci et la campagne sélectionnées
        vm.formulairesCci = null;
        vm.hasCampagneInProgress = true;
        vm.currentDate = new Date();
        vm.campagneOpened = false;
        vm.allFormulairesCompleted = true;
        vm.idFormulaireSelected = null;

        //Init functions
        vm.manageValidation = manageValidation;
        vm.noCampagneInProgress = noCampagneInProgress;
        vm.isAllFormulaireChecked = isAllFormulaireChecked;
        vm.isFormulaireCompleted = isFormulaireCompleted;
        vm.isAllFormulaireCompleted = isAllFormulaireCompleted;
        vm.loadFormulairesCci = loadFormulairesCci;

        //Appel des méthodes lors du chargement de la page
        getAccount();
        loadFormulairesCci();


        function loadFormulairesCci() {
            FormulairesCciCampagneService.getAllByCampagneIdAndCciId({idCCI: $stateParams.id, idCampagne : $stateParams.idCampagne}, onLoadFormulairesCciSuccess, onLoadFormulairesCciError);
        }

        function onLoadFormulairesCciSuccess(data) {
                vm.formulairesCci = data;
                loadAllParamFormulaires();
                noCampagneInProgress();
                isAllFormulaireChecked();
        }

        function onLoadFormulairesCciError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
        }


        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        function loadAllParamFormulaires() {
            ParamFormulaire.query({}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.paramFormulaires = data;
            }

            function onError(error) {
                AlertService.error("Un problème est survenu, merci de contacter votre administrateur");
            }
        }

        //Methode pour afficher l'icône de vérification de chaque formulaire selon la valeur de l'attribut 'complet'
        function isFormulaireCompleted(paramFormulaire) {
            var completed = false;
            angular.forEach(vm.formulairesCci, function (value, key) {
                if (value.type !== null && paramFormulaire.type !== null && value.type === paramFormulaire.type) {
                    vm.idFormulaireSelected = value.id;
                    if (value.complet === true) {
                        completed= true;
                    }
                }
            });
            return completed;
        }

        //Methode qui vérifie si le bilan (tous les formulaires) est déjà validé par l'admin ou pas
        function isAllFormulaireChecked() {
            vm.allFormulairesChecked = false;
            angular.forEach(vm.formulairesCci, function (value, key) {
                if (value.verifie) {
                    vm.allFormulairesChecked = true;
                }
            });
            return vm.allFormulairesChecked;
        }

        //Fonction qui vérifie l'existence d'une campagne ouverte (en cours)
        function noCampagneInProgress() {
            vm.hasCampagneInProgress = true;
            vm.campagneOpened = false;
            if (vm.campagneSelected != null) {
                //Conversion des dates issues de la base en format compatible avec celles de javascript
                vm.campagneSelected.dateOuverture = DateUtils.convertDateTimeFromServer(vm.campagneSelected.dateOuverture);
                if (vm.campagneSelected.dateFermeture != null) {
                    vm.campagneSelected.dateFermeture = DateUtils.convertDateTimeFromServer(vm.campagneSelected.dateFermeture);
                }
                //S'il n'y a pas de campagne en cours
                if (!(vm.campagneSelected.dateOuverture < vm.currentDate &&
                    (vm.campagneSelected.dateFermeture == null ||
                    (vm.campagneSelected.dateFermeture != null && vm.campagneSelected.dateFermeture > vm.currentDate)))) {
                    vm.hasCampagneInProgress = false;
                    alert("Attention, la campagne est fermée : toute mise à jour apportée devra faire l’objet d’un nouvel export du bilan national et d’une nouvelle publication.");
                } else {
                    vm.campagneOpened = true;
                }
            }
        }

        function onSuccessManageValidation(data) {
            vm.formulairesCci = data;
            vm.allFormulairesChecked = true;
        }

        function onErrorManageValidation(error) {
            AlertService.error(error.data.message);
        }

        //Bouton qui check les vérifs au clic sur le bouton Valider
        function manageValidation() {
            isAllFormulaireCompleted();
            var message = '';
            if (vm.allFormulairesCompleted) {
                message = 'Confirmez-vous la validation du bilan de la CCI ' + vm.cciSelected.nom + ' ? Une fois validées, la CCI n’aura plus la possibilité d’apporter des modifications à ses données.';
                if (window.confirm(message)) {
                    FormulairesCciCampagneService.save(vm.formulairesCci, onSuccessManageValidation, onErrorManageValidation);
                }
            } else {
                message = 'Validation impossible. Tous les formulaires n’ont pas été complétés.';
                alert(message);
            }
        }

        //Mehtode qui vérifie que tous les formulaires sont complétés
        function isAllFormulaireCompleted() {
            vm.allFormulairesCompleted = true;
            angular.forEach(vm.formulairesCci, function (value, key) {
                if (!value.complet) {
                    vm.allFormulairesCompleted = false;
                }
            });
            return vm.allFormulairesCompleted;
        }
    }
})();
